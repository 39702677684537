import axiosInstance from '../auth/FetchInterceptor'
import { CurrencyEnum } from '../constants/currency'
import { PartialProductItem } from '../store/basket/types'
import * as Sentry from '@sentry/react'
import { PaymentContract } from './PaymentOptionsService'
import { API_URL } from '../constants/environment'
import { OrderDeliveryStatusEnum } from '../utils/orders.util'

export type OrderContact = {
  id: number;
  name: string;
  email: string;
}

export type OrderResponse = {
  approved_at: number;
  bill_number: string;
  created_at: number;
  delivery_city: string;
  id: number;
  money_received: number;
  name: string;
  delay_status: OrderDeliveryStatusEnum;
  pay_condition_code: string;
  pay_condition_desc: string;
  pay_condition_desc_en: string;
  payment_deadline: number;
  payment_status: string;
  price: string;
  responsible_user_name: string;
  responsible_user_email: string;
  seller_company_code: string;
  seller_company_name: string;
  spec_number: string;
  status: string;
  status_group: string;
  supply_date: string;
  supply_deadline: number;
  supply_deadline2: number;
  contacts: Array<OrderContact>;
  express: boolean;
}

export type GetOrdersResponse = {
  result: string;
  error_reason: string;
  orders: Array<OrderResponse>;
  show_only_my_orders: boolean;
}

export type AddOrderAsyncRequest = {
  orderName: string;
  name: string;
  phone: string;
  email: string;
  city: string;
  comment: string;
  currency: CurrencyEnum;
  items: Array<PartialProductItem>;
  payment_contract: PaymentContract;
}
export type AddOrderAsyncResponse = {
  job_id: string;
}
export type OrderData = {
  Id: number;
}

export type GetJobResultRequest = {
  jobId: string;
  isPolling?: boolean;
}
export enum GetJobResultResponseResultEnum {
  Success = 'success',
  Error = 'error',
  NoData = 'no_data',
}
export type GetJobResultResponse = {
  result: GetJobResultResponseResultEnum;
  data: string;
}

type OrdersServiceType = {
  getOrders: (isUserOrdersOnly: boolean) => Promise<GetOrdersResponse>;
  addOrderAsync: (requestData: AddOrderAsyncRequest) => Promise<AddOrderAsyncResponse>;
  getJobResult: <ResultDataType>(request: GetJobResultRequest) => Promise<ResultDataType>,
}

const OrdersService: OrdersServiceType = {
  getOrders: (isUserOrdersOnly: boolean) =>
    axiosInstance
      .get<never, GetOrdersResponse>(
        `${API_URL.CUSTOMER_AREA}/orders`,
        {
          params: {
            user_orders_only: isUserOrdersOnly,
          },
        },
      ),
  addOrderAsync: (requestData: AddOrderAsyncRequest) =>
    axiosInstance
      .post<never, AddOrderAsyncResponse, AddOrderAsyncRequest>(
        `${API_URL.PROPOSALS}/add-order-async`,
        requestData,
      ),
  getJobResult: <ResultDataType>({ jobId, isPolling = true }: GetJobResultRequest): Promise<ResultDataType> =>
    getJobResult({ jobId, isPolling }),
}

async function getJobResult<ResultDataType>({ jobId, isPolling = true }: GetJobResultRequest): Promise<ResultDataType> {
  try {
    const responseData: GetJobResultResponse = await axiosInstance
      .get<never, GetJobResultResponse>(
        `${API_URL.PROPOSALS}/get-job-result`,
        {
          params: {
            id: jobId,
            polling: isPolling,
          },
        },
      )

    if (
      responseData?.result === GetJobResultResponseResultEnum.Success
    ) {
      return JSON.parse(responseData?.data) as ResultDataType
    }

    if (responseData?.result === GetJobResultResponseResultEnum.NoData) {
      return await getJobResult({ jobId, isPolling })
    }

    if (
      responseData?.result === GetJobResultResponseResultEnum.Error
    ) {
      const errorMessage: string = JSON.parse(responseData?.data ?? '')
      throw new Error(errorMessage)
    }

    throw new Error(`Failed long polling`)
  }
  catch (error) {
    Sentry.captureException(error)

    return Promise.reject(error)
  }
}

export default OrdersService
