import { SelectItem } from '@mantine/core'
import { SerializedOrdersTableFilters } from '../../../../../hooks/useLocalStorage/types'
import { deserializeDate, serializeDate } from '../../../../../utils/date'
import {
  Order,
  OrderResponsiblePerson,
  getWaitingForPaymentDays,
  mapOrderStatusToMessage,
  unixTimestampToFormattedDate,
} from '../../../../../utils/orders.util'
import { OrdersErpTableFilters } from './hooks/OrdersErpTableFilters.hook'
import { TableOrder, TableOrderColumnId } from './OrdersErpTable.type'

export function mapOrderToTableOrder(order: Order): TableOrder {
  return {
    ...order,
    id: `${order.id}`,
    paymentWaiting: getWaitingForPaymentDays(order.supplyDate),
  }
}

export function mapToSelectItem<ID extends string>(data: TitleWithId<ID>): SelectItem {
  return {value: data.id, label: data.title}
}

export function serializeFilters(filters: OrdersErpTableFilters): SerializedOrdersTableFilters {
  if (!filters) {
    return {
      status: [],
      supplyDate: [null, null],
    }
  }

  return {
    ...filters,
    supplyDate: [
      serializeDate(filters?.supplyDate?.[0]),
      serializeDate(filters?.supplyDate?.[1]),
    ],
  }
}

export function deserializeFilters(serializedFilters: SerializedOrdersTableFilters): OrdersErpTableFilters {
  if (!serializedFilters) {
    return {
      status: [],
      supplyDate: [null, null],
    }
  }

  return {
    ...serializedFilters,
    supplyDate: [
      deserializeDate(serializedFilters?.supplyDate?.[0]),
      deserializeDate(serializedFilters?.supplyDate?.[1]),
    ],
  }
}

export function serializeResponsiblePersons(persons: Array<OrderResponsiblePerson>): string {
  if (!persons.length) {
    return ''
  }

  return persons
    .map(({ name }) => name)
    .join('|')
}

export function orderPropertyToCSV(
  order: TableOrder,
  columnId: TableOrderColumnId,
  messages: Record<string, string>,
): string {
  switch (columnId) {
    case 'id':
    case 'billId':
    case 'paymentValue':
    case 'paymentWaiting':
    case 'name':
      return `${order[columnId] || '-'}`
    case 'status':
      return mapOrderStatusToMessage(
        order?.status,
        messages,
      )
    case 'approvalDate':
    case 'supplyDate':
      return unixTimestampToFormattedDate(order[columnId]) || '-'
    case 'supplyDeadlineDate':
      return unixTimestampToFormattedDate(order?.maxSupplyDeadlineDate) || '-'
    case 'responsiblePersons':
      return serializeResponsiblePersons(order?.responsiblePersons)
    default:
      return ''
  }
}
