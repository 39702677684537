import React from 'react'
import PageHeaderAlt from '../../../components/layout-components/PageHeaderAlt'
import Flex from '../../../components/shared-components/Flex'
import IntlMessage from '../../../components/util-components/IntlMessage'
import { ProposalContent } from './content'

export const ProposalViewer = () => (
  <>
    <PageHeaderAlt className='border-bottom'>
      <div className='container-fluid'>
        <Flex justifyContent='between' alignItems='center'>
          <h2><IntlMessage id={'sidenav.my_orders.new'}/></h2>
        </Flex>
      </div>
    </PageHeaderAlt>

    <ProposalContent/>
  </>
)
