import { createSlice } from '@reduxjs/toolkit'
import { GetUserInfoResponse } from '../../services/UserService'
import { ActionType } from '../types'

export type UserInfo = GetUserInfoResponse
type UserInfoState = {
  userInfo: UserInfo | null;
  isLoading: boolean;
}

type SetUserInfo = ActionType<UserInfo>
type SetUserInfoLoading = ActionType<boolean>

function getInitialState(): UserInfoState {
  return {
    userInfo: null,
    isLoading: true,
  }
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { ...getInitialState() },
  reducers: {
    setUserInfo(state, action: SetUserInfo) {
      state.userInfo = action.payload
      // state.userInfo.show_feedback_form = true
    },
    resetUserInfo(state) {
      state.userInfo = null
    },
    setUserInfoLoading(state, action: SetUserInfoLoading) {
      state.isLoading = action.payload
    },
  },
})

export const userInfoReducer = userInfoSlice.reducer
export const {
  setUserInfo,
  resetUserInfo,
  setUserInfoLoading,
} = userInfoSlice.actions
