import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasketProducts } from '../../store/basket/selectors'
import { useQuery } from '../../hooks/useQuery'
import {
  setAddToBtnDisable,
  setEditedItemIndex,
  setInitialStateAction,
  setOpenHandleEditor,
  setProductsListAction,
} from '../../store/basket/reducer'
import { ProductBasketType } from '../../store/basket/types'
import { setDisabledOrderFormAction, setDocLinkAction, setLinkToTableAction, setOrderValueAction, setPdfLinkAction } from '../../store/generate-table/reducer'
import ProposalService from '../../services/ProposalService'
import { notification } from 'antd'
import { parseJSON } from '../../utils/json'
import { AvailabilityService } from '../../services/AvailabilityService'
import { ProductType } from '../../store/search/types'
import { BASKET_KEY } from '../../constants/localStorage'
import * as Sentry from '@sentry/react'

export const useBasket = () => {
  const dispatch = useDispatch()
  const basketProducts = useSelector(selectBasketProducts)
  const [isBasketLoading, setBasketLoading] = useState<boolean>(true)
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(true)
  const query = useQuery()

  return useMemo(() => {
    const setDataInStore = (data: AnyType) => {
      const {
        items,
        proposal_pdf_url,
        proposal_doc_url,
        spreadsheet_url,
      } = data

      localStorage.setItem(BASKET_KEY, JSON.stringify(items ? items : []))
      dispatch(setProductsListAction(items))
      dispatch(setDocLinkAction(proposal_doc_url || ''))
      dispatch(setPdfLinkAction(proposal_pdf_url || ''))
      dispatch(setLinkToTableAction(spreadsheet_url || ''))
    }

    const toggleLoaderVisibility = () => setLoaderVisibility(isLoaderVisible)

    const setInitialBasketStatus = () => {
      const number = query.get('number') ? query.get('number') : ''
      if (number && number !== localStorage.getItem('personalOrderNumber')) {
        dispatch(setInitialStateAction([]))
        localStorage.setItem(BASKET_KEY, '')
        localStorage.setItem('personalOrderNumber', number)
        localStorage.removeItem('personalLinkToTable')
        localStorage.removeItem('personalPdfLink')
        localStorage.removeItem('personalDocLink')
      }
      const data = localStorage.getItem(BASKET_KEY)
      if (data) {
        const personalBasketAppscience = JSON.parse(data)
        dispatch(setInitialStateAction(personalBasketAppscience))
      }
      else {
        dispatch(setInitialStateAction([]))
      }
      setBasketLoading(false)
      toggleLoaderVisibility()
    }

    //
    //Initial Generate Table State
    const initTableInfoState = () => {
      const urlNumber = query.get('number')
      if (urlNumber) {
        dispatch(setDisabledOrderFormAction(true))
        dispatch(setOrderValueAction(urlNumber))
      }
      const _linkToTable = localStorage.getItem('personalLinkToTable')
      const _pdfLink = localStorage.getItem('personalPdfLink')
      const _docLink = localStorage.getItem('personalDocLink')
      _linkToTable && dispatch(setLinkToTableAction(_linkToTable))
      _pdfLink && dispatch(setPdfLinkAction(_pdfLink))
      _docLink && dispatch(setDocLinkAction(_docLink))
    }

    //Init Generate And Basket State
    const initState = () => {
      const urlNumber = query.get('number')
      if (urlNumber) {
        dispatch(setOrderValueAction(urlNumber))
        dispatch(setDisabledOrderFormAction(true))
        toggleLoaderVisibility()

        ProposalService
          .getProposalData({ id: urlNumber })
          .then(({ data }) => {
            setDataInStore(data)
          })
          .catch((error: Error) => {
            notification.error({
              message: 'Ошибка при получении данных о КП',
              description: error.message,
              duration: 5,
            })
            Sentry.captureException(error)
          })
          .finally(() => {
            setBasketLoading(false)
          })
      }
      else {
        setInitialBasketStatus()
        initTableInfoState()
      }
    }

    const onCountHandler = (value: number, itemIndex: number, availability_type?: string) => {
      if (value === 0) {
        return
      }
      const rawData = localStorage.getItem(BASKET_KEY)
      const data = rawData === null
        ? null
        : parseJSON<ProductBasketType[]>(rawData)

      if (data === null) {
        return
      }

      if (value === -1) {
        data.splice(itemIndex, 1)
      }
      else {
        data[itemIndex].count = value
      }

      localStorage.setItem(BASKET_KEY, JSON.stringify(data))
      dispatch(setProductsListAction([...data]))

      if (value > 0 && availability_type === 'ON-DEMAND') {
        availabilityRequestHandler(data[itemIndex])
      }
    }

    const availabilityRequestHandler = (product: ProductType) => {
      dispatch(setAddToBtnDisable(true))
      const { id } = product
      const storedBasketProducts: ProductBasketType[] = JSON.parse(localStorage.getItem(BASKET_KEY) ?? '[]')
      const { count = 0 } = storedBasketProducts.find(item => item.id === id) ?? {}

      AvailabilityService
        .post(id, count)
        .then(({ available }) => {
          const newBasketProducts: ProductBasketType[] =
            storedBasketProducts
              .map(basketProduct => {
                if (basketProduct.id !== id) {
                  return basketProduct
                }
                return {
                  ...basketProduct,
                  availability_flag: available,
                }
              })

          localStorage.setItem(BASKET_KEY, JSON.stringify(newBasketProducts))
          dispatch(setProductsListAction([...newBasketProducts]))
        })
        .catch((error: Error) => {
          notification.error({
            message: 'Ошибка при получении данных о доступности',
            description: error.message,
            duration: 5,
          })
          Sentry.captureException(error)
        })
        .finally(() => {
          dispatch(setAddToBtnDisable(false))
        })
    }

    const onStartEditHandler = (index: number) => {
      dispatch(setOpenHandleEditor(true))
      dispatch(setEditedItemIndex(index))
    }

    return {
      basketProducts,
      isBasketLoading,
      isLoaderVisible,

      setInitialBasketStatus,
      onCountHandler,
      initTableInfoState,
      initState,
      setLoaderVisibility: toggleLoaderVisibility,
      onStartEditHandler,
    }
  }, [basketProducts, dispatch, isLoaderVisible, isBasketLoading, query])
}
