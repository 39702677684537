import { ProductCard } from '../../../../components/custom-components/product-card'
import { Card, Pagination } from 'antd'
import { useSelector } from 'react-redux'
import { selectAddToCartBtnDisable } from '../../../../store/basket/selectors'
import { ProductType } from '../../../../store/search/types'
import styles from './Analogs.module.css'

type AnalogsProps = {
  products: Array<ProductType>;
  totalProductsCount: number;
  pageSize: number;
  page: number;
  setPaginatioAnalogsCurrent: (value: number) => void;
  onAddToBasket: (product: ProductType) => void;
}

export function Analogs({
  products,
  totalProductsCount,
  pageSize,
  page,
  setPaginatioAnalogsCurrent,
  onAddToBasket,
}: AnalogsProps) {
  const isDisabled = useSelector(selectAddToCartBtnDisable)

  return (
    <Card
      className={styles['root']}
      bordered={true}
    >
      {products.map((product, key) =>
        <ProductCard
          {...product}
          key={key}
          isDisableBtn={isDisabled}
          addToCart={() => onAddToBasket(product)}
        />,
      )}
      {pageSize < totalProductsCount && (
        <div className={styles['pagination']}>
          <Pagination
            total={totalProductsCount}
            current={page}
            pageSize={pageSize}
            showSizeChanger={false}
            onChange={value => {
              setPaginatioAnalogsCurrent(value)
            }}
          />
        </div>
      )}
    </Card>
  )
}
