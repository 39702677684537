import React, {useCallback, useEffect} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import SideNav from '../../components/layout-components/SideNav'
import TopNav from '../../components/layout-components/TopNav'
import Loading from '../../components/shared-components/Loading'
import MobileNav from '../../components/layout-components/MobileNav'
import HeaderNav from '../../components/layout-components/HeaderNav'
import PageHeader from '../../components/layout-components/PageHeader'
import Footer from '../../components/layout-components/Footer'
import AppViews from '../../views/app-views'
import {Grid, Layout, notification} from 'antd'

import navigationConfig from '../../configs/NavigationConfig'
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '../../constants/ThemeConstant'
import utils from '../../utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import {selectUserInfo, selectUserInfoLoading} from '../../store/user-info/selectors'
import { DirectionEnum } from '../../configs/AppConfig'
import {CompanyService, GetCompaniesResponse} from '../../services/CompanyService'
import {
  resetCompanies,
  resetSelectedCompanyId,
  resetUserRole,
  setCompanies,
  setSelectedCompanyId,
  setUserRole,
} from '../../store/companies/reducer'
import * as Sentry from '@sentry/react'
import {
  isAuthenticated,
  selectIsUserIdSentToYandexMetrica,
} from '../../store/auth/selectors'
import {useHistory} from 'react-router-dom'
import {setIsUserIdSentToYandexMetrica, signOut} from '../../store/auth/reducer'
import {getUserInfo} from './getUserInfo'
import { yandexMetricSetUserId } from '../../utils/yandex-metric-api'


const { Content } = Layout
const { useBreakpoint } = Grid

type AppLayoutProps = {
  navCollapsed: boolean;
  navType: string;
  location: AnyType;
  direction: DirectionEnum;
}

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
}: AppLayoutProps) => {
  const isUserIdSentToYandexMetrica = useSelector(selectIsUserIdSentToYandexMetrica)
  const currentRouteInfo: AnyType = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher()

  const dispatch = useDispatch()
  const isAuth: boolean = useSelector(isAuthenticated)
  const history = useHistory()
  const handleSignOut = useCallback(() => {
    dispatch(signOut())
    dispatch(resetSelectedCompanyId())
    history.push('/auth/login')
  }, [dispatch, history])

  useEffect(() => {
    dispatch(resetCompanies())
    dispatch(resetUserRole())

    if (!isAuth) {
      handleSignOut()
      return
    }
    CompanyService.getCompanies()
      .then(async ({ companies, user_role: userRole }: GetCompaniesResponse) => {
        await dispatch(setCompanies(companies))
        if (companies.length === 1) {
          dispatch(setSelectedCompanyId(companies[0].id))
        }
        dispatch(setUserRole(userRole))
        const userInfo = await getUserInfo(dispatch)
        if (!isUserIdSentToYandexMetrica && userInfo) {
          yandexMetricSetUserId(userInfo.metrica_user_id)
          dispatch(setIsUserIdSentToYandexMetrica(true))
        }
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных о компаниях',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
  }, [dispatch, isAuth, handleSignOut])

  const isUserInfoLoading: boolean = useSelector(selectUserInfoLoading)

  if (status === 'idle') {
    return <div className={`loading cover-page`}></div>
  }

  if (status === 'loading' || isUserInfoLoading) {
    return <Loading cover='page' />
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DirectionEnum.LeftToRight) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DirectionEnum.RightToLeft) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  return (
    <Layout>
      <HeaderNav
        isMobile={isMobile}
      />
      {isNavTop && !isMobile && (
        <TopNav />
      )}
      <Layout className='app-container'>
        {isNavSide && !isMobile && (
          <SideNav
            routeInfo={currentRouteInfo}
            hideGroupTitle={false}
          />
        )}
        <Layout
          className='app-layout'
          style={getLayoutDirectionGutter()}
        >
          <div
            className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}
            style={{ padding: 0 }}
          >
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && (
        <MobileNav
          routeInfo={currentRouteInfo}
          hideGroupTitle={false}
        />
      )}
    </Layout>
  )
}

const mapStateToProps = ({ theme }: AnyType) => {
  const { navCollapsed, navType, locale } = theme
  return { navCollapsed, navType, locale }
}

export default connect(mapStateToProps)(React.memo(AppLayout))
