import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import {Button} from 'antd'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'

type SuccessOrderType = {
  id: string
  email: string
}

export const SuccessOrder: React.FC<SuccessOrderType> = props => {
  const {id, email} = props
  const history = useHistory()
  const intl = useIntl()
  const orderMsg = () => intl.messages['orders.status.success_order_number'].toString().replace('{id}', id)

  return (
    <>
      <div style={{textAlign: 'center', width: '100%'}} className='pt-4 pb-4'>
        <h1 className='text-success mb-4' style={{fontSize: 50}}><CheckCircleOutlined/></h1>
        <h3>{orderMsg()}</h3>{intl.messages['orders.status.success_message']} <b>{email}</b>
        <div className='mt-4'>
          <Button type='primary' onClick={() => history.push('/new')}>{intl.messages['orders.btn.create_new_order']}</Button>
        </div>
      </div>
    </>
  )
}
