import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectUserInfo } from '../../store/user-info/selectors'
import { UserInfo } from '../../store/user-info/reducer'
import { yandexMetricHit } from '../../utils/yandex-metric-api'

export function usePageViews() {
  const location = useLocation()
  const userInfo: UserInfo | null = useSelector(selectUserInfo)

  useEffect(() => {
    yandexMetricHit({
      params: {
        user_id: userInfo?.user_id || '',
        company_id: userInfo?.company_id || '',
      },
    })
  }, [location])
}
