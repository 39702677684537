import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'
import { ProductType } from '../store/search/types'
import { PaymentContract } from './PaymentOptionsService'

export type GetProductByIdRequest = {
  id: string;
  payment_contract: PaymentContract;
}

export type GetProductByIdResponse = {
  items_count: number;
  pages_count: number;
  partner_products: ProductType[];
}

export type GetProductsByIdsRequest = {
  ids: Array<string>;
  payment_contract: PaymentContract;
}

export type GetProductsByIdsResponse = GetProductByIdResponse

type BasketServiceType = {
  getProductInfoById: (request: GetProductByIdRequest) => Promise<GetProductByIdResponse>;
  getProductsInfoByIds: (request: GetProductsByIdsRequest) => Promise<GetProductsByIdsResponse>;
}

const BasketService: BasketServiceType = {
  getProductInfoById: (request: GetProductByIdRequest) =>
    axiosInstance.post<never, GetProductByIdResponse, GetProductByIdRequest>(
      `${API_URL.PARTNER_PRODUCTS}/customers-request`,
      request,
    ),
  getProductsInfoByIds: (request: GetProductsByIdsRequest) =>
    axiosInstance.post<never, GetProductsByIdsResponse, GetProductsByIdsRequest>(
      `${API_URL.PARTNER_PRODUCTS}/customers-request`,
      request,
    ),
}

export default BasketService
