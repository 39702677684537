import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'
import { DeliveryCityCost } from './types'

const selectBasket = (state: RootState) => state.basket

export const selectBasketProducts = createSelector(selectBasket, state => state.basketProducts)
export const selectAddToCartBtnDisable = createSelector(selectBasket, state => state.addToCartBtnDisable)
export const selectEditedItem = createSelector(selectBasket, state => state.editedItem)
export const selectOpenHandleEditor = createSelector(selectBasket, state => state.openHandleEditor)
export const selectTotalSummary = createSelector(selectBasket, state => state.totalSummary)
export const selectPreorderProductArray = createSelector(selectBasket, state => state.preorderProductArray)
export const selectContactName = createSelector(selectBasket, state => state.contactName)
export const selectPhone = createSelector(selectBasket, state => state.phone)
export const selectEmail = createSelector(selectBasket, state => state.email)
export const selectComment = createSelector(selectBasket, state => state.comment)
export const selectPreorderArray = createSelector(selectBasket, state => state.preorderProductArray)
export const selectOrderName = createSelector(selectBasket, state => state.orderName)
export const selectDefaultOrderName = createSelector(selectBasket, state => state.defaultOrderName)
export const selectDeliveryCity = createSelector(selectBasket, state => state.deliveryCity)
export const selectDeliveryCostInUsd = createSelector(selectBasket, (state): number => {
  const cityCost: DeliveryCityCost | undefined = state.deliveryCityCosts
    .find(({ deliveryCity }) => deliveryCity === state.deliveryCity)
  return cityCost?.deliveryCostInUsd || 0
})
export const selectDeliveryCityCosts = createSelector(selectBasket, state => state.deliveryCityCosts)
export const selectExchangeRates = createSelector(selectBasket, state => state.exchangeRates)
