import { Empty, notification } from 'antd'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Loading from '../../../components/shared-components/Loading'
import { UserRoleEnum } from '../../../services/CompanyService'
import { selectSelectedCompanyId, selectUserRole } from '../../../store/companies/selectors'
import { setTableOrderItems } from '../../../store/order-items-table/reducer'
import {
  IntegratedDeliveryNote,
  Order,
  OrderItem,
  mapGetOrderResponseToOrder,
} from '../../../utils/orders.util'
import {
  OrderErpTable,
  OrderIdnTable,
  OrderItemTimelineModal,
  OrderTableHeader,
} from './components'
import * as Sentry from '@sentry/react'
import { Tabs } from '@mantine/core'
import { setIntegratedDeliveryNotes } from '../../../store/idn-table/reducer'
import { useIntl } from 'react-intl'
import { selectIntegratedDeliveryNotes } from '../../../store/idn-table/selector'
import { selectTableOrderItems } from '../../../store/order-items-table/selectors'
import { OrderService } from '../../../services'
import { GetOrderResponse } from '../../../services/OrderService'

type OrderViewRouteParams = {
  orderId: string;
}

export const OrderView = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { orderId } = useParams<OrderViewRouteParams>()
  const [isLoading, setLoading] = useState(true)
  const [order, setOrder] = useState<Order | null>(null)
  const userRole: UserRoleEnum | null = useSelector(selectUserRole)
  const selectedCompanyId: string | null = useSelector(selectSelectedCompanyId)
  const [activeTab, setActiveTab] = useState(0)
  const orderItems: Array<OrderItem> = useSelector(selectTableOrderItems)
  const hasOrderItems = Boolean(orderItems?.length)
  const idns: Array<IntegratedDeliveryNote> = useSelector(selectIntegratedDeliveryNotes)
  const hasInds = Boolean(idns?.length)

  const getOrder = useCallback(() => {
    setLoading(true)

    OrderService
      .getOrder(orderId)
      .then((response: GetOrderResponse) => {
        const orderData: Order | null = mapGetOrderResponseToOrder(response)
        setOrder(orderData)
        dispatch(setTableOrderItems(orderData?.items || []))
        dispatch(setIntegratedDeliveryNotes(orderData?.integratedDeliveryNotes ?? []))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных по сделке',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, orderId])

  useEffect(() => {
    if (!userRole || (userRole === UserRoleEnum.Sales && !selectedCompanyId)) {
      return
    }

    getOrder()
  }, [getOrder, userRole, selectedCompanyId])

  return (
    <Suspense fallback={<Loading cover='content' />}>
      <div className='order-view'>
        {isLoading && <Loading cover='content' />}
        {!isLoading && !order && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 200 }}
            description={
              <span>К сожалению, заявка №{orderId} не найдена</span>
            }
            style={{ color: '#455560' }}
          >
            Вы можете создать
            {' '}
            <Link to={'/new'}>новую заявку</Link>, найти заявку в
            {' '}
            <Link to={'/orders/proposals'}>КП</Link>, в списке
            {' '}
            <Link to={'/orders/active'}>активных</Link> или в
            {' '}
            <Link to={'/orders/archive'}>архиве</Link>
          </Empty>
        )}
        {!isLoading && order && (
          <>
            <OrderTableHeader order={order} />
            <div
              className='erp-order-table'
              style={{ margin: '0 20px' }}
            >
              <Tabs
                active={activeTab}
                onTabChange={setActiveTab}
              >
                <Tabs.Tab
                  label={intl.messages['orderPage.tabs.positions']}
                >
                  {!hasOrderItems && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                  {hasOrderItems && (
                    <>
                      <OrderErpTable order={order} />
                      <OrderItemTimelineModal />
                    </>
                  )}
                </Tabs.Tab>
                {hasInds && (
                  <Tabs.Tab
                    label={`${intl.messages['orderPage.tabs.idn']} (${order?.integratedDeliveryNotes?.length ?? 0})`}
                  >
                    <OrderIdnTable />
                  </Tabs.Tab>
                )}
              </Tabs>
            </div>
          </>
        )}
      </div>
    </Suspense>
  )
}
