import React, { useCallback } from 'react'
import { Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ANALOGS_PAGE_SIZE, SEARCH_RESULT_PAGE_SIZE, useSearchContentHook } from './search.hook'
import { VendorFilter } from './components/VendorFilter/VendorFilter'
import { SearchResultFilter } from './components/SearchResultFilter/SearchResultFilter'
import { SearchField } from './components/SearchField/SearchField'
import { SearchResult } from './components/SearchResult/SearchResult'
import { Analogs } from './components/Analogs/Analogs'
import { AnalogsHeader } from './components/AnalogsHeader/AnalogsHeader'
import { Skeleton } from '@mantine/core'
import styles from './search.module.css'

export const SearchContent: React.FC = () => {
  const {
    isSearchLoading,
    filteredWord,
    searched,
    searchResultPage,
    searchResultTotalProductsCount,
    totalSize,
    isDropdownVisible,
    searchOptions,
    requestSearchWord,
    analogsCasId,
    analogsLoading,
    analogsPage,
    searchVendors,
    setVendorsFilter,
    resetVendorsFilter,
    setIsReverseSorting,
    setSortingProp,
    setFilteredWord,
    setSearchResultPage,
    searchPaginationHandler,
    onSearchHandler,
    searchResultProducts,
    addToBasketHandler,
    onSearchInput,
    setDropdownVisible,
    onChooseSearchLabel,
    setPaginatioAnalogsCurrent,
    analogsProducts,
    totalAnalogsPagination,
    isReverseSorting,
    sortingProp,
    isDefaultVendorsLoading,
    defaultVendors,
  } = useSearchContentHook()

  const hideDropdown = useCallback(() => {
    setDropdownVisible(false)
  }, [setDropdownVisible])

  return (
    <>
      <Card bordered={true}>
        <div className={styles['search-field']}>
          <Skeleton
            visible={isDefaultVendorsLoading}
            radius='md'
            width='auto'
            height={40}
            animate
          >
            <SearchField
              searchOptions={searchOptions}
              searchInput={requestSearchWord}
              isSearchLoading={isSearchLoading}
              isDropdownVisible={isDropdownVisible}
              onChooseSearchLabel={onChooseSearchLabel}
              onSearchInput={onSearchInput}
              hideDropdown={hideDropdown}
              onSearch={onSearchHandler}
            />
          </Skeleton>
        </div>
        <Skeleton
          visible={isDefaultVendorsLoading}
          radius='md'
          width='auto'
          animate
        >
          <VendorFilter
            defaultVendors={defaultVendors}
            searchVendors={searchVendors}
            setVendorsFilter={setVendorsFilter}
            resetVendorsFilter={resetVendorsFilter}
          />
        </Skeleton>
        {searched && (
          <div className={styles['search-result-filter']}>
            <SearchResultFilter
              totalSize={totalSize}
              searchResultTotalProductsCount={searchResultTotalProductsCount}
              filteredWord={filteredWord}
              setFilteredWord={setFilteredWord}
              setPage={setSearchResultPage}
            />
          </div>
        )}
        {(searchResultProducts.length > 0 || isSearchLoading) && (
          <div className={styles['search-result']}>
            <SearchResult
              products={searchResultProducts}
              isSearchLoading={isSearchLoading}
              totalProductsCount={searchResultTotalProductsCount}
              pageSize={SEARCH_RESULT_PAGE_SIZE}
              page={searchResultPage}
              onPaginationChange={searchPaginationHandler}
              onAddToBasket={addToBasketHandler}
            />
          </div>
        )}
      </Card>

      {analogsLoading && (
        <div className={styles['analogs-loading']}>
          <LoadingOutlined
            className={styles['analogs-loading__icon']}
          />
        </div>
      )}

      {totalAnalogsPagination !== 0 && !analogsLoading && (
        <>
          <div className={styles['analogs-header']}>
            <AnalogsHeader
              analogsCasId={analogsCasId}
              sortingProp={sortingProp}
              isReverseSorting={isReverseSorting}
              setIsReverseSorting={setIsReverseSorting}
              setSortingProp={setSortingProp}
            />
          </div>
          <div className={styles['analogs']}>
            <Analogs
              products={analogsProducts}
              totalProductsCount={totalAnalogsPagination}
              pageSize={ANALOGS_PAGE_SIZE}
              page={analogsPage}
              setPaginatioAnalogsCurrent={setPaginatioAnalogsCurrent}
              onAddToBasket={addToBasketHandler}
            />
          </div>
        </>
      )}
    </>
  )
}
