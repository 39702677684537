import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Button, Card, Col, Form, Input, notification, Row, Select, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import DeliveryCostService from '../../../services/DeliveryCostService'
import { setDeliveryCity, setDeliveryCityCosts, setOrderName } from '../../../store/basket/reducer'
import { selectDefaultOrderName, selectDeliveryCity, selectDeliveryCityCosts, selectExchangeRates } from '../../../store/basket/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ORDER_NAME_KEY } from '../../../constants/localStorage'
import { DeliveryCityCost, ExchangeRates } from '../../../store/basket/types'
import { selectCurrency } from '../../../store/theme/selectors'
import { CurrencyEnum } from '../../../constants/currency'
import { convertCurrencyValue } from '../../../services/ExchangeRateService'
import * as Sentry from '@sentry/react'

const { Option } = Select
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

export type DeliveryCostItemType = {
  city: string
  cost: string
}

type OrderPersonalInfoFormProps = {
  contactName: string
  phone: string
  email: string
  comment: string
  loading: boolean
  orderName: string
  isOrderCreationDisabled: boolean
  setPropertyInStore: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  addOrderHandler: () => void
}

export const OrderPersonalInfoForm: React.FC<OrderPersonalInfoFormProps> = ({
  orderName,
  comment,
  contactName,
  phone,
  email,
  loading,
  isOrderCreationDisabled,
  setPropertyInStore,
  addOrderHandler,
}) => {
  const [form] = Form.useForm()
  const userOrderName: string = localStorage.getItem(ORDER_NAME_KEY) ?? ''
  const defaultOrderName = useSelector(selectDefaultOrderName)

  const currentDeliveryCity: string = useSelector(selectDeliveryCity)
  const deliveryCityCosts: DeliveryCityCost[] = useSelector(selectDeliveryCityCosts)
  const exchangeRates: ExchangeRates = useSelector(selectExchangeRates)
  const currency: CurrencyEnum = useSelector(selectCurrency)

  const isOptionalBlockHiddenByDefault: boolean = !!contactName && !!phone && !!email
  const [isOptionalBlockHidden, hideOptionalBlock] = useState<boolean>(isOptionalBlockHiddenByDefault)

  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true

    DeliveryCostService
      .getCities()
      .then(({ city_costs: cityCosts = [] }) => {
        if (!isMounted) {
          return
        }

        const mappedDeliveryCityCosts: DeliveryCityCost[] = cityCosts.map(({ city, cost }) => ({
          deliveryCity: city || '',
          deliveryCostInUsd: parseInt(cost?.replace('+$', '') || '0', 10),
        }))
        dispatch(setDeliveryCityCosts(mappedDeliveryCityCosts))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных о городах',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })

    return () => {
      isMounted = false
    }
  }, [dispatch])

  const onSelectDeliveryCityHandler = (selectedDeliveryCity: string) => {
    dispatch(setDeliveryCity(selectedDeliveryCity))
  }

  const handleOrderNameInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    localStorage.setItem(ORDER_NAME_KEY, event.target.value)
    setPropertyInStore(event)
  }, [setPropertyInStore])

  const handleTooltipWrapperClick = useCallback(() => {
    localStorage.setItem(ORDER_NAME_KEY, defaultOrderName)
    dispatch(setOrderName(defaultOrderName))
  }, [defaultOrderName, dispatch])

  const displayOrderName: string = userOrderName || orderName
  const isTooltipVisible: boolean = !displayOrderName.length && !!defaultOrderName.length
  const tooltipTitle = isTooltipVisible
    ? <>
      {`${intl.messages['sidenav.my_orders.edition.tooltip_hint']}`}
      <br/>
      <strong>{`${defaultOrderName}`}</strong>
    </>
    : null

  return <>
    <Card bordered={true}>
      <div className='order-personal-info-section'>
        <Form
          hidden={loading}
          form={form}
          {...layout}
          initialValues={{
            contactName,
            phone,
            email,
            comment,
            city: currentDeliveryCity,
            orderName: displayOrderName,
          }}
        >
          <Row>
            <Col
              span={24}
              md={24}
              lg={24}
            >
              <Form.Item
                name='city'
                label={intl.messages['sidenav.my_orders.edition.city_of_delivery']}
                rules={[{ required: true }]}>
                <Select
                  placeholder='Выберите город'
                  showSearch
                  allowClear
                  onChange={onSelectDeliveryCityHandler}
                >
                  {deliveryCityCosts.map(({ deliveryCity, deliveryCostInUsd }, index) => (
                    <Option
                      key={index}
                      value={deliveryCity}
                    >
                      <div className='d-flex justify-content-between'>
                        <span className='text-black-50'>
                          {deliveryCity}
                        </span>
                        {deliveryCostInUsd !== 0 && (
                          <span>
                            За доставку {convertCurrencyValue(exchangeRates, currency, deliveryCostInUsd)} {currency}
                          </span>
                        )}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              lg={24}
              style={{display: 'none'}}
            >
              <Form.Item
                name='city'
                label={intl.messages['sidenav.my_orders.edition.delivery_city']}
                required
                rules={[{
                  message: intl.messages['sidenav.my_orders.edition.delivery_city'] as string,
                }]}
              >
                <Input
                  name='city'
                  placeholder={intl.messages['sidenav.my_orders.edition.enter_delivery_city'] as string}
                  onChange={e => setPropertyInStore(e)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              lg={24}
            >
              <Form.Item
                name='orderName'
                label={intl.messages['sidenav.my_orders.edition.order_name'] as string}
                required
                rules={[{
                  message: intl.messages['sidenav.my_orders.edition.enter_order_name'] as string,
                }]}
              >
                <Input
                  name='orderName'
                  value={displayOrderName}
                  placeholder={defaultOrderName}
                  autoComplete='off'
                  onChange={handleOrderNameInputChange}
                />
                <div onClick={handleTooltipWrapperClick}>
                  <Tooltip
                    visible={isTooltipVisible}
                    title={tooltipTitle}
                    placement='bottomLeft'
                    overlayStyle={{cursor: 'pointer'}}
                    overlayInnerStyle={{color: 'rgb(26, 51, 83)'}}
                    color='white'
                    zIndex={999}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              lg={24}
            >
              <Form.Item
                name='comment'
                label={intl.messages['sidenav.my_orders.edition.comment'] as string}
              >
                {/*@ts-ignore*/}
                <Input.TextArea
                  name='comment'
                  placeholder={intl.messages['sidenav.my_orders.edition.add_comment'] as string}
                  onChange={e => setPropertyInStore(e)}
                />
              </Form.Item>
            </Col>

            {isOptionalBlockHidden && (
              <Col offset={6}>
                <Button
                  type='link'
                  className='p-0'
                  style={{marginTop: -50, marginBottom: 10}}
                  onClick={() => hideOptionalBlock(false)}
                >
                  {intl.messages['sidenav.my_orders.edition.expand']}
                  <CaretDownOutlined style={{fontSize: 11}}/>
                </Button>
              </Col>
            )}

            {!isOptionalBlockHidden && (
              <>
                <Col
                  span={24}
                  md={24}
                  lg={24}
                >
                  <Form.Item
                    name='contactName'
                    label={intl.messages['sidenav.my_orders.edition.contact_name'] as string}
                    required={true}
                    rules={[{
                      message: intl.messages['sidenav.my_orders.edition.enter_name'] as string,
                    }]}
                  >
                    <Input
                      name='contactName'
                      placeholder={intl.messages['sidenav.my_orders.edition.enter_name'] as string}
                      onChange={e => setPropertyInStore(e)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  md={24}
                  lg={24}
                >
                  <Form.Item
                    name='phone'
                    label={intl.messages['sidenav.my_orders.edition.phone'] as string}
                    required={true}
                    rules={[{
                      message: intl.messages['sidenav.my_orders.edition.add_phone'] as string,
                    }]}
                  >
                    <Input
                      name='phone'
                      placeholder={intl.messages['sidenav.my_orders.edition.add_phone'] as string}
                      onChange={e => setPropertyInStore(e)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  md={24}
                  lg={24}
                >
                  <Form.Item
                    name='email'
                    label='Email'
                    required={true}
                    rules={[{
                      message: intl.messages['sidenav.my_orders.edition.add_email'] as string,
                    }]}
                  >
                    <Input
                      name='email'
                      placeholder={intl.messages['sidenav.my_orders.edition.add_email'] as string}
                      onChange={e => setPropertyInStore(e)}
                    />
                  </Form.Item>
                </Col>
                <Col offset={6}>
                  <Button
                    type='link'
                    className='p-0'
                    style={{marginTop: -50, marginBottom: 10}}
                    onClick={() => hideOptionalBlock(true)}
                  >
                    {intl.messages['sidenav.my_orders.edition.hide']}
                    <CaretUpOutlined style={{fontSize: 11}}/>
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
        <Col
          offset={6}
          span={24}
          md={18}
          lg={18}
        >
          <div>
            <Button
              htmlType='submit'
              type='primary'
              disabled={loading || isOrderCreationDisabled}
              onClick={() => addOrderHandler()}
            >
              {intl.messages['sidenav.my_orders.edition.checkout'] as string}
            </Button>
          </div>
        </Col>
      </div>
    </Card>
  </>
}
