import { useIntl } from 'react-intl'
import { TableOrderItem } from '../../OrderErpTable.type'
import { Text } from '@mantine/core'
import { unixTimestampToFormattedDate } from '../../../../../../../utils/orders.util'
import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { getFormattedDaysDifferenceText } from './DeliveryToClientTooltipText.utils'

type Props = {
  orderItem: TableOrderItem;
}

export function DeliveryToClientTooltipText({ orderItem }: Props) {
  const {
    expectedDeliveryDate: deadline,
    expectedDeliveryToClient: expectedDate,
    expectedDeliveryDateHistory: history,
  } = orderItem
  const intl = useIntl()
  const isOnTime = expectedDate === deadline
  const isFaster = expectedDate < deadline
  const title = isOnTime
    ? intl.messages['orderTable.deliveryToClient.isOnTime']
    : isFaster
      ? intl.messages['orderTable.deliveryToClient.isFaster']
      : intl.messages['orderTable.deliveryToClient.isLate']

  return (
    <>
      <Text mb={history.length ? 10 : 0}>
        {title}
      </Text>
      {history.map(({ from, to }, index) => (
        <div
          key={index}
          className='d-flex align-items-center'
        >
          <Text
            mr={2}
            style={{ textDecoration: 'line-through' }}
          >
            {unixTimestampToFormattedDate(from)}
          </Text>
          <ArrowNarrowRightIcon className='w-4 h-4' />
          <Text
            ml={2}
          >
            {unixTimestampToFormattedDate(to)}
          </Text>
          <Text
            ml={30}
          >
            {getFormattedDaysDifferenceText(from, to, intl)}
          </Text>
        </div>
      ))}
    </>
  )
}
