import { BellOutlined } from '@ant-design/icons'
import { Text } from '@mantine/core'

type SurveyNotificationProps = {
  onClick: () => void;
}

export function SurveyNotification({ onClick }: SurveyNotificationProps) {
  return (
    <div
      className='bell'
      onClick={onClick}
    >
      <BellOutlined />
      <Text
        size='sm'
        ml={4}
        weight={600}
        lineClamp={1}
      >
        Поделитесь обратной связью по работе с нами. Это займёт 4 минуты и поможет нам стать лучше
      </Text>
    </div>
  )
}
