import { createSlice } from '@reduxjs/toolkit'
import { getLocalStorageCallbacks } from '../../hooks/useLocalStorage/useLocalStorage'
import { PaymentDelay, PaymentType } from '../../services/PaymentOptionsService'
import { ActionType } from '../types'

type PaymentOptionsState = {
  paymentTypes: Array<PaymentType>;
  defaultPaymentType: PaymentType | null;
  selectedPaymentType: PaymentType | null;
  paymentDelays: Array<PaymentDelay>;
  defaultPaymentDelay: PaymentDelay | null;
  selectedPaymentDelay: PaymentDelay | null;
  isLoading: boolean;
}

type SetPaymentTypes = ActionType<Array<PaymentType>>;
type SetDefaultPaymentType = ActionType<PaymentType | null>;
type SetSelectedPaymentType = ActionType<PaymentType | null>;
type SetPaymentDelays = ActionType<Array<PaymentDelay>>;
type SetDefaultPaymentDelay = ActionType<PaymentDelay | null>;
type SetSelectedPaymentDelay = ActionType<PaymentDelay | null>;
type SetLoading = ActionType<boolean>;

const {
  getStorageObject,
  setValueToStorage,
} = getLocalStorageCallbacks('paymentOptions')

function getInitialState(): PaymentOptionsState {
  const storage = getStorageObject()

  return {
    paymentTypes: [],
    defaultPaymentType: null,
    selectedPaymentType: storage?.selectedPaymentType ?? null,
    paymentDelays: [],
    defaultPaymentDelay: null,
    selectedPaymentDelay: storage?.selectedPaymentDelay ?? null,
    isLoading: false,
  }
}

const paymentOptionsSlice = createSlice({
  name: 'paymentOptions',
  initialState: { ...getInitialState() },
  reducers: {
    setPaymentTypes(state, action: SetPaymentTypes) {
      const paymentTypes: Array<PaymentType> = action.payload ?? []
      state.paymentTypes = paymentTypes
      state.defaultPaymentType = null
      state.selectedPaymentType =
        state.selectedPaymentType && paymentTypes.includes(state.selectedPaymentType)
          ? state.selectedPaymentType
          : null
    },
    setDefaultPaymentType(state, action: SetDefaultPaymentType) {
      state.defaultPaymentType = action.payload
    },
    setSelectedPaymentType(state, action: SetSelectedPaymentType) {
      state.selectedPaymentType = action.payload
        ?? state.selectedPaymentType
        ?? state.defaultPaymentType
        ?? state.paymentTypes?.[0]
        ?? null
      setValueToStorage('selectedPaymentType', state.selectedPaymentType)
    },
    setPaymentDelays(state, action: SetPaymentDelays) {
      const paymentDelays: Array<PaymentDelay> = action.payload ?? []
      state.paymentDelays = paymentDelays
      state.defaultPaymentDelay = null
      state.selectedPaymentDelay =
        state.selectedPaymentDelay && paymentDelays.includes(state.selectedPaymentDelay)
          ? state.selectedPaymentDelay
          : null
    },
    setDefaultPaymentDelay(state, action: SetDefaultPaymentDelay) {
      state.defaultPaymentDelay = action.payload
    },
    setSelectedPaymentDelay(state, action: SetSelectedPaymentDelay) {
      state.selectedPaymentDelay = action.payload
        ?? state.selectedPaymentDelay
        ?? state.defaultPaymentDelay
        ?? state.paymentDelays?.[0]
        ?? null
      setValueToStorage('selectedPaymentDelay', state.selectedPaymentDelay)
    },
    setPaymentOptionsLoading(state, action: SetLoading) {
      state.isLoading = action.payload
    },
  },
})

export const paymentOptionsReducer = paymentOptionsSlice.reducer
export const {
  setPaymentTypes,
  setDefaultPaymentType,
  setSelectedPaymentType,
  setPaymentDelays,
  setDefaultPaymentDelay,
  setSelectedPaymentDelay,
  setPaymentOptionsLoading,
} = paymentOptionsSlice.actions
