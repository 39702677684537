import React, { useCallback, useEffect, useState } from 'react'
import { Empty, notification } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import OrdersService, { GetOrdersResponse } from '../../../services/OrdersService'
import Loading from '../../../components/shared-components/Loading'
import './OrdersView.css'
import IntlMessage from '../../../components/util-components/IntlMessage'

import {
  ORDER_STATUS_GROUP_TO_ORDER_STATUSES,
  Order,
  OrderStatusEnum,
  OrderStatusGroupEnum,
  ViewTypeEnum,
  mapOrderResponseToOrder,
  useViewType,
} from '../../../utils/orders.util'
import {
  OrdersAntTable,
  OrdersErpTable,
  OrdersGrid,
  OrdersViewHeader,
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersFilterByUserEnum, disableOrderFilterByAll, enableOrderFilterByAll, setTableOrders } from '../../../store/orders-table/reducer'
import { selectOrdersFilterByUser } from '../../../store/orders-table/selectors'
import * as Sentry from '@sentry/react'
import { selectSelectedCompanyId, selectUserRole } from '../../../store/companies/selectors'
import { UserRoleEnum } from '../../../services/CompanyService'

export const NotLinked = () => <>
  <div className='text-center mt-4'>
    <img
      className='img-fluid mb-5'
      src='/img/svg/into_the_night.svg'
      width='300'
      alt=''
    />
  </div>
  <h1 className='text-center'>
    <IntlMessage id='orders.not_connected.header' />
  </h1>
  <p className='text-center'>
    <IntlMessage id='orders.not_connected.text' />
  </p>
</>

type OrdersViewRouteParams = {
  statusGroup: OrderStatusGroupEnum;
}

const OrdersView = () => {
  const dispatch = useDispatch()
  const { statusGroup } = useParams<OrdersViewRouteParams>()
  const [orders, setOrders] = useState<Array<Order>>([])
  const [isLoading, setLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState('all')
  const [isError, setError] = useState(false)
  const itemsCountByStatus: Record<string, number> = {}
  const ordersFilterByUser: OrdersFilterByUserEnum = useSelector(selectOrdersFilterByUser)
  const userRole: UserRoleEnum | null = useSelector(selectUserRole)
  const selectedCompanyId: string | null = useSelector(selectSelectedCompanyId)

  const filteredOrders: Order[] = orders.filter(({ status }) => {
    if (!itemsCountByStatus[status]) {
      itemsCountByStatus[status] = 0
    }
    itemsCountByStatus[status]++

    return (statusFilter === 'all' || statusFilter === status) && ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup].includes(status)
  })
  filteredOrders.sort((a, b) => {
    if (a.status === OrderStatusEnum.Delivered && b.status === OrderStatusEnum.Delivered) {
      if (a.supplyDate > b.supplyDate) {
        return 1
      }
      else if (a.supplyDate < b.supplyDate) {
        return -1
      }
      return 0
    }
    else {
      if (a.id > b.id) {
        return 1
      }
      else if (a.id < b.id) {
        return -1
      }
      return 0
    }
  })

  const getOrders = useCallback(() => {
    setLoading(true)
    setOrders([])
    dispatch(setTableOrders([]))

    const isUserOrdersOnly = ordersFilterByUser === OrdersFilterByUserEnum.User

    OrdersService
      .getOrders(isUserOrdersOnly)
      .then((data: GetOrdersResponse) => {
        if (data.result === 'error' && data.error_reason === 'NotLinkedToCompany') {
          setError(true)
          return
        }

        if (data.show_only_my_orders) {
          dispatch(disableOrderFilterByAll())
        }
        else {
          dispatch(enableOrderFilterByAll())
        }

        const ordersData: Array<Order> = data?.orders?.map(mapOrderResponseToOrder)
        setOrders(ordersData)
        dispatch(setTableOrders(ordersData))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных по сделкам',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, ordersFilterByUser])

  useEffect(() => {
    if (!userRole || (userRole === UserRoleEnum.Sales && !selectedCompanyId)) {
      return
    }

    getOrders()
  }, [getOrders, userRole, selectedCompanyId])

  const location = useLocation()
  useEffect(() => {
    setStatusFilter('all')
  }, [location])

  const tableType: ViewTypeEnum = useViewType(ViewTypeEnum.ErpTable)
  const hasData = filteredOrders.length !== 0
  const isTableVisible = hasData && !isLoading && !isError
  const areHeaderFiltersVisible = tableType !== ViewTypeEnum.ErpTable
  const isUserFilterVisible = tableType === ViewTypeEnum.ErpTable
  const isEmptyBlockVisible = !hasData && !isLoading && !isError

  return (
    <>
      {isLoading && (
        <Loading cover='content' />
      )}

      <OrdersViewHeader
        isLoading={isLoading}
        isNotLinked={isError}
        itemsCountByStatus={itemsCountByStatus}
        statusFilter={statusFilter}
        statusGroup={statusGroup}
        areFiltersVisible={areHeaderFiltersVisible}
        isUserFilterVisible={isUserFilterVisible}
        setStatusFilter={setStatusFilter}
      />

      {isError && (
        <NotLinked />
      )}

      {isEmptyBlockVisible && (
        <div className='mt-4'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}

      {isTableVisible && (tableType === ViewTypeEnum.Grid) && (
        <div className='grid-table'>
          <OrdersGrid orders={filteredOrders} />
        </div>
      )}

      {isTableVisible && (tableType === ViewTypeEnum.ErpTable) && (
        <div
          className='erp-table'
          style={{ padding: '5px 10px 10px' }}
        >
          <OrdersErpTable
            statusGroup={statusGroup}
          />
        </div>
      )}

      {isTableVisible && (tableType === ViewTypeEnum.AntDesignTable) && (
        <div className='ant-design-table'>
          <OrdersAntTable orders={filteredOrders} />
        </div>
      )}
    </>
  )
}

export default OrdersView
