import { DataTableColumnInfo } from '@appscience/data-table'
import { defaultCompare } from '@appscience/utils'
import { useIntl } from 'react-intl'
import { LinkToDocument } from '../../../../../../components/custom-components/LinkToDocument/LinkToDocument'
import { formatPrice, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { TableIntegratedDeliveryNote, TableIntegratedDeliveryNoteColumnId } from '../OrderIdnTable.type'

export function useIdnTableColumns(): Array<DataTableColumnInfo<TableIntegratedDeliveryNoteColumnId, TableIntegratedDeliveryNote>> {
  const intl = useIntl()

  return [{
    id: 'idnId',
    content: (idn: TableIntegratedDeliveryNote) =>
      <LinkToDocument
        text={idn.idnId}
        url={idn.pdfUrl}
      />,
    fr: 3,
    minWidth: '60px',
    title: intl.messages['idnTable.columns.idnId'],
    sort: (a: TableIntegratedDeliveryNote, b: TableIntegratedDeliveryNote) =>
      defaultCompare(a.idnId, b.idnId),
  }, {
    id: 'date',
    content: (idn: TableIntegratedDeliveryNote) => unixTimestampToFormattedDate(idn?.date) || '-',
    fr: 6,
    minWidth: '120px',
    title: intl.messages['idnTable.columns.date'],
    sort: (a: TableIntegratedDeliveryNote, b: TableIntegratedDeliveryNote) =>
      (a.date || 0) - (b.date || 0),
  }, {
    id: 'positionsCount',
    content: (idn: TableIntegratedDeliveryNote) => idn.positionsCount || '-',
    fr: 5,
    minWidth: '100px',
    title: intl.messages['idnTable.columns.positionsCount'],
    sort: (a: TableIntegratedDeliveryNote, b: TableIntegratedDeliveryNote) =>
      (a.positionsCount || 0) - (b.positionsCount || 0),
  }, {
    id: 'sum',
    content: (idn: TableIntegratedDeliveryNote) => formatPrice(idn.sum || 0) || '-',
    fr: 5,
    minWidth: '100px',
    title: intl.messages['idnTable.columns.sum'],
    sort: (a: TableIntegratedDeliveryNote, b: TableIntegratedDeliveryNote) =>
      (a.sum || 0) - (b.sum || 0),
  }]
}
