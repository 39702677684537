import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

export type SurveyStepItemSelectValueMessage = {
  min_value: string;
  max_value: string;
}

export enum SurveyStepItemType {
  Select = 'select',
  Text = 'text',
}

export type SurveyStepItem = {
  type: SurveyStepItemType;
  name: string;
  description: string;
  select_value_message: SurveyStepItemSelectValueMessage | null;
  required: boolean;
  value: string;
}

export type SurveyStep = {
  description: string;
  is_completed: boolean;
  name: string;
  spend_time_ms: number;
  items: Array<SurveyStepItem>;
}

export type Survey = {
  meta_info: {
    is_completed: boolean;
  },
  steps: Array<SurveyStep>;
}

export const SURVEY_MOCK: Survey = {
  meta_info: {
    is_completed: false,
  },
  steps: [
    {
      description: 'step description 1',
      is_completed: false,
      name: 'Общие впечатления от работы с нами',
      spend_time_ms: 0,
      items: [
        {
          description: 'item description 1',
          name: '1 Насколько Вы удовлетворены работой с компанией AppScience?',
          required: false,
          select_value_message: {
            max_value: 'Очень плохо',
            min_value: 'Превосходно',
          },
          type: SurveyStepItemType.Select,
          value: '',
        },
        {
          description: 'item description 1',
          name: '2 Насколько Вы удовлетворены работой с компанией AppScience?',
          required: false,
          select_value_message: null,
          type: SurveyStepItemType.Text,
          value: '',
        },
      ],
    },
  ],
}

export type GetSurveyResponse = Survey

export type UpdateSurveyRequest = Survey

type SurveyServiceType = {
  getSurvey: () => Promise<GetSurveyResponse>;
  updateSurvey: (survey: Survey) => Promise<void>;
}

export const SurveyService: SurveyServiceType = {
  // getSurvey: () =>
  // Promise.resolve(SURVEY_MOCK),
  getSurvey: () =>
    axiosInstance
      .get<never, GetSurveyResponse>(
        `${API_URL.CUSTOMER_AREA}/analytics/feedback`,
      ),
  updateSurvey: (survey: Survey) =>
    axiosInstance
      .post<never, void, UpdateSurveyRequest>(
        `${API_URL.CUSTOMER_AREA}/analytics/feedback`,
        survey,
      ),
}
