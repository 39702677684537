import { Text } from '@mantine/core'
import {DocumentDownloadIcon} from '@heroicons/react/outline'
import { orderPropertyToCSV } from '../OrdersErpTable.utils'
import { TableOrder, TableOrderColumnId } from '../OrdersErpTable.type'
import { useIntl } from 'react-intl'
import { DataTableColumnInfo } from '@appscience/data-table'
import { useMemo } from 'react'
import { ExtraActionData, useExcelExportPopup } from '../../../../../../hooks/useExcelExportPopup/useExcelExportPopup'

export function useOrdersErpTableExtraActions(
  columns: Array<DataTableColumnInfo<TableOrderColumnId, TableOrder>>,
  selectedRowIds: Array<string>,
  data: Array<TableOrder>,
): Array<ExtraActionData> {
  const intl = useIntl()
  const messages = intl.messages as Record<string, string>

  const { openExportPopup } = useExcelExportPopup<TableOrderColumnId, TableOrder>({
    headerAccessor: columnId => messages[`ordersTable.columns.${columnId}`],
    contentAccessor: (order: TableOrder, columnId: TableOrderColumnId) =>
      orderPropertyToCSV(order, columnId, messages),
  }, columns, selectedRowIds, data)

  return useMemo<Array<ExtraActionData>>(() => [{
    id: 'export-xls',
    text: intl.messages['ordersTable.export.xlsx'] as string,
    icon: <DocumentDownloadIcon className='w-4 h-4' />,
    rightSection: <Text size='xs' color='dimmed'>Ctrl + Q</Text>,
    onClick: openExportPopup,
  }], [openExportPopup, intl.messages])
}
