import { DataTableColumnInfo } from '@appscience/data-table'
import { caseInsensitiveCompare, defaultCompare } from '@appscience/utils'
import { useIntl } from 'react-intl'
import { LinkToOrder, ORDER_STATUS_TO_PRIORITY, OrderStatusGroupEnum, formatPrice, mapOrderStatusToBadge, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { ResponsiblePersons } from '../content/ResponsiblePersons/ResponsiblePersons'
import { TableOrder, TableOrderColumnId } from '../OrdersErpTable.type'
import { DeliveryDeadlineCell, ExpressCell } from '../cells'
import { ExpressIcon } from '../../../../../../components/custom-components/ExpressIcon'

function getFormattedBillId(billId: string): string {
  return billId && billId !== '0'
    ? billId
    : '-'
}

export function useOrdersErpTableColumns(statusGroup: OrderStatusGroupEnum): Array<DataTableColumnInfo<TableOrderColumnId, TableOrder>> {
  const intl = useIntl()

  return [{
    id: 'isExpress',
    content: (order: TableOrder) => <ExpressCell order={order} />,
    fr: 1.5,
    minWidth: '30px',
    title: <ExpressIcon isOn={false} />,
    sort: (a: TableOrder, b: TableOrder) => defaultCompare(a.isExpress, b.isExpress),
  }, {
    id: 'id',
    content: (order: TableOrder) => <LinkToOrder orderId={+order.id} />,
    fr: 3,
    minWidth: '50px',
    title: intl.messages['ordersTable.columns.id'],
    sort: (a: TableOrder, b: TableOrder) => defaultCompare(+(a.id || 0), +(b.id || 0)),
  }, {
    id: 'billId',
    content: (order: TableOrder) => getFormattedBillId(order.billId),
    fr: 3,
    minWidth: '60px',
    title: intl.messages['ordersTable.columns.billId'],
    sort: (a: TableOrder, b: TableOrder) => defaultCompare(+(a.billId || 0), +(b.billId || 0)),
  }, {
    id: 'approvalDate',
    content: (order: TableOrder) => unixTimestampToFormattedDate(order.approvalDate) || '-',
    fr: 3,
    minWidth: '60px',
    title: intl.messages['ordersTable.columns.approvalDate'],
    sort: (a: TableOrder, b: TableOrder) => (a.approvalDate || 0) - (b.approvalDate || 0),
  }, {
    id: 'supplyDeadlineDate',
    content: (order: TableOrder) => <DeliveryDeadlineCell order={order} />,
    fr: 3,
    minWidth: '125px',
    title: intl.messages['ordersTable.columns.supplyDeadlineDate'],
    sort: (a: TableOrder, b: TableOrder) => (a.maxSupplyDeadlineDate || 0) - (b.maxSupplyDeadlineDate || 0),
  }, {
    id: 'status',
    content: (order: TableOrder) => mapOrderStatusToBadge(order?.status) || '-',
    fr: 7.5,
    minWidth: '150px',
    title: intl.messages['ordersTable.columns.status'],
    sort: (a: TableOrder, b: TableOrder) =>
      (ORDER_STATUS_TO_PRIORITY[a?.status] || 0) - (ORDER_STATUS_TO_PRIORITY[b?.status] || 0),
  }, {
    id: 'responsiblePersons',
    content: (order: TableOrder) => <ResponsiblePersons persons={order.responsiblePersons} /> || '-',
    fr: 5.5,
    minWidth: '110px',
    title: intl.messages['ordersTable.columns.responsiblePersons'],
    sort: (a: TableOrder, b: TableOrder) => {
      const aName: string = a?.responsiblePersons?.[0]?.name || ''
      const bName: string = b?.responsiblePersons?.[0]?.name || ''

      return caseInsensitiveCompare(aName, bName)
    },
  }, {
    id: 'name',
    content: (order: TableOrder) => order.name || '-',
    fr: 9,
    minWidth: '160px',
    title: intl.messages['ordersTable.columns.name'],
    sort: (a: TableOrder, b: TableOrder) => caseInsensitiveCompare(a.name, b.name),
  }, {
    id: 'paymentValue',
    content: (order: TableOrder) => formatPrice(order.paymentValue || 0) || '-',
    fr: 3,
    minWidth: '60px',
    title: intl.messages['ordersTable.columns.paymentValue'],
    sort: (a: TableOrder, b: TableOrder) => (a.paymentValue || 0) - (b.paymentValue || 0),
  }, {
    id: 'supplyDate',
    content: (order: TableOrder) => unixTimestampToFormattedDate(order.supplyDate) || '-',
    fr: 3,
    minWidth: '60px',
    title: intl.messages['ordersTable.columns.supplyDate'],
    sort: (a: TableOrder, b: TableOrder) => (a.supplyDate || 0) - (b.supplyDate || 0),
  }, {
    id: 'paymentWaiting',
    content: (order: TableOrder) => order.paymentWaiting || '-',
    fr: 2.5,
    minWidth: '50px',
    title: intl.messages['ordersTable.columns.paymentWaiting'],
    sort: (a: TableOrder, b: TableOrder) => (a.paymentWaiting || 0) - (b.paymentWaiting || 0),
  }].filter(({ id }) => isColumnVisible(id as TableOrderColumnId, statusGroup)) as Array<DataTableColumnInfo<TableOrderColumnId, TableOrder>>
}

function isColumnVisible(columnId: TableOrderColumnId, statusGroup: OrderStatusGroupEnum): boolean {
  switch (columnId) {
    case 'approvalDate':
    case 'supplyDeadlineDate':
    case 'supplyDate':
    case 'paymentWaiting':
      return statusGroup !== OrderStatusGroupEnum.Proposals
    default:
      return true
  }
}
