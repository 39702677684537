import React from 'react'
import PageHeaderAlt from '../../../components/layout-components/PageHeaderAlt'
import Flex from '../../../components/shared-components/Flex'
import IntlMessage from '../../../components/util-components/IntlMessage'
import {OrderEditionContent} from './content'

export const OrderEditionViewer = () => (
  <>
    <PageHeaderAlt className='border-bottom'>
      <div className='container-fluid'>
        <Flex justifyContent='between' alignItems='center'>
          <h2><IntlMessage id={'sidenav.my_orders.edition.title'}/></h2>
        </Flex>
      </div>
    </PageHeaderAlt>

    {/*Proposal Content*/}
    <OrderEditionContent/>
  </>
)
