import { DataTableFiltersData } from '@appscience/data-table'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIntegratedDeliveryNotes } from '../../../../../../store/idn-table/selector'
import { IntegratedDeliveryNote } from '../../../../../../utils/orders.util'
import { TableIntegratedDeliveryNote, TableIntegratedDeliveryNoteColumnId } from '../OrderIdnTable.type'
import { mapIntegratedDeliveryNoteToTableIntegratedDeliveryNote } from '../OrderIdnTable.utils'

type IdnTableFiltersResult = {
  filtersData: DataTableFiltersData<TableIntegratedDeliveryNoteColumnId>;
  filteredByColumnsTableIntegratedDeliveryNotes: Array<TableIntegratedDeliveryNote>;
}

export function useIdnTableFilters(): IdnTableFiltersResult {
  const idns: Array<IntegratedDeliveryNote> = useSelector(selectIntegratedDeliveryNotes)
  const tableIdns: TableIntegratedDeliveryNote[] = useMemo(() =>
    idns
      .map(mapIntegratedDeliveryNoteToTableIntegratedDeliveryNote)
  , [idns])

  const filtersData: DataTableFiltersData<TableIntegratedDeliveryNoteColumnId> = {}

  return {
    filtersData,
    filteredByColumnsTableIntegratedDeliveryNotes: tableIdns,
  }
}
