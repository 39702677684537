import { Select, notification } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import PaymentOptionsService, { PaymentDelay, PaymentType, getPaymentContract } from '../../../../services/PaymentOptionsService'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultPaymentDelay, setDefaultPaymentType, setPaymentDelays, setPaymentOptionsLoading, setPaymentTypes, setSelectedPaymentDelay, setSelectedPaymentType } from '../../../../store/payment-options/reducer'
import { selectPaymentDelays, selectPaymentOptionsLoading, selectPaymentTypes, selectSelectedPaymentDelay, selectSelectedPaymentType } from '../../../../store/payment-options/selectors'
import { useIntl } from 'react-intl'
import styles from './PaymentOptions.module.css'
import { FilterFunc } from 'rc-select/lib/Select'
import * as Sentry from '@sentry/react'
import { ProductBasketType } from '../../../../store/basket/types'
import { selectBasketProducts } from '../../../../store/basket/selectors'
import { selectAnalogs, selectProducts } from '../../../../store/search/selectors'
import BasketService from '../../../../services/BasketService'
import { BASKET_KEY } from '../../../../constants/localStorage'
import { setProductsListAction } from '../../../../store/basket/reducer'
import { ProductType } from '../../../../store/search/types'
import { setAnalogsList, setProductsList } from '../../../../store/search/reducer'
import { uniq } from 'ramda'

export function PaymentOptions() {
  const intl = useIntl()
  const dispatch = useDispatch()

  const paymentTypes: Array<PaymentType> = useSelector(selectPaymentTypes)
  const selectedPaymentType: PaymentType | null = useSelector(selectSelectedPaymentType)
  const paymentDelays: Array<PaymentDelay> = useSelector(selectPaymentDelays)
  const selectedPaymentDelay: PaymentDelay | null = useSelector(selectSelectedPaymentDelay)
  const isPaymentOptionsLoading: boolean = useSelector(selectPaymentOptionsLoading)

  const basketProducts: Array<ProductBasketType> = useSelector(selectBasketProducts)
  const basketProductIds: Array<string> = useMemo(
    () =>
      (basketProducts ?? [])
        .map(({ id }) => id)
        .filter(Boolean),
    [basketProducts],
  )

  const searchResultProducts: Array<ProductType> = useSelector(selectProducts)
  const searchResultProductIds: Array<string> = useMemo(
    () => searchResultProducts?.map(({ id }) => id) ?? [],
    [searchResultProducts],
  )

  const analogProducts: Array<ProductType> = useSelector(selectAnalogs)
  const analogProductIds: Array<string> = useMemo(
    () => analogProducts?.map(({ id }) => id) ?? [],
    [analogProducts],
  )

  useEffect(() => {
    dispatch(setDefaultPaymentType(null))
    dispatch(setDefaultPaymentDelay(null))
    dispatch(setPaymentOptionsLoading(true))

    Promise.all([
      PaymentOptionsService
        .getPaymentConditions(),
      PaymentOptionsService
        .getPaymentContract(),
    ])
      .then(([{
        conditions: allPaymentsTypes,
        n_days: allPaymentDelays,
        default: defaultPaymentConditions,
      }, {
        conditions: paymentType,
        n_days: paymentDelay,
      }]) => {
        const defaultPaymentType = defaultPaymentConditions.conditions
        const defaultPaymentDelay = `${defaultPaymentConditions.n_days}`

        dispatch(setPaymentTypes(allPaymentsTypes))
        dispatch(setDefaultPaymentType(defaultPaymentType))
        dispatch(setSelectedPaymentType(null))
        dispatch(setPaymentDelays(allPaymentDelays))
        dispatch(setDefaultPaymentDelay(defaultPaymentDelay))
        dispatch(setSelectedPaymentDelay(null))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных об условиях оплаты',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setPaymentOptionsLoading(false))
      })
  }, [dispatch])

  const updateBasketProductPrices = useCallback((paymentType: PaymentType | null, paymentDelay: PaymentDelay | null) => {
    if (!basketProductIds?.length) {
      dispatch(setSelectedPaymentType(paymentType))
      dispatch(setSelectedPaymentDelay(paymentDelay))
      return
    }

    dispatch(setPaymentOptionsLoading(true))

    const productIds: Array<string> =
      uniq(
        basketProductIds
          .concat(searchResultProductIds)
          .concat(analogProductIds),
      )

    BasketService
      .getProductsInfoByIds({
        ids: productIds,
        payment_contract: getPaymentContract(
          paymentType,
          paymentDelay,
        ),
      })
      .then(({ partner_products }) => {
        const productIdToProductMap =
          (partner_products ?? []).reduce(
            (result, product) => result.set(product.id, product),
            new Map<string, ProductType>(),
          )
        const newBasketProducts: Array<ProductBasketType> =
          basketProducts
            .map(product => {
              const { price = 0, currency_prices = [] } = productIdToProductMap.get(product.id) ?? {}

              return {
                ...product,
                price,
                currency_prices,
              }
            })

        localStorage.setItem(BASKET_KEY, JSON.stringify(newBasketProducts))
        dispatch(setProductsListAction(newBasketProducts))

        const newSearchResultProducts: Array<ProductType> =
          searchResultProducts
            .map(product => {
              const { price = 0, currency_prices = [] } = productIdToProductMap.get(product.id) ?? {}

              return {
                ...product,
                price,
                currency_prices,
              }
            })
        dispatch(setProductsList(newSearchResultProducts))

        const newAnalogs: Array<ProductType> =
          analogProducts
            .map(product => {
              const { price = 0, currency_prices = [] } = productIdToProductMap.get(product.id) ?? {}

              return {
                ...product,
                price,
                currency_prices,
              }
            })
        dispatch(setAnalogsList(newAnalogs))

        dispatch(setSelectedPaymentType(paymentType))
        dispatch(setSelectedPaymentDelay(paymentDelay))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных о товарах',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setPaymentOptionsLoading(false))
      })
  }, [basketProductIds, basketProducts, dispatch, searchResultProductIds, searchResultProducts, analogProducts, analogProductIds])

  const onSelectPaymentType = useCallback((paymentType: PaymentType) => {
    updateBasketProductPrices(paymentType, selectedPaymentDelay)
  }, [updateBasketProductPrices, selectedPaymentDelay])

  const onSelectPaymentDelay = useCallback((paymentDelay: PaymentDelay) => {
    updateBasketProductPrices(selectedPaymentType, paymentDelay)
  }, [updateBasketProductPrices, selectedPaymentType])

  const onFilterPaymentTypeOption = useCallback<FilterFunc<{ value: PaymentType }>>(
    (inputValue, option) => {
      const { value } = option ?? {}
      if (!value) {
        return false
      }

      const paymentTypeMessage: string = intl.messages[`paymentOptions.paymentType.${value}`] as string
      return paymentTypeMessage
        ?.toLowerCase()
        ?.includes?.(inputValue?.toLowerCase())
    }
    , [intl.messages])

  return (
    <div
      className={styles['root']}
    >
      <div
        className={styles['option']}
      >
        <div
          className={styles['option-title']}
        >
          {intl.messages['paymentOptions.paymentType.title']}
        </div>
        <Select
          className={styles['payment-type']}
          placeholder={intl.messages['paymentOptions.paymentType.title']}
          showSearch
          loading={isPaymentOptionsLoading}
          disabled={isPaymentOptionsLoading}
          value={selectedPaymentType}
          filterOption={onFilterPaymentTypeOption}
          onChange={onSelectPaymentType}
        >
          {paymentTypes.map((paymentType, index) => (
            <Select.Option
              key={index}
              value={paymentType}
            >
              {intl.messages[`paymentOptions.paymentType.${paymentType}`] || '-'}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div
        className={styles['option']}
      >
        <div
          className={styles['option-title']}
        >
          {intl.messages['paymentOptions.paymentDelay.title']}
        </div>
        <Select
          className={styles['payment-delay']}
          placeholder={intl.messages['paymentOptions.paymentDelay.title']}
          showSearch
          loading={isPaymentOptionsLoading}
          disabled={isPaymentOptionsLoading}
          value={selectedPaymentDelay}
          onChange={onSelectPaymentDelay}
        >
          {paymentDelays.map((paymentDelay, index) => (
            <Select.Option
              key={index}
              value={paymentDelay}
            >
              <div>
                {paymentDelay}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
