import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

export type GetUserInfoResponse = {
  company_id: string;
  contact_id: string;
  email: string;
  metrica_user_id: string;
  name: string;
  phone: string;
  show_feedback_form: boolean;
  showNewOrderButton: boolean;
  user_id: string;
}

export type UserServiceType = {
  getUserInfo: () => Promise<GetUserInfoResponse>
}

const UserInfoService: UserServiceType = {
  getUserInfo: () =>
    axiosInstance.get<never, GetUserInfoResponse>(
      `${API_URL.CUSTOMER_AREA}/user-info`,
    ),
}

export default UserInfoService

