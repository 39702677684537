import {DataTable, DataTableColumnInfo, DataTableSearchFn} from '@appscience/data-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage/useLocalStorage'
import { selectCurrentTheme } from '../../../../../store/theme/selectors'
import { OrderStatusGroupEnum, ThemeColorEnum, getCustomTableTheme } from '../../../../../utils/orders.util'
import { OrdersTableGlobalFiltersSection } from './content/OrdersTableGlobalFiltersSection'
import { useOrdersErpTableColumns } from './hooks/OrdersErpTableColumns.hook'
import { useOrdersErpTableExtraActions } from './hooks/OrdersErpTableExtraActions.hook'
import { useOrdersErpTableFilters } from './hooks/OrdersErpTableFilters.hook'
import { useGlobalFilteredTableOrders, useOrdersTableSearchFn } from './hooks/OrdersErpTableGlobalFilters.hook'
import { useOrdersErpTableInitialData } from './hooks/OrdersErpTableInitialData.hook'
import { TableOrder, TableOrderColumnId } from './OrdersErpTable.type'
import { orderPropertyToCSV } from './OrdersErpTable.utils'

type OrdersErpTableProps = {
  statusGroup: OrderStatusGroupEnum;
}

export function OrdersErpTable({ statusGroup }: OrdersErpTableProps) {
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme)
  const customTableTheme = useMemo(() => getCustomTableTheme(theme), [theme])
  const intl = useIntl()
  const messages = intl.messages as Record<string, string>
  const columns: Array<DataTableColumnInfo<TableOrderColumnId, TableOrder>> = useOrdersErpTableColumns(statusGroup)
  const {filtersData, filteredByColumnsTableOrders} = useOrdersErpTableFilters(statusGroup)
  const globalFilteredTableOrders: Array<TableOrder> = useGlobalFilteredTableOrders(filteredByColumnsTableOrders)
  const searchFn: DataTableSearchFn<TableOrder> = useOrdersTableSearchFn()
  const {setValueToStorage} = useLocalStorage('ordersStage')
  const initData = useOrdersErpTableInitialData(globalFilteredTableOrders)

  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([])
  const extraActions = useOrdersErpTableExtraActions(columns, selectedRowIds, globalFilteredTableOrders)

  return <DataTable
    data={globalFilteredTableOrders}
    columns={columns}
    pagination={{
      initSize: initData.paginationSize,
      initPageIndex: initData.paginationPage,
      sizesList: [5, 10, 15, 20],
      onPageIndexChange: index => setValueToStorage('paginationPage', index),
      onSizeChange: size => setValueToStorage('paginationSize', size),
    }}
    select={{
      type: 'multi',
      selectedRowIds,
      onChange: ids => setSelectedRowIds(ids),
    }}
    layout={{horizontalScroll: true}}
    filtersData={filtersData}
    searchData={{ searchFn }}
    exportCsv={{
      headerAccessor: columnId => messages[`ordersTable.columns.${columnId}`],
      contentAccessor: (order: TableOrder, columnId: TableOrderColumnId) =>
        orderPropertyToCSV(order, columnId, messages),
    }}
    actionsSection={{
      subsection: <OrdersTableGlobalFiltersSection statusGroup={statusGroup} />,
      className: 'justify-end items-end',
    }}
    theme={customTableTheme}
    extraActions={extraActions}
  />
}
