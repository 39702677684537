import {LoadingOutlined} from '@ant-design/icons'
import {Card} from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import './order-loading.css'

type OrderLoadingMessage = {
  message: string;
  time: number;
}

function useMessages(): Array<OrderLoadingMessage> {
  const intl = useIntl()

  return useMemo(() => [
    {
      message: intl.messages['order_loading_spinner.messages.processing_data'] as string,
      time: 0,
    }, {
      message: intl.messages['order_loading_spinner.messages.adding_to_system'] as string,
      time: 3000,
    }, {
      message: intl.messages['order_loading_spinner.messages.preparing_pdf'] as string,
      time: 6000,
    }, {
      message: intl.messages['order_loading_spinner.messages.sending_to_email'] as string,
      time: 9000,
    },
  ], [intl.messages])
}

type OrderLoadingProps = {
  showMessages: boolean
}

export const OrderLoading: React.FC<OrderLoadingProps> = ({ showMessages }) => {
  const messages: Array<OrderLoadingMessage> = useMessages()
  const [status, setStatus] = useState('')

  useEffect(() => {
    let isMounted = true
    const timerIds: number[] = messages.map(({ message, time }) => window.setTimeout(() => {
      isMounted && setStatus(message)
    }, time))

    return () => {
      isMounted = false
      timerIds.forEach(timerId => {
        clearTimeout(timerId)
      })
    }
  }, [messages])

  return (
    <Card className='order-loading'>
      <div className='order-loading__container d-flex flex-column justify-content-center align-items-center'>
        <LoadingOutlined className='order-loading__spinner' />
        {showMessages &&
          <div className='order-loading__status'>
            {status}
          </div>
        }
      </div>
    </Card>
  )
}