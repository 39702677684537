import { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Badge, Text } from '@mantine/core'
import Logo from './Logo'
import NavPanel from './NavPanel'
import NavSearch from './NavSearch'
import {
  onMobileNavToggle,
  toggleCollapsedNav,
} from '../../store/theme/reducer'
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/ThemeConstant'
import utils from '../../utils'
import { selectSelectedCompanyName } from '../../store/companies/selectors'
import { DirectionEnum, ThemeConfigType } from '../../configs/AppConfig'
import { ENVIRONMENT, IS_PROD_ENVIRONMENT } from '../../constants/environment'
import './HeaderNav.css'
import { SurveyModal } from './SurveyModal/SurveyModal'

const { Header } = Layout

type HeaderNavProps = {
  navCollapsed: boolean;
  mobileNav: boolean;
  navType: string;
  headerNavColor: string;
  isMobile: boolean;
  currentTheme: string;
  direction: DirectionEnum;
}

export const HeaderNav = ({
  navCollapsed,
  mobileNav,
  navType,
  headerNavColor,
  isMobile,
  currentTheme,
  direction,
}: HeaderNavProps) => {
  const [searchActive, setSearchActive] = useState(false)
  const dispatch = useDispatch()
  const selectedCompanyName = useSelector(selectSelectedCompanyName)

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (isMobile) {
      dispatch(onMobileNavToggle(!mobileNav))
    }
    else {
      dispatch(toggleCollapsedNav(!navCollapsed))
      localStorage.setItem('lk_isCollapsedSideNav', navCollapsed ? '0' : '1')
    }
  }

  const isNavTop = (navType === NAV_TYPE_TOP)
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    }
    else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div
          className='nav'
          style={{ width: `calc(100% - ${getNavWidth()})` }}
        >
          <div className='nav-left'>
            <ul className='ant-menu ant-menu-root ant-menu-horizontal'>
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className='ant-menu-item ant-menu-item-only-child' onClick={() => {
                    onToggle()
                  }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className='nav-icon' /> : <MenuFoldOutlined className='nav-icon' />}
                  </li>
              }
            </ul>
          </div>

          <SurveyModal />

          <div className='nav-right'>
            {!IS_PROD_ENVIRONMENT && (
              <div className='d-flex align-items-center mr-3'>
                <Badge
                  variant='filled'
                  size='lg'
                >
                  {ENVIRONMENT} env
                </Badge>
              </div>
            )}
            {!!selectedCompanyName && (
              <div className='d-flex align-items-center mr-3'>
                <Text
                  size='lg'
                  weight={700}
                  style={{
                    maxWidth: 400,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={selectedCompanyName}
                >
                  {selectedCompanyName}
                </Text>
              </div>
            )}
            <NavPanel direction={direction} />
          </div>
          <NavSearch
            active={searchActive}
            close={onSearchClose}
          />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }: { theme: ThemeConfigType, }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
}

export default connect(mapStateToProps)(HeaderNav)
