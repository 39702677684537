import {useState} from 'react'
import {useSelector} from 'react-redux'
import {selectBasketProducts, selectEmail} from '../../../../store/basket/selectors'

export const useOrderEdition = () => {
  const [showSuccessOrder, _setShowSuccessOrder] = useState<boolean>(false)
  const [orderId, _setOrderId] = useState<string>('')
  const basketProducts = useSelector(selectBasketProducts)
  const email = useSelector(selectEmail)

  const setShowSuccessOrder = (status: boolean, id: string) => {
    _setShowSuccessOrder(status)
    _setOrderId(id)
  }

  return {
    //props
    showSuccessOrder,
    orderId,
    showTitle: !!basketProducts.length,
    email,

    //methods
    setShowSuccessOrder,
  }
}
