import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

export enum UserRoleEnum {
  Sales = 'sales',
  Customer = 'customer',
}
export type Company = {
  id: string;
  name: string;
}
export type GetCompaniesResponse = {
  user_role: UserRoleEnum;
  companies: Array<Company>;
}

type CompantServiceType = {
  getCompanies: () => Promise<GetCompaniesResponse>,
}

export const CompanyService: CompantServiceType = {
  getCompanies: () =>
    axiosInstance
      .get<never, GetCompaniesResponse>(
        `${API_URL.CUSTOMER_AREA}/get-companies`,
      ),
}
