import {
  IdnTableLocalStorage,
  OrderItemsStageLocalStorage,
  OrdersStageLocalStorage,
  PaymentOptionsLocalStorage,
  SettingsStorage,
} from './types'
import { useMemo } from 'react'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'

export interface CustomerAreaStorage {
  ordersStage: OrdersStageLocalStorage,
  orderItemsStage: OrderItemsStageLocalStorage,
  settings: SettingsStorage,
  idnTable: IdnTableLocalStorage,
  paymentOptions: PaymentOptionsLocalStorage,
}

type StorageKeys<T extends keyof CustomerAreaStorage> = keyof CustomerAreaStorage[T]
type KeyValue<Storage extends keyof CustomerAreaStorage, Key extends keyof CustomerAreaStorage[Storage]> = CustomerAreaStorage[Storage][Key]

export function useLocalStorage<STORAGE extends keyof CustomerAreaStorage>(storage: STORAGE) {
  return useMemo(() => getLocalStorageCallbacks(storage), [storage])
}

const generateKey = (storage: keyof CustomerAreaStorage) => `customer-area_${storage}`

export function getLocalStorageCallbacks<STORAGE extends keyof CustomerAreaStorage>(storage: STORAGE) {
  function set<KEY extends StorageKeys<STORAGE>>(key: KEY, value: KeyValue<STORAGE, KEY>) {
    setValueToStorage(storage, key, value)
  }
  function get<KEY extends StorageKeys<STORAGE>>(key: KEY) {
    return getStorageValue(storage, key)
  }

  return {
    setValueToStorage: set,
    getStorageValue: get,
    getStorageObject: () => getStorageObject(storage),
  }
}

export function getStorageObject<STORAGE extends keyof CustomerAreaStorage>(storage: STORAGE): CustomerAreaStorage[STORAGE] | null {
  const rawValue = localStorage.getItem(generateKey(storage))
  if (rawValue === null) {
    return null
  }
  return parseJSON<CustomerAreaStorage[STORAGE]>(rawValue)
}

function getStorageValue<STORAGE extends keyof CustomerAreaStorage, KEY extends StorageKeys<STORAGE>>(storage: STORAGE, key: KEY): KeyValue<STORAGE, KEY> | null {
  const parsedData = getStorageObject(storage)
  return parsedData ? parsedData[key] : null
}

function setValueToStorage<STORAGE extends keyof CustomerAreaStorage, KEY extends StorageKeys<STORAGE>>(storage: STORAGE, key: KEY, value: KeyValue<STORAGE, KEY>) {
  const currentState = getStorageObject(storage)
  localStorage.setItem(generateKey(storage), JSON.stringify({
    ...currentState,
    [key]: value,
  }))
}

function parseJSON<EXPECTED = undefined>(json: string): EXPECTED | null {
  try {
    return JSON.parse(json) as EXPECTED
  }
  catch (error: AnyType) {
    notification.error({
      message: 'Ошибка персистентного хранилища',
      description: error.message,
      duration: 5,
    })
    Sentry.captureException(error)
    return null
  }
}
