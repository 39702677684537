import { GlobalMultiSelect } from '@appscience/data-table'
import { checkNever } from '@appscience/utils'
import {SelectItem} from '@mantine/core/lib/components/Select/types'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import IntlMessage from '../../../../../../components/util-components/IntlMessage'
import { setOrdersTableGlobalFilter } from '../../../../../../store/orders-table/reducer'
import { selectOrdersTableGlobalFilter, selectTableOrders } from '../../../../../../store/orders-table/selectors'
import { ORDER_STATUS_GROUP_TO_ORDER_STATUSES, Order, OrderStatusGroupEnum } from '../../../../../../utils/orders.util'
import { TableOrder } from '../OrdersErpTable.type'
import { mapOrderToTableOrder, mapToSelectItem } from '../OrdersErpTable.utils'
import { uniqBy } from 'ramda'

type OrderIdData = TitleWithId<string>
type BillIdData = TitleWithId<string> & {
  billId: string;
}
type ResponsiblePersonNameData = TitleWithId<string> & {
  responsiblePersonName: string;
}
type NameData = TitleWithId<string> & {
  name: string;
}

type OrdersTableGlobalFiltersData = {
  orderIds: Array<OrderIdData>;
  billIds: Array<BillIdData>;
  responsiblePersonNameIds: Array<ResponsiblePersonNameData>;
  nameIds: Array<NameData>;
}

function getUniqResponsiblePersonNames(tableOrders: TableOrder[]): Array<string> {
  const personNames: Array<string> = tableOrders
    .flatMap(tableOrder =>
      tableOrder?.responsiblePersons?.map(person => person?.name) ?? [],
    )

  return uniqBy(
    (personName: string) => personName,
    personNames,
  )
}

type FilterType = keyof OrdersTableGlobalFiltersData

function useTableData(statusGroup: OrderStatusGroupEnum): OrdersTableGlobalFiltersData {
  const orders: Array<Order> = useSelector(selectTableOrders)
  const tableOrders: TableOrder[] = orders
    .map(mapOrderToTableOrder)
    .filter(tableOrder => ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup].includes(tableOrder.status))

  return {
    orderIds: tableOrders.map(order => ({
      id: order.id,
      title: order.id,
    })),
    billIds: tableOrders.map(order => ({
      id: order.id,
      title: order.billId,
      billId: order.billId,
    })),
    responsiblePersonNameIds:
      getUniqResponsiblePersonNames(tableOrders)
        .map((personName: string) => ({
          id: personName,
          title: personName,
          responsiblePersonName: personName,
        })),
    nameIds: tableOrders.map(order => ({
      id: order.id,
      title: order.name,
      name: order.name,
    })),
  }
}

const enum FilterSizeEnum {
  Small = 'SMALL',
  Default = 'DEFAULT',
}

type OrdersTableGlobalFiltersSectionProps = {
  statusGroup: OrderStatusGroupEnum;
}

export function OrdersTableGlobalFiltersSection(props: OrdersTableGlobalFiltersSectionProps) {
  const {orderIds, billIds, responsiblePersonNameIds, nameIds} = useTableData(props.statusGroup)

  return (
    <div className='flex space-x-2 mr-1'>
      <Filter
        type='orderIds'
        data={orderIds.map(mapToSelectItem)}
        size={FilterSizeEnum.Small}
      />
      <Filter
        type='billIds'
        data={billIds.map(mapToSelectItem)}
        size={FilterSizeEnum.Small}
      />
      <Filter
        type='responsiblePersonNameIds'
        data={responsiblePersonNameIds.map(mapToSelectItem)}
      />
      <Filter
        type='nameIds'
        data={nameIds.map(mapToSelectItem)}
      />
    </div>
  )
}

interface GlobalMultiSelectProps {
  type: FilterType,
  data: Array<SelectItem>,
  size?: FilterSizeEnum,
}

function Filter({
  type,
  data,
  size = FilterSizeEnum.Default,
}: GlobalMultiSelectProps) {
  const dispatch = useDispatch()
  const selectedIds: Array<string> = useSelector(selectOrdersTableGlobalFilter(type))
  const resultData: Array<SelectItem> = [
    // { value: EMPTY_VALUE_ID, label: '(Empty value)' },
    ...data,
  ]

  return (
    <GlobalMultiSelect
      className={size === FilterSizeEnum.Default
        ? 'w-[300px]'
        : 'w-[150px]'
      }
      selected={selectedIds}
      data={resultData}
      onChange={ids => {
        dispatch(setOrdersTableGlobalFilter({ field: type, value: ids }))
      }}
      label={<label className='text-xs'>{getLabel(type)}</label>}
      withinPortal={false}
    />
  )
}

function getLabel(type: FilterType) {
  switch (type) {
    case 'orderIds':
      return <IntlMessage id='ordersTable.filters.id' />
    case 'billIds':
      return <IntlMessage id='ordersTable.filters.billId' />
    case 'responsiblePersonNameIds':
      return <IntlMessage id='ordersTable.filters.responsiblePersons' />
    case 'nameIds':
      return <IntlMessage id='ordersTable.filters.name' />
    default:
      checkNever(type)
      return ''
  }
}