import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { orderBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { selectAnalogs, selectProducts } from '../../store/search/selectors'
import { setAnalogsList, setProductsList } from '../../store/search/reducer'
import { ProductType } from '../../store/search/types'
import { setAddToBtnDisable, setProductsListAction } from '../../store/basket/reducer'
import { ProductBasketType } from '../../store/basket/types'
import service from '../../auth/FetchInterceptor'
import { SelectProps } from 'antd/es/select'
import axios from 'axios'
import { selectCurrentTheme } from '../../store/theme/selectors'
import { useIntl } from 'react-intl'
import { parseJSON } from '../../utils/json'
import {
  AnalogsSortingProp,
  SearchParamsType,
  SuggestItem,
  SuggestItemProduct,
  axiosSearchRequestData,
  axiosSuggestRequestData,
} from './search.type'
import { notificationCount, notificationSuccess } from './search.notifications'
import { isFilteredWord, repeatQueryParams } from './search.utils'
import CasId, { GetCasIdResponse } from '../../services/CasIdService'
import { SuggestDropdownItem } from './components/SuggestDropdownItem'
import BasketService from '../../services/BasketService'
import { AvailabilityService } from '../../services/AvailabilityService'
import { BASKET_KEY } from '../../constants/localStorage'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { selectSelectedPaymentDelay, selectSelectedPaymentType } from '../../store/payment-options/selectors'
import { PaymentDelay, PaymentType, getPaymentContract } from '../../services/PaymentOptionsService'
import SearchService from '../../services/SearchService'
import VendorService, { GetVendorsResponse } from '../../services/VendorService'
import { API_URL } from '../../constants/environment'

export const SEARCH_RESULT_PAGE_SIZE = 8
export const ANALOGS_PAGE_SIZE = 8

type SearchOptionsState = SelectProps<object>['options']

export const useSearchContentHook = () => {
  //Disaptch
  const dispatch = useDispatch()
  const theme = useSelector(selectCurrentTheme)
  const intl = useIntl()

  //Props
  const [searchProp, setSearchProp] = useState<string>('any')
  const [searchWord, _setSearchWord] = useState<string | number>('')
  const [vendor, setVendor] = useState<string>('')
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [filteredWord, _setFilteredWord] = useState<string>('')
  const [searched, setSearched] = useState<boolean>(false)
  const [searchResultPage, setSearchResultPage] = useState<number>(1)
  const [searchResultTotalProductsCount, setSearchResultTotalProductsCount] = useState<number>(0)
  const [visibleLoader, setVisibleLoader] = useState<boolean>(false)
  const [loadingSearchByWord, setLoadingSearchByWord] = useState<boolean>(true)
  const [searchOptions, setSearchOptions] = useState<SearchOptionsState>([])
  const [requestSearchWord, setRequestSearchWord] = useState<string>('')
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false)

  //Vendor
  const [isDefaultVendorsLoading, setDefaultVendorsLoading] = useState<boolean>(true)
  const [defaultVendors, setDefaultVendors] = useState<string[]>([])
  const [searchVendors, setSearchVendors] = useState<string[]>([])

  //Analogs Props
  const [analogsCasId, setAnalogsCasId] = useState<string>('')
  const [analogsLoading, setAnalogsLoading] = useState<boolean>(false)
  const [analogsPage, setPaginatioAnalogsCurrent] = useState<number>(1)
  const [totalAnalogsPagination, setTotalAnalogsPagination] = useState<number>(0)
  const [sortingProp, setSortingProp] = useState<AnalogsSortingProp>('')
  const [isReverseSorting, setIsReverseSorting] = useState<boolean>(false)

  const products = useSelector(selectProducts)
  const analogs: Array<ProductType> = useSelector(selectAnalogs)

  const selectedPaymentType: PaymentType | null = useSelector(selectSelectedPaymentType)
  const selectedPaymentDelay: PaymentDelay | null = useSelector(selectSelectedPaymentDelay)

  const setSearchWord = (value: string) => {
    if (!value.trim()) {
      return
    }
    _setSearchWord(value)
    setPaginatioAnalogsCurrent(1)
  }

  const setFilteredWord = useCallback((value: string) => {
    const trimString: string = `${value}`.trim().toUpperCase()
    const _length = analogs.filter(item => isFilteredWord(item, trimString)).length
    setTotalAnalogsPagination(_length)
    setPaginatioAnalogsCurrent(1)
    _setFilteredWord(value)
  }, [analogs])

  const onSearchAnalogsHandler = useCallback((
    cas_id?: string,
    id?: string,
  ) => {
    if (!cas_id || cas_id === 'n/a') {
      dispatch(setAnalogsList([]))
      return
    }

    setAnalogsLoading(true)

    CasId
      .getPartnerProductsByCasId({
        cas_id,
        payment_contract: getPaymentContract(
          selectedPaymentType,
          selectedPaymentDelay,
        ),
      })
      .then(({ partner_products: partnerProducts }: GetCasIdResponse) => {
        const otherPartnerProducts: Array<ProductType> =
          partnerProducts
            .filter((product: ProductType) => product.id !== id)
            ?.slice(0, SEARCH_RESULT_PAGE_SIZE) ?? [] // TODO backend error

        dispatch(setAnalogsList(otherPartnerProducts))
        setTotalAnalogsPagination(otherPartnerProducts.length)
      })
      .catch((error: Error) => {
        dispatch(setAnalogsList([]))
        notification.error({
          message: 'Ошибка при получении CasID',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setAnalogsLoading(false)
      })
  }, [dispatch, selectedPaymentDelay, selectedPaymentType])

  const onSearchHandler = useCallback((
    value?: string,
    paginationValue?: number,
    vendors: string[] = searchVendors,
  ) => {
    axiosSearchRequestData.source.cancel()

    setDropdownVisible(false)

    //Axios Cancel Func
    axiosSearchRequestData.cancelToken = axios.CancelToken
    axiosSearchRequestData.source = axiosSuggestRequestData.cancelToken.source()

    //Check Empty String
    if (!`${requestSearchWord}`.trim()) {
      return
    }

    //Revers sorting reset
    setSortingProp('')
    setIsReverseSorting(false)

    const searchParams: SearchParamsType = {}
    searchParams[searchProp as keyof typeof searchParams] = value ? value : requestSearchWord as string
    setSearchLoading(true)
    setFilteredWord('')
    setSearchResultPage(paginationValue ? paginationValue : 1)
    if (!paginationValue) {
      setPaginatioAnalogsCurrent(1)
      setTotalAnalogsPagination(0)
      dispatch(setAnalogsList([]))
    }

    SearchService
      .getPartnerProducts(
        {
          any: `${searchParams?.any ?? ''}`,
          count: SEARCH_RESULT_PAGE_SIZE,
          page: paginationValue ? paginationValue - 1 : 0,
          vendors: vendors.length ? vendors : defaultVendors,
          payment_contract: getPaymentContract(
            selectedPaymentType,
            selectedPaymentDelay,
          ),
        },
        axiosSearchRequestData.source.token,
      )
      .then(({ partner_products, items_count }) => {
        const partnerProducts: Array<ProductType> =
          partner_products
            ?.slice(0, SEARCH_RESULT_PAGE_SIZE) ?? [] // TODO backend error
        setSearchResultTotalProductsCount(items_count)
        dispatch(setProductsList(partnerProducts))

        const casId: string | undefined = partnerProducts?.[0]?.cas_id
        const id: string | undefined = partnerProducts?.[0]?.id

        setAnalogsCasId(casId ?? '')

        if (!paginationValue) {
          onSearchAnalogsHandler(casId, id)
        }
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных поисковой выдачи',
          description: error.message,
          duration: 5,
        })
        dispatch(setProductsList([]))
        dispatch(setAnalogsList([]))
        Sentry.captureException(error)
      })
      .finally(() => {
        setSearchLoading(false)
        setSearched(true)
      })
  }, [defaultVendors, dispatch, onSearchAnalogsHandler, requestSearchWord, searchProp, searchVendors, selectedPaymentDelay, selectedPaymentType, setFilteredWord])

  const searchPaginationHandler = (paginationValue: number) => {
    onSearchHandler(undefined, paginationValue)
  }

  const onSelectHandler = (value: string) => {
    setVendor(value)
  }

  const setObjectToLocalStorage = (object: ProductType) => {
    const data = localStorage.getItem(BASKET_KEY)
    if (data) {
      const dataArray = parseJSON<ProductBasketType[]>(data)
      if (dataArray === null) {
        return
      }
      let alreadyObjectInLocal = false
      dataArray.forEach((item, index) => {
        if (object.id === item.id) {
          dataArray[index].count++
          alreadyObjectInLocal = true
        }
      })

      if (alreadyObjectInLocal) {
        notificationCount(`${intl.messages['sidenav.my_orders.added_one_more_product']} ${object.name}`)
      }
      else {
        dataArray.push({ ...object, count: 1 })
        notificationSuccess(`${object.name} ${intl.messages['sidenav.my_orders.added_in_basket']}`)
      }
      localStorage.setItem(BASKET_KEY, JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]));
      (object.availability_type === 'ON-DEMAND') && availabilityRequestHandler(object)
    }
    else {
      const dataArray = []
      dataArray.push({ ...object, count: 1 })
      localStorage.setItem(BASKET_KEY, JSON.stringify(dataArray))
      dispatch(setProductsListAction([...dataArray]));
      (object.availability_type === 'ON-DEMAND') && availabilityRequestHandler(object)
    }
  }

  const availabilityRequestHandler = (product: ProductType) => {
    dispatch(setAddToBtnDisable(true))
    const { id } = product
    const storedBasketProducts: ProductBasketType[] = JSON.parse(localStorage.getItem(BASKET_KEY) ?? '[]')
    const { count = 0 } = storedBasketProducts.find(item => item.id === id) ?? {}

    AvailabilityService
      .post(id, count)
      .then(({ available, excel_comment }) => {
        const newBasketProducts: ProductBasketType[] =
          storedBasketProducts.map(basketProduct => {
            if (basketProduct.id !== id) {
              return basketProduct
            }
            return {
              ...basketProduct,
              availability_flag: available,
              excel_comment,
            }
          })

        localStorage.setItem(BASKET_KEY, JSON.stringify(newBasketProducts))
        dispatch(setProductsListAction([...newBasketProducts]))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных о доступности',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
  }

  const searchResultProducts: Array<ProductType> = useMemo(() => {
    if (!`${filteredWord}`.trim()) {
      return products
    }

    const trimString: string = `${filteredWord}`.trim().toUpperCase()
    return products
      .filter(item => isFilteredWord(item, trimString))
  }, [filteredWord, products])
  const totalSize = useMemo(() => searchResultProducts.length, [searchResultProducts])

  const sortingAnalogs = useMemo(() => {
    const result = [...analogs]

    if (sortingProp && sortingProp !== 'partnership_type') {
      const sortedProducts: Array<ProductType> = orderBy(
        result,
        [item => item[sortingProp].toLowerCase()],
        ['asc'],
      )

      return isReverseSorting
        ? sortedProducts.reverse()
        : sortedProducts
    }
    else if (sortingProp === 'partnership_type' && isReverseSorting) {
      const productsWithPartnershipType = result.filter(({ partnership_type }) => partnership_type)
      const sortedProductsWithPartnershipType = orderBy(
        productsWithPartnershipType,
        [({ partnership_type }) => partnership_type.toLowerCase()],
        ['asc'],
      )
      const productsWithoutPartnershipType = result.filter(({ partnership_type }) => !partnership_type)

      return [
        ...sortedProductsWithPartnershipType,
        ...productsWithoutPartnershipType,
      ].reverse()
    }

    return result
  }, [analogs, isReverseSorting, sortingProp])

  const analogsProducts: Array<ProductType> = useMemo(() => {
    const startIndex: number = (analogsPage - 1) * ANALOGS_PAGE_SIZE
    const endIndex: number = analogsPage * ANALOGS_PAGE_SIZE
    const filteredString = `${filteredWord}`.trim()

    if (!filteredString) {
      return sortingAnalogs.slice(startIndex, endIndex)
    }

    const trimString: string = filteredString.toUpperCase()
    return sortingAnalogs
      .filter(item => isFilteredWord(item, trimString))
      .slice(startIndex, endIndex)
  }, [filteredWord, analogsPage, sortingAnalogs])

  const addToBasketHandler = (item: ProductType) => {
    setObjectToLocalStorage(item)
  }

  const onChooseSearchLabel = (id: string) => {
    setDropdownVisible(false)
    setRequestSearchWord(id)
    onSearchHandler(id)
  }

  const addToBasketFromSearchResult = (
    e: React.MouseEvent<HTMLElement>,
    suggestItemProduct: SuggestItemProduct,
    basketProducts: ProductBasketType[],
  ) => {
    e.preventDefault()
    e.stopPropagation()

    const { id } = suggestItemProduct
    const hasProductInBasket = basketProducts
      .findIndex(product => product.id === id) !== -1

    if (hasProductInBasket) {
      const basketProduct = suggestItemProduct as unknown as ProductType
      addToBasketHandler(basketProduct)
      return
    }

    dispatch(setAddToBtnDisable(true))
    BasketService
      .getProductInfoById({
        id,
        payment_contract: getPaymentContract(
          selectedPaymentType,
          selectedPaymentDelay,
        ),
      })
      .then(({ partner_products }) => {
        addToBasketHandler(partner_products[0])
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных о товаре',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
  }

  const searchResult = (suggestItems: SuggestItem[]): SearchOptionsState => {
    setDropdownVisible(true)

    if (!suggestItems.length) {
      return [{
        value: '',
        label: <div>{intl.messages['sidenav.my_orders.search_not_found']}</div>,
      }]
    }

    return suggestItems
      .map((item, index) => ({
        value: item.data.id,
        label:
          <SuggestDropdownItem
            id={index}
            item={item}
            theme={theme}
            addToBasketFromSearchResult={addToBasketFromSearchResult}
          />,
      }))
  }

  const suggestRequestHandler = (value: string) => {
    axiosSuggestRequestData.source.cancel()

    if (!value.trim()) {
      setSearchOptions(searchResult([]))
      return
    }

    //Axios Cancel Func
    axiosSuggestRequestData.cancelToken = axios.CancelToken
    axiosSuggestRequestData.source = axiosSuggestRequestData.cancelToken.source()

    const classParams: string = repeatQueryParams(
      'class',
      searchVendors.length
        ? searchVendors
        : defaultVendors,
    )
    const formattedClassParams: string = classParams ? `&${classParams}` : ''

    // TODO move to service
    service
      .get(`${API_URL.SUGGEST}?count=100${formattedClassParams}`,
        {
          params: {
            part: value,
          },
          cancelToken: axiosSuggestRequestData.source.token,
        },
      )
      .then(data => {
        //@ts-ignore
        setSearchOptions(searchResult(data))
      })
  }

  const onSearchInput = (value: string) => {
    setRequestSearchWord(value)
    suggestRequestHandler(value)
  }

  const resetVendorsFilter = () => {
    setVendorsFilter(defaultVendors)
  }

  const setVendorsFilter = useCallback((vendors: string[]) => {
    setSearchVendors(vendors)
    onSearchHandler(undefined, undefined, vendors)
  }, [onSearchHandler])

  useEffect(() => {
    let isMounted = true
    setDefaultVendorsLoading(true)

    VendorService
      .getCustomerAreaVendors()
      .then(data => new Promise<GetVendorsResponse>(resolve => setTimeout(() => resolve(data), 1000))) // TODO remove
      .then(data => {
        const { vendors } = data
        if (isMounted) {
          setDefaultVendors(vendors)
          setVendorsFilter(vendors)
        }
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении списка вендоров',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setDefaultVendorsLoading(false)
      })

    return () => {
      isMounted = false
    }
  }, [])

  return {
    searchProp,
    searchWord,
    vendor,
    isSearchLoading,
    filteredWord,
    searched,
    searchResultPage,
    searchResultTotalProductsCount,
    products,
    totalSize,
    isDropdownVisible,
    visibleLoader,
    loadingSearchByWord,
    searchOptions,
    requestSearchWord,
    analogs,
    analogsCasId,
    analogsLoading,
    analogsPage,
    analogsProducts,
    totalAnalogsPagination,
    defaultVendors,
    searchVendors,
    isReverseSorting,
    sortingProp,
    searchResultProducts,
    isDefaultVendorsLoading,

    setSearchProp,
    _setSearchWord,
    setVendor,
    setSearchLoading,
    setFilteredWord,
    setSearched,
    setSearchResultPage,
    setSearchWord,
    onSearchHandler,
    onSelectHandler,
    setObjectToLocalStorage,
    addToBasketHandler,
    onSearchInput,
    setDropdownVisible,
    setVisibleLoader,
    setLoadingSearchByWord,
    onChooseSearchLabel,
    setRequestSearchWord,
    setPaginatioAnalogsCurrent,
    setDefaultVendors,
    setSearchVendors,
    setIsReverseSorting,
    setSortingProp,
    searchPaginationHandler,
    setVendorsFilter,
    resetVendorsFilter,
    setDefaultVendorsLoading,
  }
}
