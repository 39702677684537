import {notification} from 'antd'

interface ErrorInfo {
	errorObj?: unknown,
	toastMessage?: string,
	consoleMessage?: string,
}

export function defaultError({
  errorObj,
  toastMessage,
  consoleMessage,
}: ErrorInfo) {
  consoleMessage && console.error(consoleMessage)
  errorObj && console.error(errorObj)
  notification.error({
    message: toastMessage ||	'Something went wrong',
  })
}