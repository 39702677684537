export const titleStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
}

export const propsItemStyle = {
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: 13,
}
