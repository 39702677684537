// 1, 21, 31 - день
// 2-4, 22-24 - дня
// остальное - дней

import { IntlShape } from 'react-intl'
import { getUnixDatesDifferenceInDays } from '../../../../../../../utils/orders.util'

export function getFormattedDaysText(days: number, intl: IntlShape): string {
  if (!days) {
    return ''
  }

  const lastDigit = days % 10
  const preLastDigit = Math.floor(days / 10 % 10)

  if (lastDigit === 1 && preLastDigit !== 1) {
    return intl.messages['orderTable.deliveryToClient.daysText.1'] as string
  }

  if (lastDigit >= 2 && lastDigit <= 4 && preLastDigit !== 1) {
    return intl.messages['orderTable.deliveryToClient.daysText.2'] as string
  }

  return intl.messages['orderTable.deliveryToClient.daysText.3'] as string
}

export function getFormattedDaysDifferenceText(fromUnixDate: number, toUnixDate: number, intl: IntlShape): string {
  const difference = getUnixDatesDifferenceInDays(fromUnixDate, toUnixDate)

  return `${difference >= 0 ? '+' : ''}${difference} ${getFormattedDaysText(Math.abs(difference), intl)}`
}
