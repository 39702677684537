import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { Drawer, Menu } from 'antd'
import { useIntl } from 'react-intl'
import ThemeConfigurator from './Configurator'
import { DirectionEnum } from '../../configs/AppConfig'

type NavPanelProps = {
  direction: DirectionEnum;
}

export const NavPanel = ({ direction }: NavPanelProps) => {
  const [visible, setVisible] = useState(false)
  const drawerPlacement = useMemo(
    () =>
      (direction === DirectionEnum.RightToLeft
        ? 'left'
        : 'right')
    ,
    [direction],
  )

  const intl = useIntl()

  const showDrawer = useCallback(() => setVisible(true), [])
  const onClose = useCallback(() => setVisible(false), [])

  return (
    <>
      <Menu mode='horizontal'>
        <Menu.Item
          key='panel'
          onClick={showDrawer}
        >
          <span>
            <SettingOutlined className='nav-icon mr-0' />
          </span>
        </Menu.Item>
      </Menu>
      <Drawer
        title={intl.messages['sidenav.configuration.configuration']}
        placement={drawerPlacement}
        width={350}
        onClose={onClose}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  )
}

export default NavPanel
