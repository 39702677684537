import React, { useEffect } from 'react'
import { Provider, useSelector } from 'react-redux'
//import store from './redux/store';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Views from './views'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from './configs/AppConfig'
import { store } from './store/store'
import './App.css'
import { MantineProvider } from '@mantine/core'
import { ModalProvider } from '@appscience/hooks'
import { ExternalLayer } from '@appscience/layers'
import { ThemeColorEnum } from './utils/orders.util'
import { selectCurrentTheme } from './store/theme/selectors'
import * as Sentry from '@sentry/react'
import { SentryFallback } from './components/util-components/SentryFallback/SentryFallback'
import { injectStoreToAxios } from './auth/FetchInterceptor'

injectStoreToAxios(store)

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function ThemedApp() {
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme)

  return (
    <ThemeSwitcherProvider
      themeMap={themes}
      defaultTheme={THEME_CONFIG.currentTheme}
      insertionPoint='styles-insertion-point'
    >
      <Router>
        <MantineProvider
          withNormalizeCSS
          theme={{
            colorScheme: theme,
          }}
        >
          <ModalProvider layer={ExternalLayer}>
            <Switch>
              <Route
                path='/'
                component={Views}
              />
            </Switch>
          </ModalProvider>
        </MantineProvider>
      </Router>
    </ThemeSwitcherProvider>
  )
}

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    }
  }, [])

  return (
    <div className='App'>
      <Sentry.ErrorBoundary fallback={props => <SentryFallback {...props} />}>
        <Provider store={store}>
          <ThemedApp />
        </Provider>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App
