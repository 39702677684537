import React, { useCallback } from 'react'
import { Button, Col, Row } from 'antd'
import { Basket } from '../../../../content/basket'
import { OrderPersonalInfo } from '../../../../content/order-personal-info'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useOrderEdition } from './order-edition-view.hook'
import { SuccessOrder } from './order-edition-view.success-order'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const OrderEditionContent: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const {
    showSuccessOrder,
    orderId,
    email,

    setShowSuccessOrder,
  } = useOrderEdition()

  const onSubmitButtonClick = useCallback(() => {
    history.push('/new')
  }, [history])

  return (
    <Row>
      {showSuccessOrder && (
        <SuccessOrder
          email={email}
          id={orderId}
        />
      )}
      {!showSuccessOrder && (
        <>
          <Col
            span={24}
            className='pl-2'
          >
            <Button
              htmlType='submit'
              className='mb-3 mt-3'
              onClick={onSubmitButtonClick}
            >
              <ArrowLeftOutlined /> {intl.messages['sidenav.my_orders.add_products']}
            </Button>
          </Col>
          <Col
            span={24}
            xl={12}
            className='pr-2 pl-2 mt-3 basket-wrapper'
          >
            <Basket />
          </Col>
          <Col
            span={24}
            xl={12}
            className='pr-2 pl-2 mt-3'
          >
            <OrderPersonalInfo
              setShowSuccessOrder={setShowSuccessOrder}
            />
          </Col>
        </>
      )}
    </Row>
  )
}
