import { Col, Pagination, Row } from 'antd'
import { useSelector } from 'react-redux'
import { ProductCard } from '../../../../components/custom-components/product-card'
import { selectAddToCartBtnDisable } from '../../../../store/basket/selectors'
import { ProductType } from '../../../../store/search/types'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './SearchResult.module.css'

type SearchResultProps = {
  products: Array<ProductType>;
  isSearchLoading: boolean;
  totalProductsCount: number;
  pageSize: number;
  page: number;
  onAddToBasket: (product: ProductType) => void;
  onPaginationChange: (page: number) => void;
}

export function SearchResult({
  products,
  isSearchLoading,
  totalProductsCount,
  pageSize,
  page,
  onAddToBasket,
  onPaginationChange,
}: SearchResultProps) {
  const isDisabled = useSelector(selectAddToCartBtnDisable)

  return (
    <Row>
      <Col span={24}>
        {products.map((product, key) =>
          <ProductCard
            {...product}
            key={key}
            isDisableBtn={isDisabled}
            addToCart={() => onAddToBasket(product)}
          />,
        )}
        {isSearchLoading && (
          <div className={styles['loading']}>
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
            />
          </div>
        )}
      </Col>
      {totalProductsCount > pageSize && (
        <Col
          className={styles['pagination']}
          span={24}
        >
          <Pagination
            disabled={isSearchLoading}
            pageSize={pageSize}
            total={totalProductsCount}
            current={page}
            showSizeChanger={false}
            onChange={onPaginationChange}
          />
        </Col>
      )}
    </Row>
  )
}
