import { Badge, Container, Tooltip } from '@mantine/core'
import { TableOrderItem } from '../OrderErpTable.type'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { getUnixDatesDifferenceInDays, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { DeliveryToClientTooltipText } from './DeliveryToClientTooltipText/DeliveryToClientTooltipText'

export const DeliveryToClientDateCell = ({
  orderItem,
}: {
  orderItem: TableOrderItem;
}) => {
  const {
    expectedDeliveryDate: deadline,
    expectedDeliveryToClient: expectedDate,
    expectedDeliveryDateHistory: history,
  } = orderItem

  if (!deadline || !expectedDate) {
    return <>-</>
  }

  const isOnTime = expectedDate <= deadline
  const latestHistoryItem = history.length
    ? history[history.length - 1]
    : { from: 0, to: 0 }
  const latestDateChange = getUnixDatesDifferenceInDays(latestHistoryItem.from, latestHistoryItem.to)

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Tooltip
        style={{
          width: '100%',
        }}
        label={(
          <DeliveryToClientTooltipText
            orderItem={orderItem}
          />
        )}
      >
        <Badge
          size='lg'
          color={isOnTime ? 'green' : 'red'}
          style={{
            width: '100%',
            fontWeight: 500,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {unixTimestampToFormattedDate(expectedDate)}
            {latestDateChange !== 0 && (
              <Container
                ml={4}
                px={2}
                style={{
                  lineHeight: '14px',
                  height: 16,
                  border: '1px solid',
                  borderRadius: 10,
                }}
                sx={theme => ({
                  borderColor: isOnTime ? theme.colors.green[3] : theme.colors.red[3],
                })}
              >
                {latestDateChange > 0 ? `+${latestDateChange}` : latestDateChange}
              </Container>
            )}
          </div>
        </Badge>
      </Tooltip>
    </div>
  )
}
