import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'
import { OrdersTableGlobalFiltersData } from './reducer'

const selectOrdersTable = (state: RootState) => state.ordersTable

export const selectTableOrders = createSelector(selectOrdersTable, state => state.orders)

export const selectOrdersTableGlobalFilter = <T extends keyof OrdersTableGlobalFiltersData>(field: T) => createSelector(
  selectOrdersTable, state => state.globalFilters[field],
)

export const selectOrdersTableGlobalFilters = () => createSelector(
  selectOrdersTable, state => state.globalFilters,
)

export const selectOrdersFilterByUser = createSelector(selectOrdersTable, state => state.ordersFilterByUser)

export const selectIsOrdersFilterByAllDisabled = createSelector(selectOrdersTable, state => state.isOrdersFilterByAllDisabled)
