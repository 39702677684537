import { Button, Modal, Skeleton, Text } from '@mantine/core'
import { Timeline, notification } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrderService } from '../../../../../services'
import { GetOrderItemTimelineResponse, OrderItemTimelineStatusType, SortingOrder } from '../../../../../services/OrderService'
import { OrderItemTimelineModalState, setOrderItemTimelineModalState } from '../../../../../store/order-items-table/reducer'
import { selectOrderItemTimelineModalState } from '../../../../../store/order-items-table/selectors'
import * as Sentry from '@sentry/react'
import { LocaleEnum, OrderItemTimelineStatus, mapOrderItemTimelineResponseToOrderItemTimeline, unixTimestampToFormattedDate } from '../../../../../utils/orders.util'
import { selectLocale } from '../../../../../store/theme/selectors'
import { CheckCircleOutlined, ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

type TimelineStatusIconProps = {
  statusType: OrderItemTimelineStatusType;
}

function TimelineStatusIcon({
  statusType,
}: TimelineStatusIconProps) {
  switch (statusType) {
    case OrderItemTimelineStatusType.History:
      return <CheckCircleOutlined style={{ color: 'gray' }} />
    case OrderItemTimelineStatusType.Current:
      return <CheckCircleOutlined style={{ color: 'green' }} />
    case OrderItemTimelineStatusType.Forecast:
      return <ClockCircleOutlined />
    default:
      return <QuestionCircleOutlined />
  }
}

function OrderItemTimelineModalTitle() {
  const intl = useIntl()

  return (
    <Text
      size='lg'
    >
      {intl.messages['orderItemTimelineModal.title']}
    </Text>
  )
}

export function OrderItemTimelineModal() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const locale: LocaleEnum = useSelector(selectLocale)
  const isEnLocale = locale === LocaleEnum.En
  const { isOpen, itemId }: OrderItemTimelineModalState = useSelector(selectOrderItemTimelineModalState)
  const [isLoading, setLoading] = useState(true)
  const [timelineStatuses, setTimelineStatuses] = useState<Array<OrderItemTimelineStatus>>([])

  const closeModal = useCallback(() => {
    dispatch(setOrderItemTimelineModalState({
      isOpen: false,
      itemId: null,
    }))
  }, [dispatch])

  useEffect(() => {
    if (!isOpen || !itemId) {
      return
    }

    setLoading(true)

    OrderService
      .getOrderItemTimeline(
        itemId,
        SortingOrder.Ascending,
      )
      .then((response: GetOrderItemTimelineResponse) => {
        const { statuses } = mapOrderItemTimelineResponseToOrderItemTimeline(response)

        setTimelineStatuses(statuses)
      })
      .catch((error: Error) => {
        notification.error({
          message: intl.messages['orderItemTimelineModal.timelineResponseError'],
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [isOpen, itemId, intl.messages])

  return (
    <Modal
      centered
      opened={isOpen}
      onClose={closeModal}
      title={<OrderItemTimelineModalTitle />}
      zIndex={1000}
      size='md'
    >
      {isLoading && (
        <>
          {Array(10).fill(null).map((_, index) => (
            <>
              <Skeleton
                key={index}
                height={20}
                radius='sm'
                mt={20}
              />
            </>
          ))}
          <Skeleton
            height={40}
            width={100}
            radius='sm'
            mt={20}
          />
        </>
      )}
      {!isLoading && (
        <>
          {!timelineStatuses.length && (
            <Text
              size='sm'
              mb={20}
            >
              {intl.messages['orderItemTimelineModal.emptyTimelineMessage']}
            </Text>
          )}
          {timelineStatuses.length > 0 && (
            <Timeline
              mode='left'
              className='mt-4'
              style={{
                marginBottom: -30,
                marginLeft: -190,
              }}
            >
              {timelineStatuses.map(({
                timestamp,
                statusRu,
                statusEn,
                statusType,
              }, index) => (
                <Timeline.Item
                  key={index}
                  label={unixTimestampToFormattedDate(timestamp)}
                  dot={(
                    <TimelineStatusIcon
                      statusType={statusType}
                    />
                  )}
                >
                  {isEnLocale && statusEn}
                  {!isEnLocale && statusRu}
                </Timeline.Item>
              ))}
            </Timeline>
          )}
          <Button
            onClick={closeModal}
          >
            {intl.messages['orderItemTimelineModal.ok']}
          </Button>
        </>
      )}
    </Modal>
  )
}
