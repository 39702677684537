import { SegmentedControl } from '@mantine/core'
import { Badge, Radio, RadioChangeEvent } from 'antd'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PageHeaderAlt from '../../../../../components/layout-components/PageHeaderAlt'
import Flex from '../../../../../components/shared-components/Flex'
import IntlMessage from '../../../../../components/util-components/IntlMessage'
import { OrdersFilterByUserEnum, setOrdersFilterByUser } from '../../../../../store/orders-table/reducer'
import { selectIsOrdersFilterByAllDisabled, selectOrdersFilterByUser } from '../../../../../store/orders-table/selectors'
import { ORDER_STATUS_GROUP_TO_ORDER_STATUSES, OrderStatusGroupEnum } from '../../../../../utils/orders.util'

type OrdersViewHeaderProps = {
  statusGroup: OrderStatusGroupEnum;
  isLoading: boolean;
  isNotLinked: boolean;
  statusFilter: string;
  itemsCountByStatus: Record<string, number>;
  areFiltersVisible: boolean;
  isUserFilterVisible: boolean;
  setStatusFilter: (value: string) => void;
}

export function OrdersViewHeader(props: OrdersViewHeaderProps) {
  const {
    statusGroup,
    isLoading,
    isNotLinked,
    statusFilter,
    itemsCountByStatus,
    areFiltersVisible,
    isUserFilterVisible,
    setStatusFilter,
  } = props

  const intl = useIntl()
  const ordersFilterByUser: OrdersFilterByUserEnum = useSelector(selectOrdersFilterByUser)
  const isOrdersFilterByAllDisabled: boolean = useSelector(selectIsOrdersFilterByAllDisabled)
  const dispatch = useDispatch()
  const changeOrdersFilterByUser = useCallback((value: OrdersFilterByUserEnum) => {
    dispatch(setOrdersFilterByUser(value))
  }, [dispatch])

  return (
    <PageHeaderAlt className='border-bottom'>
      <div className='container-fluid'>
        <Flex
          justifyContent='between'
          alignItems='center'
        >
          <h2 style={{marginBottom: 0}}>
            <IntlMessage id={'sidenav.my_orders.' + statusGroup} />
          </h2>
          {areFiltersVisible && !isNotLinked && !isLoading && (
            <div>
              <Radio.Group
                className='d-none d-md-block'
                value={statusFilter}
                size='small'
                onChange={(e: RadioChangeEvent) => setStatusFilter(e.target.value)}
              >
                <Radio.Button value='all'>
                  <IntlMessage id={'orders.status.all.' + statusGroup}/>
                </Radio.Button>
                {ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup] && ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup].map((status, index) =>
                  <Radio.Button
                    key={index}
                    value={status}
                    disabled={!itemsCountByStatus[status]}
                  >
                    <IntlMessage id={'orders.status.' + status}/>
                    &nbsp;
                    <Badge
                      count={itemsCountByStatus[status]}
                      style={{backgroundColor: '#0997d9'}}
                    />
                  </Radio.Button>,
                )}
              </Radio.Group>
            </div>
          )}
          {isUserFilterVisible && (
            <SegmentedControl
              value={ordersFilterByUser}
              onChange={changeOrdersFilterByUser}
              data={[
                {
                  label: intl.messages['ordersTable.filters.byUser.all'],
                  value: OrdersFilterByUserEnum.All,
                  disabled: isOrdersFilterByAllDisabled,
                },
                {
                  label: intl.messages['ordersTable.filters.byUser.user'],
                  value: OrdersFilterByUserEnum.User,
                },
              ]}
              disabled={isNotLinked || isLoading}
            />
          )}
        </Flex>
      </div>
    </PageHeaderAlt>
  )
}
