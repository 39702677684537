export function serializeDate(date: Date | null): string | null {
  if (!date) {
    return null
  }

  return date.toDateString()
}

export function deserializeDate(dateString: string | null): Date | null {
  if (!dateString) {
    return null
  }

  return new Date(dateString)
}
