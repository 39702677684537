import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getLocalStorageCallbacks } from '../../hooks/useLocalStorage/useLocalStorage'
import { Order } from '../../utils/orders.util'
import { ActionType } from '../types'

export interface PartialUpdateAction<O extends object, F extends keyof O> {
  field: F,
  value: O[F],
}

export type OrdersTableGlobalFiltersData = {
  orderIds: Array<string>;
  billIds: Array<string>;
  responsiblePersonNameIds: Array<string>;
  nameIds: Array<string>;
}

export const enum OrdersFilterByUserEnum {
  All = 'ALL',
  User = 'USER',
}

type OrdersTableState = {
  orders: Array<Order>;
  globalFilters: OrdersTableGlobalFiltersData;
  ordersFilterByUser: OrdersFilterByUserEnum;
  isOrdersFilterByAllDisabled: boolean;
}

type SetOrdersAction = ActionType<Array<Order>>
type SetOrdersFilterByUserAction = ActionType<OrdersFilterByUserEnum>

const {
  getStorageObject,
  setValueToStorage,
} = getLocalStorageCallbacks('ordersStage')

function getInitialState(): OrdersTableState {
  const storage = getStorageObject()

  return {
    orders: [],
    globalFilters: storage?.globalFilters || {
      orderIds: [],
      billIds: [],
      responsiblePersonNameIds: [],
      nameIds: [],
    },
    ordersFilterByUser: storage?.ordersFilterByUser ?? OrdersFilterByUserEnum.All,
    isOrdersFilterByAllDisabled: false,
  }
}

const ordersTableSlice = createSlice({
  name: 'orders-table',
  initialState: {...getInitialState()},
  reducers: {
    setTableOrders(state, action: SetOrdersAction) {
      state.orders = action.payload
    },
    setOrdersTableGlobalFilter<T extends keyof OrdersTableGlobalFiltersData>(
      state: OrdersTableState,
      { payload }: PayloadAction<PartialUpdateAction<OrdersTableGlobalFiltersData, T>>,
    ) {
      state.globalFilters[payload.field] = payload.value
      setValueToStorage('globalFilters', state.globalFilters)
    },
    setOrdersFilterByUser(state, action: SetOrdersFilterByUserAction) {
      state.ordersFilterByUser = action.payload
      setValueToStorage('ordersFilterByUser', state.ordersFilterByUser)
    },
    resetOrdersTableGlobalFilters(state) {
      state.globalFilters = {
        billIds: [],
        nameIds: [],
        orderIds: [],
        responsiblePersonNameIds: [],
      }
      setValueToStorage('globalFilters', state.globalFilters)
    },
    resetPaginationIndex() {
      setValueToStorage('paginationPage', 0)
    },
    disableOrderFilterByAll(state) {
      state.isOrdersFilterByAllDisabled = true
      state.ordersFilterByUser = OrdersFilterByUserEnum.User
      setValueToStorage('ordersFilterByUser', state.ordersFilterByUser)
    },
    enableOrderFilterByAll(state) {
      state.isOrdersFilterByAllDisabled = false
    },
  },
})

export const ordersTableReducer = ordersTableSlice.reducer
export const {
  setTableOrders,
  setOrdersTableGlobalFilter,
  setOrdersFilterByUser,
  resetOrdersTableGlobalFilters,
  resetPaginationIndex,
  disableOrderFilterByAll,
  enableOrderFilterByAll,
} = ordersTableSlice.actions
