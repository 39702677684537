import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'

const initialState = {
  pdfLink: '',
  docLink: '',
  linkToTable: '',
  orderValue: '',
  disabledOrderForm: false,
}

const generateSlice = createSlice({
  name: 'generate',
  initialState: {...initialState},
  reducers: {
    setPdfLinkAction(state, action: ActionType<string>) {
      state.pdfLink = action.payload
    },
    setDocLinkAction(state, action: ActionType<string>) {
      state.docLink = action.payload
    },
    setLinkToTableAction(state, action: ActionType<string>) {
      state.linkToTable = action.payload
    },
    setOrderValueAction(state, action: ActionType<string>) {
      state.orderValue = action.payload
    },
    setDisabledOrderFormAction(state, action: ActionType<boolean>) {
      state.disabledOrderForm = action.payload
    },
  },
})

export const generateReducer = generateSlice.reducer
export const {
  setPdfLinkAction,
  setDocLinkAction,
  setOrderValueAction,
  setLinkToTableAction,
  setDisabledOrderFormAction,
} = generateSlice.actions
