import { isNumber } from '@appscience/utils'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage/useLocalStorage'
import { resetPaginationIndex } from '../../../../../../store/orders-table/reducer'
import { TableOrder } from '../OrdersErpTable.type'

export type OrdersErpTableInitialData = {
  paginationPage: number;
  paginationSize: number;
}

export function useOrdersErpTableInitialData(tableOrders: Array<TableOrder>): OrdersErpTableInitialData {
  const {getStorageValue} = useLocalStorage('ordersStage')

  const initialData = useMemo(() => {
    const rawPaginationPage = getStorageValue('paginationPage')
    const rawPaginationSize = getStorageValue('paginationSize')

    const paginationPage = rawPaginationPage && isNumber(rawPaginationPage) ? Number(rawPaginationPage) : 0
    const paginationSize = rawPaginationSize && isNumber(rawPaginationSize) ? Number(rawPaginationSize) : 10

    const maxPaginationPage: number = Math.floor((tableOrders.length - 1) / paginationSize)

    return {
      paginationPage: maxPaginationPage < paginationPage ? 0 : paginationPage,
      paginationSize,
    }
  }, [getStorageValue, tableOrders.length])

  // Set paginationIndex to 0 for fixing bug with invalid exceeding paginationIndex when changing statusType
  const dispatch = useDispatch()
  useEffect(() => {
    if (!initialData.paginationPage) {
      dispatch(resetPaginationIndex())
    }
  }, [dispatch, initialData.paginationPage])

  return initialData
}
