import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'
import { ProductType } from '../store/search/types'
import { PaymentContract } from './PaymentOptionsService'

export type GetCasIdRequest = {
  cas_id: string;
  payment_contract: PaymentContract;
}

export type GetCasIdResponse = {
  items_count: number;
  pages_count: number;
  partner_products: Array<ProductType>;
}

type CasIdServiceType = {
  getPartnerProductsByCasId: (request: GetCasIdRequest) => Promise<GetCasIdResponse>;
}

const CasIdService: CasIdServiceType = {
  getPartnerProductsByCasId: (request: GetCasIdRequest) =>
    axiosInstance.post<never, GetCasIdResponse, GetCasIdRequest>(
      `${API_URL.PARTNER_PRODUCTS}/customers-request`,
      request,
    ),
}

export default CasIdService
