import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Loading from '../../components/shared-components/Loading'
import {AUTH_PREFIX_PATH} from '../../configs/AppConfig'

export const AppViews = () => (
  <Suspense fallback={<Loading cover='page'/>}>
    <Switch>
      <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
      <Route path={`${AUTH_PREFIX_PATH}/signup`} component={lazy(() => import(`./authentication/signup`))} />
      <Route path={`${AUTH_PREFIX_PATH}/confirm`} component={lazy(() => import(`./authentication/confirm`))} />
      <Route path={`${AUTH_PREFIX_PATH}/forgot`} component={lazy(() => import(`./authentication/forgot`))} />
      <Route path={`${AUTH_PREFIX_PATH}/reset`} component={lazy(() => import(`./authentication/reset`))} />
      <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
    </Switch>
  </Suspense>
)

export default AppViews

