import {createSlice} from '@reduxjs/toolkit'
import {ActionType} from '../types'
import {BasketState, DeliveryCityCost, ExchangeRates, PreorderItemType, ProductBasketType} from './types'
import {DELIVERY_CITY_KEY} from '../../constants/localStorage'
import { CurrencyEnum } from '../../constants/currency'

const DEFAULT_DELIVERY_CITY = 'Москва'
const initialDeliveryCity: string = localStorage.getItem(DELIVERY_CITY_KEY) || DEFAULT_DELIVERY_CITY

const initialState: BasketState = {
  addToCartBtnDisable: false,
  basketProducts: [],
  editedItem: undefined,
  openHandleEditor: false,
  totalSummary: 0,
  preorderProductArray: [],
  contactName: '',
  phone: '',
  email: '',
  comment: '',
  orderName: '',
  defaultOrderName: '',
  deliveryCity: initialDeliveryCity,
  deliveryCityCosts: [],
  exchangeRates: {
    [CurrencyEnum.Rub]: {
      amount: 1,
      lastUpdateTimestamp: 0,
    },
    [CurrencyEnum.Usd]: {
      amount: 1,
      lastUpdateTimestamp: 0,
    },
  },
}

const basketSlice = createSlice({
  name: 'search',
  initialState: {...initialState},
  reducers: {
    setInitialStateAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = action.payload
    },
    setProductsListAction(state, action: ActionType<ProductBasketType[]>) {
      state.basketProducts = [...action.payload]
    },
    setEditedItemIndex(state, action: ActionType<number>) {
      state.editedItem = action.payload
    },
    setOpenHandleEditor(state, action: ActionType<boolean>) {
      state.openHandleEditor = action.payload
    },
    setContactName(state, action: ActionType<string>) {
      state.contactName = action.payload
    },
    setPhone(state, action: ActionType<string>) {
      state.phone = action.payload
    },
    setEmail(state, action: ActionType<string>) {
      state.email = action.payload
    },
    setComment(state, action: ActionType<string>) {
      state.comment = action.payload
    },
    setTotalSummary(state, action: ActionType<string | number>) {
      state.totalSummary = action.payload
    },
    setPeorderProductArray(state, action: ActionType<PreorderItemType[]>) {
      state.preorderProductArray = action.payload
    },
    setAddToBtnDisable(state, action: ActionType<boolean>) {
      state.addToCartBtnDisable = action.payload
    },
    setOrderName(state, action: ActionType<string>) {
      state.orderName = action.payload
    },
    setDefaultOrderName(state, action: ActionType<string>) {
      state.defaultOrderName = action.payload
    },
    setDeliveryCity(state, action: ActionType<string>) {
      const deliveryCity: string = action.payload || ''
      state.deliveryCity = deliveryCity
      localStorage.setItem(DELIVERY_CITY_KEY, deliveryCity)
    },
    setDeliveryCityCosts(state, action: ActionType<DeliveryCityCost[]>) {
      state.deliveryCityCosts = action.payload
    },
    setExchangeRates(state, action: ActionType<ExchangeRates>) {
      state.exchangeRates = action.payload
    },
  },
})

export const basketReducer = basketSlice.reducer
export const {
  setInitialStateAction,
  setProductsListAction,
  setEditedItemIndex,
  setOpenHandleEditor,
  setContactName,
  setComment,
  setEmail,
  setPhone,
  setPeorderProductArray,
  setAddToBtnDisable,
  setOrderName,
  setDefaultOrderName,
  setDeliveryCity,
  setDeliveryCityCosts,
  setExchangeRates,
} = basketSlice.actions
