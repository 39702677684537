declare global {
  interface YandexMetrikaHitOptions {
    params: Record<string, unknown>;
  }

  interface YandexMetrikaParamsOptions {
    params: Record<string, unknown>;
  }

  interface Window {
    // see https://yandex.ru/support/metrica/objects/set-user-id.html
    ym(metricId: number, eventName: 'setUserID', metricaUserId: string): void;
    // see https://yandex.ru/support/metrica/objects/params-method.html
    ym(metricId: number, eventName: 'params', options: YandexMetrikaParamsOptions): void;
    // see https://yandex.ru/support/metrica/objects/hit.html
    ym(metricId: number, eventName: 'hit', url: string, options?: YandexMetrikaHitOptions): void;
  }
}

export const YANDEX_METRIC_ID = 89455156

export function yandexMetricSetUserId(metricaUserId: string) {
  window.ym(
    YANDEX_METRIC_ID,
    'setUserID',
    metricaUserId,
  )
}

export function yandexMetricParams(options: YandexMetrikaParamsOptions) {
  window.ym(
    YANDEX_METRIC_ID,
    'params',
    options,
  )
}

export function yandexMetricHit(options: YandexMetrikaHitOptions) {
  window.ym(
    YANDEX_METRIC_ID,
    'hit',
    window.location.href,
    options,
  )
}
