import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'
import { OrderItemsTableGlobalFiltersData } from './reducer'

const selectOrderItemsTable = (state: RootState) => state.orderItemsTable

export const selectTableOrderItems = createSelector(selectOrderItemsTable, state => state.orderItems)

export const selectOrderItemsTableGlobalFilter = <T extends keyof OrderItemsTableGlobalFiltersData>(field: T) => createSelector(
  selectOrderItemsTable, state => state.globalFilters[field],
)

export const selectOrderItemsTableGlobalFilters = () => createSelector(
  selectOrderItemsTable, state => state.globalFilters,
)

export const selectOrderItemTimelineModalState = createSelector(
  selectOrderItemsTable,
  state => state.orderItemTimelineModalState,
)
