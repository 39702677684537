import { Badge, Tooltip } from '@mantine/core'
import { OrderDeliveryStatusEnum, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { TableOrder } from '../OrdersErpTable.type'
import { useIntl } from 'react-intl'

type Props = {
  order: TableOrder;
}

export function DeliveryDeadlineCell({ order }: Props) {
  const intl = useIntl()

  const deadlineDate =
    unixTimestampToFormattedDate(order.maxSupplyDeadlineDate) || '-'
  const { delayStatus } = order
  const hasNoData = delayStatus === OrderDeliveryStatusEnum.NoData
  const isLate = delayStatus === OrderDeliveryStatusEnum.IsLate

  const tooltipText =
    !hasNoData && isLate
      ? intl.messages['orders.delivery.late']
      : intl.messages['orders.delivery.on_time']

  if (hasNoData) {
    return <>{deadlineDate}</>
  }

  return (
    <Tooltip label={tooltipText}>
      <Badge
        size='lg'
        color={isLate ? 'red' : 'green'}
        style={{
          fontWeight: 500,
        }}
      >
        {deadlineDate}
      </Badge>
    </Tooltip>
  )
}
