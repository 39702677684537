import React from 'react'
import {Drawer} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {
  selectBasketProducts,
  selectEditedItem,
  selectOpenHandleEditor,
} from '../../store/basket/selectors'
import {HandleInputForm} from '../../components/custom-components/handle-input-form'
import {
  setOpenHandleEditor,
  setProductsListAction,
} from '../../store/basket/reducer'
import {useIntl} from 'react-intl'
import { BASKET_KEY } from '../../constants/localStorage'

export const SideHandleEditorContent: React.FC = () => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const editedItemIndex = useSelector(selectEditedItem)
  const basketProducts = useSelector(selectBasketProducts)
  const openHandleEditor = useSelector(selectOpenHandleEditor)
  const editedItem = editedItemIndex ? basketProducts[editedItemIndex] : basketProducts[0]
  const onClose = () => {
    dispatch(setOpenHandleEditor(false))
  }

  const onChangeHandler = (name: string, value: string) => {
    if (editedItemIndex === undefined) {
      return
    }
    const item = {...basketProducts[editedItemIndex]}

    //@ts-ignore
    item[name] = value
    const resultArray = [...basketProducts]
    resultArray[editedItemIndex] = {...item}
    dispatch(setProductsListAction(resultArray))
    localStorage.setItem(BASKET_KEY, JSON.stringify(resultArray))
  }

  return (
    <>
      <Drawer
        title={intl.messages['sidenav.my_orders.product_info_edition']}
        placement='right'
        className='side-handle-editor-content'
        destroyOnClose={true}
        onClose={onClose} visible={openHandleEditor}>
        <div className='side-handle-editor-content'>
          <HandleInputForm
            //@ts-ignore
            initialValues={editedItem}
            onChangeHandler={onChangeHandler}
            showFinishBtn={false}/>
        </div>
      </Drawer>
    </>
  )
}
