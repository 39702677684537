import { defaultError } from './errors'

export function parseJSON<EXPECTED = undefined>(json: string): EXPECTED | null {
  try {
    return JSON.parse(json) as EXPECTED
  }
  catch (e) {
    defaultError({
      errorObj: e,
    })
    return null
  }
}