import { isNumber } from '@appscience/utils'
import { useMemo } from 'react'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage/useLocalStorage'

export function useIdnTableInitialData() {
  const { getStorageValue } = useLocalStorage('idnTable')

  return useMemo(() => {
    const rawPaginationPage = getStorageValue('paginationPage')
    const rawPaginationSize = getStorageValue('paginationSize')

    return {
      paginationPage: rawPaginationPage && isNumber(rawPaginationPage) ? Number(rawPaginationPage) : 0,
      paginationSize: rawPaginationSize && isNumber(rawPaginationSize) ? Number(rawPaginationSize) : 10,
    }
  }, [getStorageValue])
}
