import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'
import { PartialProductItem } from '../store/basket/types'

// TODO api typings
type GenerateOrderNameResponse = {
  name: string;
}

type ProposalServiceType = {
  post: (proposalhData: AnyType) => Promise<AnyType>;
  getProposalData: (proposalRequestData: AnyType) => Promise<AnyType>;
  generateOrderName: (partialProductItems: PartialProductItem[]) => Promise<GenerateOrderNameResponse>;
};

const ProposalService: ProposalServiceType = {
  post: proposalhData =>
    axiosInstance({
      url: `${API_URL.PROPOSALS}/proposal`,
      method: 'post',
      data: proposalhData,
    }),
  getProposalData: proposalRequestData =>
    axiosInstance({
      url: `${API_URL.PROPOSALS}/get`,
      method: 'get',
      data: proposalRequestData,
    }),
  generateOrderName: partialProductItems =>
    axiosInstance.post<never, GenerateOrderNameResponse>(
      `${API_URL.PROPOSALS}/generate-order-name`,
      {
        items: partialProductItems,
      },
    ),
}

export default ProposalService
