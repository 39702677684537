import { DataTableSearchFn } from '@appscience/data-table'
import { caseInsensitiveSubstr, isNullable } from '@appscience/utils'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { OrdersTableGlobalFiltersData } from '../../../../../../store/orders-table/reducer'
import { selectOrdersTableGlobalFilters } from '../../../../../../store/orders-table/selectors'
import { OrderResponsiblePerson, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { TableOrder } from '../OrdersErpTable.type'

export function useGlobalFilteredTableOrders(tableOrders: Array<TableOrder>): Array<TableOrder> {
  const {
    orderIds = [],
    billIds = [],
    responsiblePersonNameIds = [],
    nameIds = [],
  }: OrdersTableGlobalFiltersData = useSelector(selectOrdersTableGlobalFilters())

  return useMemo(() => {
    const orderIdsSet = orderIds.length > 0
      ? new Set(orderIds)
      : null
    const billIdsSet = billIds.length > 0
      ? new Set(billIds)
      : null
    const responsiblePersonNameIdsSet = responsiblePersonNameIds.length > 0
      ? new Set(responsiblePersonNameIds)
      : null
    const nameIdsSet = nameIds.length > 0
      ? new Set(nameIds)
      : null

    if ([orderIdsSet, billIdsSet, responsiblePersonNameIdsSet, nameIdsSet].every(isNullable)) {
      return tableOrders
    }

    return tableOrders.filter(({ id, responsiblePersons }) =>
      isInFilter(id, orderIdsSet)
      && isInFilter(id, billIdsSet)
      && isInFilter(id, nameIdsSet)
      && isResponsiblePersonNameInFilter(responsiblePersons, responsiblePersonNameIdsSet),
    )
  }, [tableOrders, orderIds, billIds, responsiblePersonNameIds, nameIds])
}

function isResponsiblePersonNameInFilter(
  responsiblePersons: OrderResponsiblePerson[],
  filteredIdsSet: Set<string> | null,
): boolean {
  if (filteredIdsSet === null) {
    return true
  }

  const personNames: Array<string> = responsiblePersons.map(({ name }) => name)
  return personNames.some(personName => filteredIdsSet.has(personName))
}

function isInFilter(id: string, filteredIdsSet: Set<string> | null): boolean {
  if (filteredIdsSet === null) {
    return true
  }

  return filteredIdsSet.has(id)
}

export function useOrdersTableSearchFn(): DataTableSearchFn<TableOrder> {
  const intl = useIntl()

  return useCallback((order: TableOrder, search: string) =>
    caseInsensitiveSubstr(order.id, search)
    || caseInsensitiveSubstr(order.billId, search)
    || caseInsensitiveSubstr(intl.messages[`orders.status.${order.status}`] as string, search)
    || (order?.responsiblePersons || [])
      .some(({name}) => caseInsensitiveSubstr(name, search))
    || caseInsensitiveSubstr(order.name, search)
    || caseInsensitiveSubstr(`${order.paymentValue}`, search)
    || caseInsensitiveSubstr(unixTimestampToFormattedDate(order.supplyDate), search)
  , [intl.messages])
}
