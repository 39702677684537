import { Col, Input, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../../../store/theme/selectors'
import { getSearchResultCountMessage } from '../../search.utils'

type SearchResultFilterProps = {
  totalSize: number;
  searchResultTotalProductsCount: number;
  filteredWord: string;
  setFilteredWord: (value: string) => void;
  setPage: (value: number) => void;
}

export function SearchResultFilter({
  totalSize,
  searchResultTotalProductsCount,
  filteredWord,
  setFilteredWord,
  setPage,
}: SearchResultFilterProps) {
  const intl = useIntl()
  const locale = useSelector(selectLocale)

  const searchResultCountMessage: string = useMemo(() => {
    if (totalSize < 1) {
      return intl.messages['sidenav.my_orders.search_not_found'] as string
    }

    return getSearchResultCountMessage(
      searchResultTotalProductsCount,
      locale,
    )
  }, [intl.messages, searchResultTotalProductsCount, locale, totalSize])

  const onFilterChange = useCallback((value: string) => {
    setFilteredWord(value)
    setPage(1)
  }, [setFilteredWord, setPage])

  return (
    <Row align='middle'>
      <Col span={6}>
        {searchResultCountMessage}
      </Col>
      <Col span={18}>
        <Input
          placeholder={intl.messages['sidenav.my_orders.filter_by_any_field'] as string}
          value={filteredWord}
          prefix={<SearchOutlined />}
          onChange={e => onFilterChange(e.target.value)}
        />
      </Col>
    </Row>
  )
}
