import { isNumber } from '@appscience/utils'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage/useLocalStorage'
import { useQuery } from '../../../../../../hooks/useQuery'

export type PaginationData = {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}

const DEFAULT_PAGINATION_PAGE = 1
const DEFAULT_PAGINATION_SIZE = 10
const PAGINATION_PAGE_QUERY_PARAM = 'page'

function getMaxPage(totalCount: number, pageSize: number): number {
  if (totalCount < 1 || pageSize < 1) {
    return 0
  }

  return Math.floor(totalCount / pageSize) + ((totalCount % pageSize) > 0 ? 1 : 0) - 1
}

function getNumberValue(value: AnyType, defaultValue: number): number {
  return value && isNumber(value)
    ? Math.floor(Number(value))
    : defaultValue
}

export function useOrderErpTableInitialData(totalPositionsCount: number): PaginationData {
  const history = useHistory()
  const location = useLocation()
  const { getStorageValue, setValueToStorage } = useLocalStorage('orderItemsStage')
  const query = useQuery()
  const rawPage = query.get(PAGINATION_PAGE_QUERY_PARAM)
  const page = getNumberValue(rawPage, DEFAULT_PAGINATION_PAGE) - 1

  const setPage = useCallback((newPage: number) => {
    const params = new URLSearchParams({
      [PAGINATION_PAGE_QUERY_PARAM]: `${newPage + 1}`,
    })
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    })
  }, [history, location.pathname])

  const setPageSize = useCallback((newPageSize: number) => {
    setValueToStorage('paginationSize', newPageSize)
    setPage(0)
  }, [setPage, setValueToStorage])

  return useMemo(() => {
    const rawPageSize = getStorageValue('paginationSize')
    const pageSize = getNumberValue(rawPageSize, DEFAULT_PAGINATION_SIZE)

    const maxPage = getMaxPage(totalPositionsCount, pageSize)
    if (page < 0 || maxPage < page) {
      setPage(0)
    }

    return {
      page: Math.min(page, maxPage),
      pageSize,
      setPage,
      setPageSize,
    }
  }, [getStorageValue, page, setPage, setPageSize, totalPositionsCount])
}
