import {
  Order,
  OrderStatusEnum,
  OrderStatusGroupEnum,
  ThemeColorEnum,
  getWaitingForPaymentDays,
  unixTimestampToFormattedDate,
} from '../../../../../utils/orders.util'
import { Card, Col, Row, Tag, Tooltip} from 'antd'
import {AuditOutlined, CarryOutOutlined, CheckCircleOutlined, DollarCircleOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectCurrentTheme } from '../../../../../store/theme/selectors'
import IntlMessage from '../../../../../components/util-components/IntlMessage'
import Flex from '../../../../../components/shared-components/Flex'

type ItemHeaderProps = {
  name: string;
  category: string;
}
const ItemHeader = ({name, category}: ItemHeaderProps) => (
  <div>
    <h4 className='mb-0'>{name}</h4>
    <span className='text-muted'>{category}</span>
  </div>
)

type StatusProps = {
  order: Order;
  theme: ThemeColorEnum;
}
const Status = ({order, theme}: StatusProps) => {
  const {status} = order
  let statusColor = 'none'
  const defaultColor = theme === ThemeColorEnum.Light
    ? 'bg-gray-lightest'
    : 'bg-gray'
  switch (status) {
    case OrderStatusEnum.Proposal:
      statusColor = 'geekblue'
      break
    case OrderStatusEnum.Agreement:
      statusColor = 'gold'
      break
    case OrderStatusEnum.InProgress:
      statusColor = 'geekblue'
      break
    case OrderStatusEnum.Received:
      statusColor = 'orange'
      break
    case OrderStatusEnum.Delivered:
      statusColor = 'cyan'
      break
    case OrderStatusEnum.Success:
      statusColor = 'rgba(33,181,115,.6)'
      break
  }
  return (
    <Tag
      className={statusColor === 'none' ? defaultColor : ''}
      color={statusColor === 'none' ? '' : statusColor}
    >
      <span className='ml-2 font-weight-semibold'>
        <IntlMessage id={'orders.status.' + status}/>
      </span>
    </Tag>
  )
}

type WaitingForPaymentProps = {
  order: Order;
  theme: ThemeColorEnum;
}
const WaitingForPayment = ({order, theme}: WaitingForPaymentProps) => {
  const days: number = getWaitingForPaymentDays(order.supplyDate)
  const defaultColor = theme === ThemeColorEnum.Light
    ? 'bg-gray-lightest'
    : 'bg-gray'
  let statusColor = 'none'
  if (days > 30) {
    statusColor = 'red'
  }

  if (days === 0) {
    return <></>
  }

  return (
    <Tag
      className={statusColor === 'none' ? defaultColor : ''}
      color={statusColor === 'none' ? '' : statusColor}
    >
      <span className='ml-2 font-weight-semibold'>
        <IntlMessage id='orders.waiting_for_payment'/>: {days} <IntlMessage id='orders.waiting_for_payment.days'/>
      </span>
    </Tag>
  )
}

type GridItemProps = {
  order: Order;
  theme: ThemeColorEnum;
}
const GridItem = ({ order, theme }: GridItemProps) => {
  const formattedSupplyDate: string = unixTimestampToFormattedDate(order.supplyDate)

  return (
    <Card>
      <Flex
        alignItems='top'
        justifyContent='between'
        className='grid-item__header'
      >
        <ItemHeader
          name={`${order.id}`}
          category={order.name}
        />
        {/*<ItemAction id={data.id} removeId={removeId}/>*/}
        <div>
          <Flex
            alignItems='right'
            flexDirection='row-reverse'
          >
            <Status
              order={order}
              theme={theme}
            />
          </Flex>
          {order.statusGroup === OrderStatusGroupEnum.Active && order.supplyDate !== 0 && (
            <div className='mt-2'>
              <Flex alignItems='right'>
                <WaitingForPayment
                  order={order}
                  theme={theme}
                />
              </Flex>
            </div>
          )}
        </div>
      </Flex>

      <div className='grid-item__body rounded px-2 pt-3'>
        <Row align='middle'>
          <Col xs={12} sm={12} md={12}>
            {order.paymentValue !== 0 && (
              <Tooltip
                title={<IntlMessage id='orders.fields.price'/>}
              >
                <DollarCircleOutlined className='text-muted font-size-md'/>
                <span className='text-muted ml-1'>
                  {order.paymentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽
                </span>
              </Tooltip>
            )}
          </Col>
          <Col xs={12} sm={12} md={12}>
            {order.billId !== '0' && (
              <Tooltip
                title={<IntlMessage id='orders.fields.bill_number'/>}
              >
                <AuditOutlined className='text-muted font-size-md'/>
                <span className='text-muted ml-1'>
                  <IntlMessage id='orders.fields.bill'/> #{order.billId}
                </span>
              </Tooltip>
            )}
          </Col>
        </Row>
      </div>

      {order.supplyDate !== 0 && (
        <div className='grid-item__dates'>
          <div className='rounded px-2 pt-2'>
            <Tooltip
              title={<IntlMessage id='orders.fields.supply_date'/>}
            >
              <FieldTimeOutlined className='font-size-md'/>
              <span className='ml-1'>
                <IntlMessage id='orders.fields.create_date'/>: {formattedSupplyDate}
              </span>
            </Tooltip>
          </div>

          <div className='rounded px-2 pt-2'>
            <Tooltip
              title={<IntlMessage id='orders.fields.supply_date'/>}
            >
              <CheckCircleOutlined className='font-size-md'/>
              <span className='ml-1'>
                <IntlMessage id='orders.fields.accept_date'/>: {formattedSupplyDate}
              </span>
            </Tooltip>
          </div>

          <div className='rounded px-2 pt-2'>
            <Tooltip
              title={<IntlMessage id='orders.fields.supply_date'/>}
            >
              <CarryOutOutlined className='font-size-md'/>
              <span className='ml-1'>
                <IntlMessage id='orders.fields.supply_date'/>: {formattedSupplyDate}
              </span>
            </Tooltip>
          </div>
        </div>
      )}

      {/*<div className="mt-3">*/}
      {/*	<ItemProgress progression={data.progression} />*/}
      {/*</div>*/}
      {/*<div className="mt-2">*/}
      {/*	<ItemMember member={data.member}/>*/}
      {/*</div>*/}
    </Card>
  )
}

type OrdersGridProps = {
  orders: Array<Order>;
}

export const OrdersGrid = (props: OrdersGridProps) => {
  const {orders} = props
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme) as ThemeColorEnum

  return (
    <div className={`orders-grid my-4 container-fluid`}>
      <Row gutter={16}>
        {orders.map(order => (
          <Col
            xs={24}
            sm={24}
            lg={8}
            xl={8}
            xxl={6}
            key={order.id}
          >
            <div className='orders-viewer-order'>
              <GridItem
                order={order}
                theme={theme}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}