import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Switch } from 'antd'
import {
  onCurrencyChange,
  onHeaderNavColorChange,
  onSwitchTheme,
} from '../../store/theme/reducer'
import NavLanguage from './NavLanguage'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import IntlMessage from '../util-components/IntlMessage'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { selectCurrency } from '../../store/theme/selectors'
import { useCallback } from 'react'
import Toggler from '../custom-components/Toggler'
import { CurrencyEnum } from '../../constants/currency'
import { CompanySelector } from '../custom-components/CompanySelector/CompanySelector'
import { selectCompanies } from '../../store/companies/selectors'
import { signOut } from '../../store/auth/reducer'
import { resetSelectedCompanyId } from '../../store/companies/reducer'

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
    <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
    <div>{selector}</div>
  </div>
)

export const Configurator = ({
  currentTheme,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { switcher, themes } = useThemeSwitcher()

  const toggleTheme = isChecked => {
    dispatch(onHeaderNavColorChange(''))
    const changedTheme = isChecked ? 'dark' : 'light'
    dispatch(onSwitchTheme(changedTheme))
    switcher({ theme: themes[changedTheme] })
  }

  const history = useHistory()
  const handleSignOut = useCallback(() => {
    dispatch(signOut())
    dispatch(resetSelectedCompanyId())
    history.push('/auth/login')
  }, [dispatch, history])

  const currentCurrency = useSelector(selectCurrency)
  const handleCurrencyChange = useCallback(() => {
    const nextCurrency =
      currentCurrency === CurrencyEnum.Rub
        ? CurrencyEnum.Usd
        : CurrencyEnum.Rub
    dispatch(onCurrencyChange(nextCurrency))
  }, [dispatch, currentCurrency])

  const companies = useSelector(selectCompanies)

  return (
    <>
      {companies.length > 1 && (
        <div className='mb-5'>
          <h4 className='mb-3 font-weight-bold'>
            Company
          </h4>
          <CompanySelector />
        </div>
      )}
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>
          {intl.messages['sidenav.configuration.navigation']}
        </h4>
        <ListOption
          name={intl.messages['sidenav.configuration.darkTheme']}
          selector={
            <Switch
              checked={currentTheme === 'dark'}
              onChange={toggleTheme}
            />
          }
        />
      </div>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>
          {intl.messages['sidenav.configuration.locale']}
        </h4>
        <ListOption
          name={intl.messages['sidenav.configuration.language']}
          selector={
            <NavLanguage configDisplay />
          }
        />
      </div>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>
          {intl.messages['sidenav.configuration.currency']}
        </h4>
        <Toggler
          firstOption={CurrencyEnum.Rub}
          secondOption={CurrencyEnum.Usd}
          isFirstOptionSelected={currentCurrency === CurrencyEnum.Rub}
          isDarkThemeEnabled={currentTheme === 'dark'}
          onChange={handleCurrencyChange}
        />
      </div>
      <div className='mb-5'>
        <Button
          danger
          onClick={handleSignOut}
        >
          <IntlMessage id='auth.signout' />
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction } = theme
  return { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction }
}

export default connect(mapStateToProps)(Configurator)
