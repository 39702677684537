import axiosInstance from '../auth/FetchInterceptor'
import { CurrencyEnum } from '../constants/currency'
import { API_URL } from '../constants/environment'
import { ExchangeRates } from '../store/basket/types'

export const enum CurrencyResponseEnum {
  Chf = 'CHF',
  Eur = 'EUR',
  Gbr = 'GBR',
  Usd = 'USD',
}

export function convertCurrencyValue(
  exchangeRates: ExchangeRates,
  targetCurrency: CurrencyEnum,
  valueInUsd: number,
): number {
  if (targetCurrency === CurrencyEnum.Usd) {
    return valueInUsd
  }

  const usdToRubExchangeRate: number = exchangeRates[CurrencyEnum.Usd].amount
  if (targetCurrency === CurrencyEnum.Rub) {
    return Math.round(valueInUsd * usdToRubExchangeRate)
  }

  return -1
}

export const CURRENCY_RESPONSE_TO_CURRENCY: Partial<Record<CurrencyResponseEnum, CurrencyEnum>> = {
  [CurrencyResponseEnum.Usd]: CurrencyEnum.Usd,
}
export const CURRENCY_RESPONSES: Array<CurrencyResponseEnum> = [
  CurrencyResponseEnum.Usd,
]
export function mapGetExchangeRatesResponseToExchangeRates(response: GetExchangeRatesResponse): ExchangeRates {
  return CURRENCY_RESPONSES
    .reduce((result, currencyResponse: CurrencyResponseEnum) => {
      const currency: CurrencyEnum = CURRENCY_RESPONSE_TO_CURRENCY[currencyResponse] as CurrencyEnum
      if (!currency) {
        return result
      }

      const { amount, last_update } = response?.currencies?.[currencyResponse] ?? {}
      result[currency] = {
        amount,
        lastUpdateTimestamp: new Date(last_update).getTime(),
      }
      return result
    }, {} as ExchangeRates)
}

export type GetExchangeRatesResponse = {
  currencies: Record<
    CurrencyResponseEnum,
    {
      amount: number;
      last_update: string;
    }
  >;
}

type ExchangeRateServiceType = {
  getCurrentExchangeRates: () => Promise<GetExchangeRatesResponse>;
  getAverageExchangeRates: () => Promise<GetExchangeRatesResponse>;
}

const ExchangeRateService: ExchangeRateServiceType = {
  getCurrentExchangeRates: () =>
    axiosInstance.get<never, GetExchangeRatesResponse>(
      `${API_URL.CURRENCIES}/current`,
    ),
  getAverageExchangeRates: () =>
    axiosInstance.get<never, GetExchangeRatesResponse>(
      `${API_URL.CURRENCIES}/average`,
    ),
}

export default ExchangeRateService
