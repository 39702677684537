import { createSlice } from '@reduxjs/toolkit'
import { getLocalStorageCallbacks } from '../../hooks/useLocalStorage/useLocalStorage'
import { Company, UserRoleEnum } from '../../services/CompanyService'
import { ActionType } from '../types'

type CompaniesState = {
  companies: Array<Company>;
  selectedCompanyId: string | null;
  userRole: UserRoleEnum | null;
}

type SetCompanies = ActionType<Array<Company>>
type SetSelectedCompanyId = ActionType<string>
type SetUserRole = ActionType<UserRoleEnum>

const {
  getStorageObject,
  setValueToStorage,
} = getLocalStorageCallbacks('settings')

function getInitialState(): CompaniesState {
  return {
    companies: [],
    selectedCompanyId: null,
    userRole: null,
  }
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState: { ...getInitialState() },
  reducers: {
    setCompanies(state, action: SetCompanies) {
      const storage = getStorageObject()
      const companies = action.payload ?? []

      state.companies = companies
      state.selectedCompanyId =
        companies.length
          ? storage?.selectedCompanyId ?? companies[0]?.id ?? null
          : null
    },
    resetCompanies(state) {
      state.companies = []
    },
    setSelectedCompanyId(state, action: SetSelectedCompanyId) {
      state.selectedCompanyId = action.payload
      setValueToStorage('selectedCompanyId', state.selectedCompanyId)
    },
    resetSelectedCompanyId(state) {
      state.selectedCompanyId = null
      setValueToStorage('selectedCompanyId', state.selectedCompanyId)
    },
    setUserRole(state, action: SetUserRole) {
      state.userRole = action.payload
    },
    resetUserRole(state) {
      state.userRole = null
    },
  },
})

export const companiesReducer = companiesSlice.reducer
export const {
  setCompanies,
  resetCompanies,
  setSelectedCompanyId,
  resetSelectedCompanyId,
  setUserRole,
  resetUserRole,
} = companiesSlice.actions
