//Параметры поиска
import axios from 'axios'
import React from 'react'
import {ProductBasketType} from '../../store/basket/types'
import { ProductType } from '../../store/search/types'

export type SearchParamsType = {
  any?: string | number
  catalogue_id?: string | number
  cas_id?: string | number
  name?: string
  vendor?: string
}

//Axios Props
export const CancelToken = axios.CancelToken
export const source = CancelToken.source()

//Axios Suggest Request Data
export const axiosSuggestRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}

//Axios Search Request Data
export const axiosSearchRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}

export type SuggestItemProduct = {
  cas_id: string;
  catalogue_id: string;
  class: string;
  country_of_purchase: string;
  field: string;
  group: string;
  id: string;
  name: string;
  vendor: string;
}

export type SuggestItemText = {
  hl: boolean;
  text: string;
}

export type SuggestItem = {
  data: SuggestItemProduct;
  text: SuggestItemText[];
  weight: number;
}

export type SuggestDropdownItemProps = {
  id: number,
  item: SuggestItem,
  theme: string,
  addToBasketFromSearchResult: (
    e: React.MouseEvent<HTMLElement>,
    product: SuggestItemProduct,
    basketProducts: ProductBasketType[]
  ) => void,
}

export type AnalogsSortingProp = keyof Pick<ProductType, 'package_size' | 'partnership_type' | 'vendor'> | ''
