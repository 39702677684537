import { PaperClipOutlined } from '@ant-design/icons'

type LinksToDocumentProps = {
  text: string;
  url: string;
}

export function LinkToDocument({ text, url }: LinksToDocumentProps) {
  return (
    <>
      {text && url && (
        <a
          href={url}
          target='_blank'
        >
          <PaperClipOutlined />{' '}{text}
        </a>
      )}
      {!url && (
        text || '-'
      )}
    </>
  )
}
