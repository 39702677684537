import { createSlice } from '@reduxjs/toolkit'
import { IntegratedDeliveryNote } from '../../utils/orders.util'
import { ActionType } from '../types'

type IdnTableState = {
  integratedDeliveryNotes: Array<IntegratedDeliveryNote>;
}

type SetIntegratedDeliveryNotesAction = ActionType<Array<IntegratedDeliveryNote>>

function getInitialState(): IdnTableState {
  return {
    integratedDeliveryNotes: [],
  }
}

const idnTableSlice = createSlice({
  name: 'idn-table',
  initialState: { ...getInitialState() },
  reducers: {
    setIntegratedDeliveryNotes(state, action: SetIntegratedDeliveryNotesAction) {
      state.integratedDeliveryNotes = action.payload
    },
  },
})

export const idnTableReducer = idnTableSlice.reducer
export const {
  setIntegratedDeliveryNotes,
} = idnTableSlice.actions
