import { SerializedOrderItemsTableFilters } from '../../../../../hooks/useLocalStorage/types'
import { ORDER_ITEM_STATUS_TO_PRIORITY, OrderItem, mapOrderItemStatusToMessage, unixTimestampToFormattedDate, LocaleEnum } from '../../../../../utils/orders.util'
import { OrderErpTableFilters } from './hooks/OrderErpTableFilters.hook'
import { TableOrderItem, TableOrderItemColumnId } from './OrderErpTable.type'

export function mapOrderItemToTableOrderItem(orderItem: OrderItem): TableOrderItem {
  return {
    ...orderItem,
    id: `${orderItem.id}`,
  }
}

export function compareOrderItemStatuses(a: TableOrderItem, b: TableOrderItem): number {
  if (a.lastStatusDate !== b.lastStatusDate) {
    return a.lastStatusDate - b.lastStatusDate
  }

  const aPriority: number = ORDER_ITEM_STATUS_TO_PRIORITY[a?.status] || 0
  const bPriority: number = ORDER_ITEM_STATUS_TO_PRIORITY[b?.status] || 0

  return aPriority - bPriority
}

export function getOrderItemPropertyToCSVFn(
  locale: LocaleEnum,
): (
  orderItem: TableOrderItem,
  columnId: TableOrderItemColumnId,
) => string {
  return function orderItemPropertyToCSV(
    orderItem: TableOrderItem,
    columnId: TableOrderItemColumnId,
  ): string {
    switch (columnId) {
      case 'billPosition':
      case 'quantity':
      case 'totalPrice':
      case 'salesComments':
        return `${orderItem[columnId] || '-'}`
      case 'status':
        return mapOrderItemStatusToMessage(
          orderItem?.status,
          orderItem?.statusDescription,
          orderItem?.lastStatusDate,
          locale,
        )
      case 'name':
        return `${orderItem[columnId]}`
      case 'expectedDeliveryDate':
        return unixTimestampToFormattedDate(orderItem?.expectedDeliveryDate) || '-'
      default:
        return ''
    }
  }
}

export function serializeFilters(filters: OrderErpTableFilters): SerializedOrderItemsTableFilters {
  if (!filters) {
    return {
      status: [],
    }
  }

  return {
    ...filters,
  }
}

export function deserializeFilters(serializedFilters: SerializedOrderItemsTableFilters): OrderErpTableFilters {
  if (!serializedFilters) {
    return {
      status: [],
    }
  }

  return {
    ...serializedFilters,
  }
}
