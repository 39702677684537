import { Tooltip } from '@mantine/core'
import { TableOrder } from '../OrdersErpTable.type'
import { useIntl } from 'react-intl'
import { ExpressIcon } from '../../../../../../components/custom-components/ExpressIcon'

type Props = {
  order: TableOrder;
}

export function ExpressCell({ order }: Props) {
  const intl = useIntl()
  const { isExpress } = order

  return (
    <Tooltip
      label={intl.messages['orders.express.tooltip_title']}
      disabled={!isExpress}
      zIndex={1000}
    >
      <ExpressIcon isOn={isExpress} />
    </Tooltip>
  )
}
