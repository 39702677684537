import axios from 'axios'
import history from '../history'
import { AUTH_TOKEN } from '../redux/constants/Auth'
import { notification } from 'antd'
import { API_BASE_URL } from '../configs/AppConfig'
import { ArgsProps } from 'antd/lib/notification'
import { CombinedState } from 'redux'
import { EnhancedStore } from '@reduxjs/toolkit'
import { RootReducer } from '../store/types'

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
})

// inject store for companyId
type StoreType = EnhancedStore<CombinedState<RootReducer>>
let store: StoreType
export const injectStoreToAxios = (_store: StoreType) => {
  store = _store
}

const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'X-AUTH-TOKEN'
const PUBLIC_REQUEST_KEY = 'public-request'
const COMPANY_KEY = 'X-COMPANY'

// API request interceptor
service.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN)
  const companyId = store?.getState()?.companies?.selectedCompanyId

  if (jwtToken && config.headers) {
    config.headers[TOKEN_PAYLOAD_KEY] = jwtToken

    if (companyId) {
      config.headers[COMPANY_KEY] = companyId
    }
  }

  if (!jwtToken && !config.headers?.[PUBLIC_REQUEST_KEY]) {
    history.push(ENTRY_ROUTE)
    window.location.reload()
  }

  return config
}, error => {
  const defaultMessage = 'Interceptor request error'
  notification.error({
    message: defaultMessage,
  })
  return Promise.reject(new Error(error?.message ?? defaultMessage))
})

// API response interceptor
service.interceptors.response.use(response => response.data, error => {
  const notificationParam: ArgsProps = {
    message: '',
  }

  // Remove token and redirect
  if (error && error.response && error.response.status === 403) {
    notificationParam.message = 'Authentication Fail'
    notificationParam.description = 'Please login again'
    localStorage.removeItem(AUTH_TOKEN)
    history.push(ENTRY_ROUTE)
    window.location.reload()
  }

  if (error && error.response && error.response.status === 404) {
    notificationParam.message = 'Not Found'
  }

  if (error && error.response && error.response.status === 500) {
    notificationParam.message = 'Internal Server Error'
  }

  if (error && error.response && error.response.status === 508) {
    notificationParam.message = 'Time Out'
  }

  if (notificationParam.message !== '') {
    notification.error(notificationParam)
  }

  if (error?.response?.data?.message) {
    return Promise.reject(new Error(error.response.data.message))
  }

  return Promise.reject(new Error(error?.response?.data))
})

export default service
