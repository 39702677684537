import React from 'react'
import { Col, Row, Tabs } from 'antd'
import { SearchContent } from '../../../../content/search'
import { Basket } from '../../../../content/basket'
import { GenerateTableContent } from '../../../../content/generate-table'
import { useStickyBox } from '../../../../utils/sticky-scroll'
import { useIntl } from 'react-intl'
import { useProposalView } from './proposal-view.hook'
import { NotLinked } from '../../orders/OrdersView'
import { HandleInputBasketContent } from '../../../../content/handle-input-basket'

const { TabPane } = Tabs

export const ProposalContent: React.FC = () => {
  const {
    loading,
    showMainLayout,
  } = useProposalView()

  const intl = useIntl()
  const stickyRef = useStickyBox({
    offsetBottom: 20,
    offsetTop: 80,
  })

  if (loading) {
    return null
  }

  if (!showMainLayout) {
    return (
      <NotLinked />
    )
  }

  return (
    <Row>
      <Col
        span={24}
        xl={12}
        className='pr-2 pl-2 mt-3'
      >
        <Tabs
          type='card'
          defaultActiveKey='1'
        >
          <TabPane
            tab={intl.messages['sidenav.my_orders.catalog_search']}
            key='1'
          >
            <SearchContent />
          </TabPane>
          <TabPane
            tab={intl.messages['sidenav.my_orders.handle_input']}
            key='2'
          >
            <HandleInputBasketContent/>
          </TabPane>
        </Tabs>
      </Col>
      <Col
        span={24}
        xl={12}
        className='pr-2 pl-2 mt-3'
      >
        <div
          // @ts-ignore
          ref={stickyRef}
          className='basket-wrapper'
        >
          <Basket />
          <GenerateTableContent />
        </div>
      </Col>
    </Row>
  )
}
