import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getLocalStorageCallbacks } from '../../hooks/useLocalStorage/useLocalStorage'
import { OrderItem } from '../../utils/orders.util'
import { ActionType } from '../types'

export interface PartialUpdateAction<O extends object, F extends keyof O> {
  field: F,
  value: O[F],
}

export type OrderItemsTableGlobalFiltersData = {
  nameIds: Array<string>;
}

export type OrderItemTimelineModalState = {
  isOpen: boolean;
  itemId: string | null;
}

type OrderItemsTableState = {
  orderItems: Array<OrderItem>;
  globalFilters: OrderItemsTableGlobalFiltersData;
  orderItemTimelineModalState: OrderItemTimelineModalState;
}

type SetOrderItemsAction = ActionType<Array<OrderItem>>;
type SetOrderItemTimelineModalStateAction = ActionType<OrderItemTimelineModalState>;

const {
  getStorageObject,
  setValueToStorage,
} = getLocalStorageCallbacks('orderItemsStage')

function getInitialState(): OrderItemsTableState {
  const storage = getStorageObject()

  return {
    orderItems: [],
    // TODO temporal fix for WMLN-2061
    // globalFilters: storage?.globalFilters || {
    //   nameIds: [],
    // },
    globalFilters: {
      nameIds: [],
    },
    orderItemTimelineModalState: {
      isOpen: false,
      itemId: null,
    },
  }
}

const orderItemsTableSlice = createSlice({
  name: 'order-items-table',
  initialState: {...getInitialState()},
  reducers: {
    setTableOrderItems(state, action: SetOrderItemsAction) {
      state.orderItems = action.payload
    },
    setOrderItemsTableGlobalFilter<T extends keyof OrderItemsTableGlobalFiltersData>(
      state: OrderItemsTableState,
      { payload }: PayloadAction<PartialUpdateAction<OrderItemsTableGlobalFiltersData, T>>,
    ) {
      state.globalFilters[payload.field] = payload.value
      // TODO temporal fix for WMLN-2061
      // setValueToStorage('globalFilters', state.globalFilters)
    },
    setOrderItemTimelineModalState(state, action: SetOrderItemTimelineModalStateAction) {
      state.orderItemTimelineModalState = action.payload
    },
  },
})

export const orderItemsTableReducer = orderItemsTableSlice.reducer
export const {
  setTableOrderItems,
  setOrderItemsTableGlobalFilter,
  setOrderItemTimelineModalState,
} = orderItemsTableSlice.actions
