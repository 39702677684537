import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { Col, Divider, InputNumber, Row, Tooltip, Typography, notification } from 'antd'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasketProducts } from '../../../store/basket/selectors'
import { selectCurrency, selectLocale } from '../../../store/theme/selectors'
//@ts-ignore
import { setDefaultOrderName, setOrderName } from '../../../store/basket/reducer'
import parse from 'html-react-parser'
import ProposalService from '../../../services/ProposalService'
import { ORDER_NAME_KEY } from '../../../constants/localStorage'
import { PartialProductItem, ProductBasketType } from '../../../store/basket/types'
import { useIntl } from 'react-intl'
import { mapProductItemsToPartial } from '../../../utils/mappers'
import { DEFAULT_PRODUCT_PRICE } from '../../../constants/commonConstants'
import { CountryEnum, hasCountryIcon } from '../../../constants/country'
import CountryFlagIcon from '../CountryFlagIcon'
import { AvailabilityInfo } from '../AvailabilityInfo/AvailabilityInfo'
import * as Sentry from '@sentry/react'
import { selectPaymentOptionsLoading } from '../../../store/payment-options/selectors'
import { Skeleton } from '@mantine/core'
import { LocaleEnum } from '../../../utils/orders.util'

const {
  Title,
  Text,
} = Typography

type ProductBasketCardProps = ProductBasketType & {
  itemIndex: number;
  isLastItem?: boolean;
  isDisableCount: boolean;
  deliveryPartialCost?: number;
  scrollTrigger?: () => void;
  onStartEditHandler?: (index: number) => void;
  onCount: (value: number, itemIndex: number, availability_type?: string) => void;
}

export const BasketProductCard: React.FC<ProductBasketCardProps> = props => {
  const dispatch = useDispatch()

  const locale: LocaleEnum = useSelector(selectLocale)
  const currentCurrency = useSelector(selectCurrency)
  const basketProducts = useSelector(selectBasketProducts)
  const isPaymentOptionsLoading: boolean = useSelector(selectPaymentOptionsLoading)

  const intl = useIntl()

  const {
    catalogue_id,
    vendor,
    count,
    currency_prices = [],
    name,
    onCount,
    itemIndex,
    scrollTrigger,
    isLastItem,
    onStartEditHandler,
    isDisableCount,
    id,
    info_from_client,
    availability_type,
    availability_flag,
    partner_url,
    delivery_period,
    delivery_period_en,
    country_of_purchase = '',
    deliveryPartialCost,
    brand,
  } = props

  const currentPrice = currency_prices
    .find(({ currency }) => currency === currentCurrency)
    ?.price ?? DEFAULT_PRODUCT_PRICE
  const productTitleText: string = !id && !name ? info_from_client : name
  const productTitle = parse(`<span>${productTitleText}</span>`)
  const countryOfPurchase: string = (hasCountryIcon(country_of_purchase) && country_of_purchase) || ''
  const availabilityText: string | undefined = useMemo(() => {
    if (!availability_flag) {
      return intl.messages['sidenav.my_orders.product_card.unavailable'] as string
    }

    if (!delivery_period) {
      return intl.messages['sidenav.my_orders.product_card.available'] as string
    }

    return locale === LocaleEnum.Ru
      ? delivery_period
      : delivery_period_en
  }, [availability_flag, delivery_period, delivery_period_en, intl.messages, locale])

  useEffect(() => {
    if (isLastItem && scrollTrigger) {
      scrollTrigger()
    }
  }, [isLastItem, scrollTrigger])

  const onCountHandler = useCallback((value: number) => {
    onCount(value, itemIndex, availability_type)
  }, [onCount, itemIndex, availability_type])

  const formattedPrice = useMemo((): string => {
    if (!id || currentPrice === 0) {
      return intl.messages['sidenav.basket.by_request'] as string
    }

    return `${currentPrice * count + (deliveryPartialCost || 0)} ${currentCurrency}`
  }, [intl.messages, id, currentPrice, count, deliveryPartialCost, currentCurrency])

  const onStartEdit = (index: number) => {
    onStartEditHandler && onStartEditHandler(index)
  }

  const partialProductItems: PartialProductItem[] = useMemo(
    () => mapProductItemsToPartial(basketProducts),
    [basketProducts],
  )

  const handleDeleteBtnClick = useCallback(() => {
    const newPartialProductItems: PartialProductItem[] = [
      ...partialProductItems.slice(0, itemIndex),
      ...partialProductItems.slice(itemIndex + 1),
    ]

    onCountHandler(-1)

    ProposalService
      .generateOrderName(newPartialProductItems)
      .then(({ name: generatedOrderName }) => {
        dispatch(setDefaultOrderName(generatedOrderName))
        if (localStorage.getItem(ORDER_NAME_KEY) === null) {
          dispatch(setOrderName(generatedOrderName))
        }
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при генерации имени сделки',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
  }, [onCountHandler, dispatch, partialProductItems, itemIndex])

  return (
    <div className='basket-product-card'>
      <Row className='justify-content-between'>
        <Col style={{ paddingTop: 2 }}>
          <span
            className='mr-2 text-gray-light opacity-0-5'
            style={{ display: 'inline-block' }}
          >
            <b>
              {itemIndex + 1}.
            </b>
          </span>
        </Col>
        <Col
          span={12}
          className='mr-auto'
        >
          <Title
            level={4}
            className='mb-2 text-dark d-flex align-items-top mr-auto'
            style={{
              display: 'inline-block',
              wordBreak: 'break-all',
            }}
          >
            {!!partner_url && (
              <a
                href={partner_url}
                target='_blank'
                className='text-dark mr-2'
                style={{ textDecoration: 'underline' }}
              >
                {productTitle}
              </a>
            )}
            {!partner_url && (
              <span className='text-dark mr-2'>
                {productTitle}
              </span>
            )}
            <EditOutlined
              className='text-info'
              style={{
                fontSize: 17,
                cursor: 'pointer',
                paddingTop: 3,
              }}
              onClick={() => onStartEdit(itemIndex)}
            />
          </Title>
          <div className='props-holder'>
            {vendor && (
              <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>
                {vendor}
              </Text>
            )}
            {brand && brand !== vendor && (
              <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>
                {brand}
              </Text>
            )}
            {countryOfPurchase && (
              <div
                className='mr-2'
                style={{
                  width: 16,
                  display: 'inline-block',
                }}
              >
                <CountryFlagIcon countryCode={countryOfPurchase as CountryEnum} />
              </div>
            )}
            <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>
              {`${catalogue_id || ''}`}
            </Text>
          </div>
        </Col>
        <Col className='d-flex align-items-center'>
          <div className='d-flex flex-column align-items-end'>
            <Skeleton
              className='d-flex flex-column align-items-end'
              radius='xs'
              animate
              visible={isPaymentOptionsLoading}
              width={isPaymentOptionsLoading ? 70 : 'auto'}
            >
              <div className='d-flex align-items-center'>
                <span style={{ fontWeight: 'bold' }}>
                  {formattedPrice}
                </span>
              </div>
              {!!deliveryPartialCost && (
                <div className='text-gray-light d-flex align-items-center'>
                  &nbsp;
                  {'(Вкл. '}
                  {`${deliveryPartialCost}`} {currentCurrency}
                  &nbsp;
                  <Tooltip
                    placement='top'
                    title='За доставку по РФ'
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                  {')'}
                </div>
              )}
            </Skeleton>
            <div className='d-flex align-items-center'>
              <Text className={`mr-1 font-weight-bold rounded product-top-label ${availability_flag ? 'text-success' : 'text-gray-light opacity-0-5'}`}>
                {availabilityText}
              </Text>
              <AvailabilityInfo
                isAvailable={availability_flag}
              />
            </div>
          </div>
          <InputNumber
            min={1}
            className='ml-3'
            style={{ maxWidth: 70 }}
            size='small'
            value={count}
            disabled={isDisableCount}
            onChange={value => onCountHandler(value)}
          />
          <div
            style={{
              visibility: isDisableCount ? 'hidden' : 'visible',
            }}
          >
            <DeleteOutlined
              className='text-danger ml-3'
              style={{
                fontSize: 21,
                cursor: 'pointer',
              }}
              onClick={handleDeleteBtnClick}
            />
          </div>
        </Col>
      </Row>
      {!isLastItem && (
        <Divider />
      )}
    </div>
  )
}
