//Check Filter Word
import { ProductType } from '../../store/search/types'

export const isFilteredWord = (item: ProductType, trimString: string) => {
  let status = false
  Object.keys(item).forEach(key => {
    //@ts-ignore
    if (item[key as string].toString().toLocaleUpperCase().indexOf(trimString) !== -1) {
      status = true
    }
  })
  return status
}

export function repeatQueryParams(key: string, values: string[]) {
  return values.map((value: string) => `${key}=${value}`).join('&')
}

const getPositionMessageRu = (count: number): string => {
  const lastDigit: number = count % 10
  const twoLastDigits: number = count % 100

  if (11 <= twoLastDigits && twoLastDigits <= 14) {
    return 'позиций'
  }

  if (lastDigit === 1) {
    return 'позиция'
  }

  if (2 <= lastDigit && lastDigit <= 4) {
    return 'позиции'
  }

  return 'позиций'
}

export const getSearchResultCountMessage = (count: number, locale: string): string => {
  if (locale !== 'ru') {
    const positionMessage: string =
      count === 1
        ? 'position'
        : 'positions'

    return `Found ${count} ${positionMessage}`
  }

  const foundMessage: string =
    count % 10 === 1 && count % 100 !== 11
      ? 'Найдена'
      : 'Найдено'

  return `${foundMessage} ${count} ${getPositionMessageRu(count)}`
}
