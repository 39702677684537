import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'
import { OrderDeliveryStatusEnum } from '../utils/orders.util'

export type OrderContact = {
  id: number;
  name: string;
  email: string;
}

export type OrderResponse = {
  approved_at: number;
  bill_number: string;
  created_at: number;
  delay_status: OrderDeliveryStatusEnum;
  delivery_city: string;
  id: number;
  money_received: number;
  name: string;
  pay_condition_code: string;
  pay_condition_desc: string;
  pay_condition_desc_en: string;
  payment_deadline: number;
  payment_status: string;
  price: string;
  responsible_user_name: string;
  responsible_user_email: string;
  seller_company_code: string;
  seller_company_name: string;
  spec_number: string;
  status: string;
  status_group: string;
  supply_date: string;
  supply_deadline: number;
  supply_deadline2: number;
  contacts: Array<OrderContact>;
  express: boolean;
}

export type OrderItemResponse = {
  bill_position: number;
  brand: string;
  catalogue_id: string;
  cost: number;
  count: number;
  deadline: string;
  express: boolean;
  actual_deadline: string;
  id: number;
  name: string;
  original_name: string;
  price: number;
  provider_exp: string;
  provider_lot: string;
  status: string;
  status_desc: string;
  status_desc_en: string;
  sum: number;
  supply_country: string;
  supply_store: string;
  last_status_date: string;
  expected_delivery_date: string;
  sales_comments: string;
  deadline_history: Array<{
    old_date: string;
    new_date: string;
  }>;
}

export type IdnItemResponse = {
  id: number;
  count: number;
  name: string;
  price: number;
  unit: string;
  upd_id: number;
}

export type IdnResponse = {
  document_date: string;
  document_number: string;
  pdf_url: string;
  sum: number;
  items: Array<IdnItemResponse>;
}

export type GetOrderResponse = {
  result: string;
  error_reason: string;
  items: Array<OrderItemResponse>;
  order: OrderResponse;
  proposal_pdf_url: string;
  delay_status: OrderDeliveryStatusEnum;
  upd: Array<IdnResponse>;
  bill: {
    amodeal_id: number;
    doc_date: string;
    number: string;
    paid_sum: number;
    pdf_url: string;
    sum: number;
  }
}

export enum OrderItemTimelineStatusType {
  History = 'history',
  Current = 'current',
  Forecast = 'forecast',
}

export type OrderItemTimelineStatusResponse = {
  time: string;
  status_desc_ru: string;
  status_desc_en: string;
  status_type: OrderItemTimelineStatusType;
  status_correct: string;
}

export type GetOrderItemTimelineResponse = {
  status_list: Array<OrderItemTimelineStatusResponse>;
}

export enum SortingOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

type OrderServiceType = {
  getOrder: (id: string) => Promise<GetOrderResponse>,
  getOrderItemTimeline: (itemId: string, sortingOrder: SortingOrder) => Promise<GetOrderItemTimelineResponse>,
}

export const OrderService: OrderServiceType = {
  getOrder: (id: string) =>
    axiosInstance
      .get<never, GetOrderResponse>(
        `${API_URL.CUSTOMER_AREA}/get-order`,
        {
          params: {
            id,
          },
        },
      ),
  getOrderItemTimeline: (itemId: string, sortingOrder: SortingOrder) =>
    axiosInstance
      .get<never, GetOrderItemTimelineResponse>(
        `${API_URL.CUSTOMER_AREA}/get-item-timeline`,
        {
          params: {
            item_id: itemId,
            sort_order: sortingOrder,
          },
        },
      ),
}
