import { Tooltip } from '@mantine/core'
import { useIntl } from 'react-intl'
import { ExpressIcon } from '../../../../../../components/custom-components/ExpressIcon'
import { TableOrderItem } from '../OrderErpTable.type'

type Props = {
  orderItem: TableOrderItem;
}

export function ExpressCell({ orderItem }: Props) {
  const intl = useIntl()
  const { isExpress } = orderItem

  return (
    <Tooltip
      label={intl.messages['orderTable.columns.isExpress.tooltipTitle']}
      disabled={!isExpress}
      zIndex={1000}
    >
      <ExpressIcon isOn={isExpress} />
    </Tooltip>
  )
}
