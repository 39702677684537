import React, { useState } from 'react'
import { Form, notification } from 'antd'
import { selectHandleInputProduct } from '../../store/search/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setProductsListAction } from '../../store/basket/reducer'
import { HandleInputProductType } from '../../store/search/reducer'
import { HandleInputForm } from '../../components/custom-components/handle-input-form'
import { BASKET_KEY } from '../../constants/localStorage'
import { ProductBasketType } from '../../store/basket/types'
import * as Sentry from '@sentry/react'

function getSavedCart(): Array<ProductBasketType> {
  const serializedCart = localStorage.getItem(BASKET_KEY)

  try {
    const deserializedCart: Array<ProductBasketType> = JSON.parse(serializedCart || '')
    return deserializedCart || []
  }
  catch (error) {
    notification.error({
      message: 'Reading cart error',
      duration: 5,
    })
    Sentry.captureException(error)
    saveCart([])
    return []
  }
}

function saveCart(cart: Array<ProductBasketType>): void {
  try {
    const serializedCart: string = JSON.stringify(cart)
    localStorage.setItem(
      BASKET_KEY,
      serializedCart,
    )
  }
  catch (error) {
    notification.error({
      message: 'Saving cart error',
      duration: 5,
    })
    Sentry.captureException(error)
  }
}

export const HandleInputBasketContent: React.FC = () => {
  const handleInputProduct = useSelector(selectHandleInputProduct)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [price_currency] = useState<string>('USD')

  const onFinish = (product: HandleInputProductType) => {
    const newProduct = {
      ...product,
      price_currency,
    } as unknown as ProductBasketType
    const cart: Array<ProductBasketType> = getSavedCart()

    const newCart: Array<ProductBasketType> = cart.concat([newProduct])
    saveCart(newCart)
    dispatch(setProductsListAction(newCart))

    form.resetFields()
  }

  return (
    <HandleInputForm
      initialValues={handleInputProduct}
      onFinishHandler={onFinish}
      showFinishBtn
    />
  )
}
