import React from 'react'

export default function Footer() {
  return (
    <footer className='footer'>
      <span>Copyright  &copy;  {`${new Date().getFullYear()}`} All rights reserved.</span>
    </footer>
  )
}

