import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

type GetCitiesResponse = {
  city_costs: Array<{
    city: string;
    cost: string;
  }>
}

type DeliveryCostServiceType = {
  getCities: () => Promise<GetCitiesResponse>
}

const DeliveryCostService: DeliveryCostServiceType = {
  getCities: () =>
    axiosInstance.get<never, GetCitiesResponse>(
      `${API_URL.PARTNER_PRODUCTS}/last-mile`,
    ),
}

export default DeliveryCostService
