import { Select } from '@mantine/core'
import { SelectItem } from '@mantine/core/lib/components/Select/types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Company } from '../../../services/CompanyService'
import { setSelectedCompanyId } from '../../../store/companies/reducer'
import { selectCompanies, selectSelectedCompanyId } from '../../../store/companies/selectors'
import {getUserInfo} from '../../../layouts/app-layout/getUserInfo'

export function CompanySelector() {
  const dispatch = useDispatch()

  const companies: Array<Company> = useSelector(selectCompanies)
  const companiesData = useMemo<Array<SelectItem>>(
    () =>
      companies
        .map(({ id, name }) => ({
          value: id,
          label: name,
        }))
    , [companies],
  )
  const selectedCompanyId = useSelector(selectSelectedCompanyId)

  const onChange = useCallback((companyId: string) => {
    dispatch(setSelectedCompanyId(companyId))
    getUserInfo(dispatch)
  }, [dispatch])
  const onFilter = useCallback((value: string, item: SelectItem): boolean => (
    item?.label?.toLowerCase().includes(value.toLowerCase().trim())
    || item?.description?.toLowerCase().includes(value.toLowerCase().trim())
  ), [])

  return (
    <Select
      data={companiesData}
      value={selectedCompanyId}
      onChange={onChange}
      placeholder='Choose company'
      searchable
      maxDropdownHeight={400}
      nothingFound='No companies'
      filter={onFilter}
      withinPortal={false}
    />
  )
}
