import { IntegratedDeliveryNote } from '../../../../../utils/orders.util'
import { TableIntegratedDeliveryNote } from './OrderIdnTable.type'

export function mapIntegratedDeliveryNoteToTableIntegratedDeliveryNote(
  idn: IntegratedDeliveryNote,
): TableIntegratedDeliveryNote {
  return {
    ...idn,
    id: `${idn.idnId}`,
  }
}
