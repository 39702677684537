import { Text } from '@mantine/core'
import {DocumentDownloadIcon} from '@heroicons/react/outline'
import { useIntl } from 'react-intl'
import { DataTableColumnInfo } from '@appscience/data-table'
import { useMemo } from 'react'
import { ExtraActionData, useExcelExportPopup } from '../../../../../../hooks/useExcelExportPopup/useExcelExportPopup'
import { TableOrderItem, TableOrderItemColumnId } from '../OrderErpTable.type'
import { getOrderItemPropertyToCSVFn } from '../OrderErpTable.utils'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../../../../../store/theme/selectors'
import { LocaleEnum } from '../../../../../../utils/orders.util'

export function useOrderErpTableExtraActions(
  columns: Array<DataTableColumnInfo<TableOrderItemColumnId, TableOrderItem>>,
  selectedRowIds: Array<string>,
  data: Array<TableOrderItem>,
): Array<ExtraActionData> {
  const intl = useIntl()
  const locale: LocaleEnum = useSelector(selectLocale)
  const messages = intl.messages as Record<string, string>

  const { openExportPopup } = useExcelExportPopup<TableOrderItemColumnId, TableOrderItem>({
    headerAccessor: columnId => messages[`orderTable.columns.${columnId}`],
    contentAccessor: getOrderItemPropertyToCSVFn(locale),
  }, columns, selectedRowIds, data)

  return useMemo<Array<ExtraActionData>>(() => [{
    id: 'export-xls',
    text: intl.messages['ordersTable.export.xlsx'] as string,
    icon: <DocumentDownloadIcon className='w-4 h-4' />,
    rightSection: <Text size='xs' color='dimmed'>Ctrl + Q</Text>,
    onClick: openExportPopup,
  }], [openExportPopup, intl.messages])
}
