import React from 'react'
import {Button, Card, Col, Row} from 'antd'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useGenerateTable} from './generate-table.hook'

export const GenerateTableContent: React.FC = () => {
  const history = useHistory()
  const {
    isEmptyBasket,
  } = useGenerateTable()

  const intl = useIntl()

  return (
    <Card className='generate-table-content'>
      <Row>
        <Col
          span='24'
          className='mb-2'
        ></Col>
        <Col span='24'>
          <div>
            <Button
              block={true}
              disabled={isEmptyBasket}
              onClick={() => history.push('/new/create')}
            >
              {intl.messages['sidenav.my_orders.go_to_order']}
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  )
}
