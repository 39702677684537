import React, { useCallback } from 'react'
import Highlighter from 'react-highlight-words'
import parse from 'html-react-parser'
import {
  propsItemStyle,
  titleStyle,
} from './SuggestItemContent.style'
import { SuggestItem } from '../../../content/search/search.type'
import { CountryEnum, hasCountryIcon } from '../../../constants/country'
import CountryFlagIcon from '../CountryFlagIcon'

type SuggestItemContentProps = {
  item: SuggestItem;
}

export const SuggestItemContent: React.FC<SuggestItemContentProps> = props => {
  const { item: { data: itemData, text: itemTexts } } = props
  const { country_of_purchase } = itemData
  const countryOfPurchase: string = (hasCountryIcon(country_of_purchase) && country_of_purchase) || ''

  const highlightTextArray = useCallback((fieldName: string) => {
    if (!itemTexts && fieldName === 'name') {
      return parse(`<span>${itemData[fieldName as keyof typeof itemData]}</span>`)
    }

    if (!itemTexts) {
      return itemData[fieldName as keyof typeof itemData]
    }

    return itemTexts.map((itemText, key) => {
      if (!itemText.hl) {
        return <span key={key}>{parse(itemText.text)}</span>
      }

      return (
        <span key={key}>
          <Highlighter
            activeClassName='bgDarg'
            searchWords={[itemText.text]}
            autoEscape={true}
            textToHighlight={itemText.text}
          />
        </span>
      )
    })
  }, [itemData, itemTexts])

  return (
    <div
      className='search-result-dropdown-item d-flex align-items-center'
      style={{
        position: 'relative',
        whiteSpace: 'normal',
      }}
    >
      <div
        className='title-holder mr-auto pr-3'
        style={{
          wordBreak: 'break-word',
        }}
      >
        <h5
          className='title m-0 font-weight-normal'
          style={titleStyle}
        >
          {itemData.field === 'name'
            ? highlightTextArray('name')
            : parse(`<span class="name-wrapper">${itemData.name}</span>`)
          }
        </h5>
      </div>
      <div
        className='props-holder justify-content-between text-center d-sm-flex'
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {itemData.cas_id !== 'n/a' && (
          <span
            className='text-black mr-2 font-weight-normal opacity-0-7'
            style={propsItemStyle}
          >
            {itemData.field === 'cas_number'
              ? highlightTextArray('cas_id')
              : `${itemData.cas_id}`
            }
          </span>
        )}
        <span
          className='text-black mr-2'
          style={propsItemStyle}
        >
          {itemData.field === 'catalogue_id'
            ? highlightTextArray('catalogue_id')
            : `${itemData.catalogue_id}`
          }
        </span>
        <span
          className='text-primary font-weight-bold'
          style={propsItemStyle}
        >
          {itemData.field === 'vendor'
            ? highlightTextArray('vendor')
            : `${itemData.vendor}`
          }
        </span>
        {countryOfPurchase && (
          <div
            className='ml-2'
            style={{width: 16, display: 'inline-block'}}
          >
            <CountryFlagIcon countryCode={countryOfPurchase as CountryEnum} />
          </div>
        )}
      </div>
    </div>
  )
}
