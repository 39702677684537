import React, { FormEvent, useEffect, useState } from 'react'
import { HandleInputProductType } from '../../../store/search/reducer'
import { Button, Card, Col, Form, Input, Row, Select, notification } from 'antd'
import VendorService from '../../../services/VendorService'
import Utils from '../../../utils'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'

const { Option } = Select

type HandleInputFormProps = {
  initialValues: HandleInputProductType;
  showFinishBtn?: boolean;
  onFinishHandler?: (product: HandleInputProductType) => void;
  onChangeHandler?: (name: string, value: string) => void;
}

export const HandleInputForm: React.FC<HandleInputFormProps> = ({
  onFinishHandler,
  onChangeHandler,
  showFinishBtn,
  initialValues,
}) => {
  const {
    name,
    catalogue_id,
    cas_id,
    price,
    shipping_conditions,
    package_size,
    vendor,
    partner_url,
    count,
    info_from_client,
    id,
  } = initialValues

  const [form] = Form.useForm()
  const [price_currency] = useState<string>('USD')
  const [vendorsList, setVendorsList] = useState<string[]>([])
  const intl = useIntl()
  const isItemFromServer = !!id
  const [isAddToCartDisabled, setAddToCartDisabled] = useState<boolean>(true)

  useEffect(() => {
    VendorService
      .getForHandleInput()
      .then(vendorsString => {
        setVendorsList(Utils.transformStringToArray(vendorsString))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных вендоров',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
  }, [])

  const onFinish = (product: HandleInputProductType) => {
    if (onFinishHandler) {
      onFinishHandler({
        ...product,
        input_by_handle: true,
      })
    }
    form.resetFields()
  }

  const onChange = (e: FormEvent<HTMLFormElement>) => {
    const inputElement = e.nativeEvent.target as HTMLInputElement
    if (onChangeHandler) {
      onChangeHandler(inputElement.id, inputElement.value)
    }

    const isPositionNameEmpty = !form.getFieldValue('name')?.trim()
    setAddToCartDisabled(isPositionNameEmpty)
  }

  const onSelect = (nameValue: string, value: string) => {
    if (onChangeHandler) {
      onChangeHandler(nameValue, value)
    }
  }

  return (
    <Card
      bordered
    >
      <div className='handle-input-basket-section side-handle-input-basket-section'>
        <Form
          form={form}
          onChange={onChange}
          onFinish={onFinish}
          initialValues={{
            name,
            catalogue_id,
            cas_id,
            price,
            price_currency,
            shipping_conditions,
            package_size,
            vendor,
            partner_url,
            count,
            info_from_client,
          }}
        >
          <Row>
            <Col
              className='handle-input-basket-input'
              span={24}
            >
              <Form.Item
                label={intl.messages['sidenav.my_orders.position_name'] as string}
                name='name'
                rules={[{
                  message: intl.messages['sidenav.my_orders.enter_name'] as string,
                  required: true,
                }]}
                required
              >
                <Input
                  placeholder={intl.messages['sidenav.my_orders.enter_name'] as string}
                  disabled={isItemFromServer}
                  required
                />
              </Form.Item>
            </Col>
          </Row>
          <div className='form-item-line'>
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={12}
                lg={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.catalog'] as string}
                  name='catalogue_id'
                  rules={[{
                    message: intl.messages['sidenav.my_orders.catalog_id'] as string,
                  }]}
                >
                  <Input
                    placeholder={intl.messages['sidenav.my_orders.catalog_id'] as string}
                    disabled={isItemFromServer}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={12}
                lg={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label='CAS'
                  name='cas_id'
                  rules={[{
                    message: intl.messages['sidenav.my_orders.enter_cas'] as string,
                  }]}
                >
                  <Input
                    placeholder={intl.messages['sidenav.my_orders.enter_cas'] as string}
                    disabled={isItemFromServer}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.packing']}
                  name='package_size'
                  rules={[{
                    message: intl.messages['sidenav.my_orders.enter_packing'] as string,
                  }]}
                >
                  <Input
                    placeholder={intl.messages['sidenav.my_orders.enter_packing'] as string}
                    disabled={isItemFromServer}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.quantity'] as string}
                  name='count'
                  rules={[{
                    message: intl.messages['sidenav.my_orders.add_quantity'] as string,
                  }]}
                >
                  <Input
                    type='number'
                    disabled={isItemFromServer}
                    min={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={20}
            >
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.vendor']}
                  name='vendor'
                  rules={[{
                    message: intl.messages['sidenav.my_orders.enter_vendor'] as string,
                  }]}
                >
                  <Select
                    disabled={isItemFromServer}
                    showSearch
                    onSelect={(value: string) => onSelect('vendor', value)}
                  >
                    {vendorsList.map((item, key) => (
                      <Option
                        key={key}
                        value={item}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={12}
                className='handle-input-basket-input handle-input-basket-input-desktop'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.link']}
                  name='partner_url' rules={[{
                    message: intl.messages['sidenav.my_orders.enter_link'] as string,
                  }]}
                >
                  <Input
                    disabled={isItemFromServer}
                    placeholder={intl.messages['sidenav.my_orders.enter_link'] as string}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row>
              <Col
                span={24}
                className='handle-input-basket-input'
              >
                <Form.Item
                  label={intl.messages['sidenav.my_orders.user_info']}
                  name='info_from_client'
                >
                  <Input.TextArea
                    placeholder={intl.messages['sidenav.my_orders.enter_user_info'] as string}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            className='form-item-line'
          >
            <Row
              gutter={30}
            >
              {showFinishBtn && (
                <Col
                  span={24}
                  md={24}
                  className='d-flex justify-content-end'
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isAddToCartDisabled}
                  >
                    {intl.messages['sidenav.my_orders.add_item_to_cart']}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Form>
      </div>
    </Card>
  )
}
