import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBasketProducts,
  selectComment,
  selectContactName,
  selectDeliveryCity,
  selectEmail,
  selectOrderName,
  selectPhone,
} from '../../store/basket/selectors'
import {
  setComment,
  setContactName,
  setDefaultOrderName,
  setDeliveryCity,
  setEmail,
  setExchangeRates,
  setOrderName,
  setPhone,
  setProductsListAction,
} from '../../store/basket/reducer'
import { setProductsList } from '../../store/search/reducer'
import OrdersService, { AddOrderAsyncResponse, OrderData } from '../../services/OrdersService'
import UserInfoService from '../../services/UserService'
import ProposalService from '../../services/ProposalService'
import { BASKET_KEY, ORDER_NAME_KEY } from '../../constants/localStorage'
import { useIntl } from 'react-intl'
import { mapProductItemsToPartial } from '../../utils/mappers'
import { ExchangeRates, PartialProductItem } from '../../store/basket/types'
import { selectCurrency } from '../../store/theme/selectors'
import ExchangeRateService, { GetExchangeRatesResponse, mapGetExchangeRatesResponseToExchangeRates } from '../../services/ExchangeRateService'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { selectSelectedPaymentDelay, selectSelectedPaymentType } from '../../store/payment-options/selectors'
import { PaymentDelay, PaymentType, getPaymentContract } from '../../services/PaymentOptionsService'

type UseOrderPersonalInfoType = {
  setShowSuccessOrder: (status: boolean, id: string) => void
}
export const useOrderPersonalInfo = (props: UseOrderPersonalInfoType) => {
  const { setShowSuccessOrder } = props
  const dispatch = useDispatch()
  const city = useSelector(selectDeliveryCity)
  const comment = useSelector(selectComment)
  const email = useSelector(selectEmail)
  const contactName = useSelector(selectContactName)
  const phone = useSelector(selectPhone)
  const basketProducts = useSelector(selectBasketProducts)
  const orderName = useSelector(selectOrderName)
  const currency = useSelector(selectCurrency)
  const [isDataLoading, setDataLoading] = useState<boolean>(false)
  const [isOrderLoading, setOrderLoading] = useState<boolean>(false)
  const selectedPaymentType: PaymentType | null = useSelector(selectSelectedPaymentType)
  const selectedPaymentDelay: PaymentDelay | null = useSelector(selectSelectedPaymentDelay)
  const { messages } = useIntl()

  const partialProductItems: PartialProductItem[] = useMemo(
    () => mapProductItemsToPartial(basketProducts),
    [basketProducts],
  )

  const setPropertyInStore = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: {
      value, name,
    } } = e

    name === 'contactName' && dispatch(setContactName(value))
    name === 'phone' && dispatch(setPhone(value))
    name === 'email' && dispatch(setEmail(value))
    name === 'comment' && dispatch(setComment(value))
    name === 'orderName' && dispatch(setOrderName(value))
  }

  const addOrderHandler = () => {
    setOrderLoading(true)

    OrdersService
      .addOrderAsync({
        orderName,
        name: contactName,
        phone,
        email,
        city,
        comment,
        currency,
        items: partialProductItems,
        payment_contract: getPaymentContract(
          selectedPaymentType,
          selectedPaymentDelay,
        ),
      })
      .then((response: AddOrderAsyncResponse) => response.job_id)
      .then((jobId: string) =>
        OrdersService
          .getJobResult<OrderData>({
            jobId,
          }),
      )
      .then(({ Id: orderId }) => {
        setShowSuccessOrder(true, `${orderId}`)
        resetForms()
        localStorage.setItem(BASKET_KEY, JSON.stringify([]))
        localStorage.removeItem(ORDER_NAME_KEY)
        dispatch(setProductsListAction([]))
        dispatch(setProductsList([]))
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при оформлении заказа',
          description: 'К сожалению, заказ не был оформлен',
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setOrderLoading(false)
      })
  }

  const resetForms = () => {
    dispatch(setContactName(''))
    dispatch(setPhone(''))
    dispatch(setDeliveryCity(''))
    dispatch(setComment(''))
    dispatch(setOrderName(''))
  }

  const isOrderCreationDisabled: boolean =
    !basketProducts.length ||
    [contactName, city, email, phone, orderName].some((value: string) => !value?.trim().length)

  useEffect(() => {
    let isMounted = true

    const setInitialUserData = () => {
      isMounted && setDataLoading(true)

      UserInfoService
        .getUserInfo()
        .then(response => {
          dispatch(setContactName(response.name))
          dispatch(setPhone(response.phone))
          dispatch(setEmail(response.email))
        })
        .then(() => {
          if (!partialProductItems.length) {
            dispatch(setOrderName(''))
            return
          }

          return ProposalService
            .generateOrderName(partialProductItems)
            .then(({ name: generatedOrderName }) => {
              const userOrderName: string | null = localStorage.getItem(ORDER_NAME_KEY)
              const newOrderName: string = userOrderName === null ? generatedOrderName : userOrderName

              dispatch(setDefaultOrderName(generatedOrderName))
              dispatch(setOrderName(newOrderName))
            })
        })
        .then(() =>
          ExchangeRateService
            .getAverageExchangeRates()
            .then((response: GetExchangeRatesResponse) => {
              const exchangeRates: ExchangeRates = mapGetExchangeRatesResponseToExchangeRates(response)
              dispatch(setExchangeRates(exchangeRates))
            }),
        )
        .catch((error: Error) => {
          notification.error({
            message: 'Ошибка при получении данных', // messages['orders.data_loading_error']
            description: error.message,
            duration: 5,
          })
          Sentry.captureException(error)
        })
        .finally(() => {
          isMounted && setDataLoading(false)
        })
    }

    setInitialUserData()

    return () => {
      isMounted = false
    }
  }, [dispatch, messages, partialProductItems])

  return {
    messages,
    comment,
    email,
    contactName,
    phone,
    orderName,
    isOrderCreationDisabled,
    isDataLoading,
    isOrderLoading,

    setPropertyInStore,
    addOrderHandler,
  }
}
