import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import IntlMessage from '../../util-components/IntlMessage'

type AvailabilityInfoProps = {
  isAvailable: boolean;
}

export function AvailabilityInfo({ isAvailable }: AvailabilityInfoProps) {
  return (
    <Tooltip
      title={(
        <IntlMessage
          id={
            isAvailable
              ? 'sidenav.my_orders.product_card.available.tooltip_text'
              : 'sidenav.my_orders.product_card.unavailable.tooltip_text'
          }
        />
      )}
      overlayInnerStyle={{
        textAlign: 'center',
      }}
      placement='left'
    >
      <InfoCircleOutlined />
    </Tooltip>
  )
}
