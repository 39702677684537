import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  LinkToOrder,
  Order,
  getWaitingForPaymentDays,
  mapOrderStatusToBadge,
  unixTimestampToFormattedDate,
} from '../../../../../utils/orders.util'

type OrdersTableProps = {
  orders: Array<Order>;
}

export const OrdersAntTable = (props: OrdersTableProps) => {
  const {orders} = props

  const columns: ColumnsType<Order> = [
    {
      title: 'Номер заявки',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 130,
      align: 'center',
      render: (_, order) => <LinkToOrder orderId={order.id} />,
    },
    {
      title: 'Номер счета',
      dataIndex: 'billId',
      key: 'billId',
      width: 100,
      align: 'center',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      align: 'center',
      render: (_, order) => mapOrderStatusToBadge(order?.status),
    },
    // {
    //   title: 'Ответственный',
    //   dataIndex: 'responsibleUserName',
    //   key: 'responsibleUserName',
    //   width: 300,
    //   align: 'center',
    // },
    {
      title: 'Название заявки',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    // {
    //   title: 'Кол-во позиций',
    //   dataIndex: 'quantity',
    //   key: 'quantity',
    //   align: 'center',
    // },
    {
      title: 'Сумма, ₽',
      dataIndex: 'paymentValue',
      key: 'paymentValue',
      width: 120,
      align: 'center',
      sorter: (a, b) => a.paymentValue - b.paymentValue,
    },
    {
      title: 'Дата поставки',
      dataIndex: 'supplyDate',
      key: 'supplyDate',
      width: 120,
      align: 'center',
      render: (_, order) => unixTimestampToFormattedDate(order.supplyDate),
      sorter: (a, b) => a.supplyDate - b.supplyDate,
    },
    {
      title: 'Ожидание оплаты, дни',
      dataIndex: 'paymentWaiting',
      key: 'paymentWaiting',
      width: 120,
      align: 'center',
      render: (_, order) => {
        const days: number = getWaitingForPaymentDays(order.supplyDate)
        if (!days) {
          return null
        }

        return days
      },
    },
  ]

  return (
    <div className='orders-table'>
      <Table
        columns={columns}
        dataSource={orders}
        bordered
        pagination={{ pageSize: 10 }}
        rowKey='id'
      />
    </div>
  )
}
