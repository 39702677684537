import { Select } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import styles from './AnalogsHeader.module.css'
import { AnalogsSortingProp } from '../../search.type'

const { Option } = Select

type AnalogsHeaderProps = {
  analogsCasId: string;
  sortingProp: string;
  isReverseSorting: boolean;
  setIsReverseSorting: (value: boolean) => void;
  setSortingProp: (value: AnalogsSortingProp) => void;
}

export function AnalogsHeader({
  analogsCasId,
  sortingProp,
  isReverseSorting,
  setIsReverseSorting,
  setSortingProp,
}: AnalogsHeaderProps) {
  const intl = useIntl()

  return (
    <div className={styles['root']}>
      <h4>
        {intl.messages['sidenav.my_orders.analogs'] as string}
        &nbsp;
        (CAS {analogsCasId})
      </h4>
      <div className={styles['sorting']}>
        {sortingProp && (
          <div className={styles['sorting-icons']}>
            <ArrowDownOutlined
              className={cn(
                styles['arrow-icon'],
                { [styles['arrow-icon--down-active']]: !isReverseSorting },
              )}
              onClick={() => setIsReverseSorting(false)}
            />
            <ArrowUpOutlined
              className={cn(
                styles['arrow-icon'],
                { [styles['arrow-icon--up-active']]: isReverseSorting },
              )}
              onClick={() => setIsReverseSorting(true)}
            />
          </div>
        )}
        <Select
          className={styles['sorting-type']}
          defaultValue=''
          value={sortingProp}
          onChange={value => setSortingProp(value as AnalogsSortingProp)}
        >
          <Option value=''>
            {' '}
          </Option>
          <Option
            value='package_size'
          >
            package_size
          </Option>
          <Option
            value='vendor'
          >
            brand
          </Option>
          <Option
            value='partnership_type'
          >
            partnership_type
          </Option>
        </Select>
      </div>
    </div>
  )
}
