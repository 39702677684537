import { Button, List, Text } from '@mantine/core'
import { SurveyStage } from '../SurveyModal'

type IntroStageProps = {
  setStage: (surveyStage: SurveyStage) => void;
  onStartSurveyClick: () => void;
  hasSurveySteps: boolean;
}

export function IntroStage({ hasSurveySteps, setStage, onStartSurveyClick }: IntroStageProps) {
  return (
    <>
      <Text
        size='sm'
        mb={16}
      >
        В опросе мы используем 7-балльную шкалу. Ниже вы можете посмотреть расшифровку:
      </Text>
      <List size={'sm'} listStyleType={'none'}>
        <List.Item>7 - Превосходно, выше всяких похвал</List.Item>
        <List.Item>6 - Всё отлично</List.Item>
        <List.Item>5 - Хорошо</List.Item>
        <List.Item>4 - Нормально, удовлетворительно</List.Item>
        <List.Item>3 - Посредственно, ниже среднего</List.Item>
        <List.Item>2 - Плохо</List.Item>
        <List.Item>1 - Очень плохо, негативный опыт</List.Item>
      </List>
      <div className='flex justify-content-end mt-[30px]'>
        <Button
          onClick={() => setStage(SurveyStage.Welcome)}
          variant='default'
        >
          Назад
        </Button>
        <Button
          onClick={onStartSurveyClick}
          className='ml-[20px]'
          disabled={hasSurveySteps}
        >
          Далее
        </Button>
      </div>
    </>
  )
}
