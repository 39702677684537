import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

export const enum PaymentType {
  Postpaid = 'postpaid',
  Prepaid = 'prepaid',
  PartPrepaid20 = 'part_prepaid_20',
  PartPrepaid30 = 'part_prepaid_30',
  PartPrepaid50 = 'part_prepaid_50',
  PartPrepaid70 = 'part_prepaid_70',
}

export type PaymentContract = {
  conditions: PaymentType;
  n_days: string;
}

export function getPaymentContract(
  paymentType: PaymentType | null,
  paymentDelay: string | null,
): PaymentContract {
  return {
    conditions: paymentType ?? PaymentType.Postpaid,
    n_days: paymentDelay ?? '60',
  }
}

export type PaymentDelay = string;

export type GetPaymentConditionsResponse = {
  conditions: Array<PaymentType>;
  n_days: Array<string>;
  default: {
    conditions: PaymentType,
    n_days: number;
  }
}

export type GetPaymentContractResponse = {
  company_id: string;
  company_name: string;
  conditions: PaymentType;
  n_days: string;
}

type PaymentOptionsServiceType = {
  getPaymentConditions: () => Promise<GetPaymentConditionsResponse>;
  getPaymentContract: () => Promise<GetPaymentContractResponse>;
}

const PaymentOptionsService: PaymentOptionsServiceType = {
  getPaymentConditions: () =>
    axiosInstance.get<never, GetPaymentConditionsResponse>(
      `${API_URL.PROPOSALS}/get-payment-conditions`,
    ),
  getPaymentContract: () =>
    axiosInstance.get<never, GetPaymentContractResponse>(
      `${API_URL.PROPOSALS}/get-payment-contract`,
    ),
}

export default PaymentOptionsService
