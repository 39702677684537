import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Loading from '../../components/shared-components/Loading'
import { APP_PREFIX_PATH } from '../../configs/AppConfig'
import { connect } from 'react-redux'
import { ProposalContent } from './proposal'
import { OrderContent } from './order-edition'
import { SideHandleEditorContent } from '../../content/side-handle-editor'
import { OrderView } from './OrderView/OrderView'

export const AppViews = () => (
  <>
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/new/create`}
          component={() => <OrderContent />}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/new`}
          component={() => <ProposalContent />}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/order/:orderId`}
          component={() => <OrderView />}
          exact
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/orders/active`}
        />
      </Switch>
    </Suspense>
    <SideHandleEditorContent />
  </>
)
// @ts-ignore
const mapStateToProps = ({ auth }) => {
  const { token } = auth
  return { token }
}

// @ts-ignore
export default connect(mapStateToProps, null)(React.memo(AppViews))
