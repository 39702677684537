import { DataTableFiltersData } from '@appscience/data-table'
import { cleanArray } from '@appscience/utils'
import { uniqBy, values } from 'ramda'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SerializedOrderItemsTableFilters } from '../../../../../../hooks/useLocalStorage/types'
import { getStorageObject, useLocalStorage } from '../../../../../../hooks/useLocalStorage/useLocalStorage'
import { selectTableOrderItems } from '../../../../../../store/order-items-table/selectors'
import { selectLocale } from '../../../../../../store/theme/selectors'
import {
  LocaleEnum,
  OrderItem,
  OrderItemStatusEnum,
  getSetsIntersection,
} from '../../../../../../utils/orders.util'
import { MultiSelectListPopover } from '../../../../orders/components/multi-select-list-popover'
import { mapToSelectItem } from '../../../../orders/components/OrdersErpTable/OrdersErpTable.utils'
import { TableOrderItem, TableOrderItemColumnId } from '../OrderErpTable.type'
import { deserializeFilters, mapOrderItemToTableOrderItem, serializeFilters } from '../OrderErpTable.utils'

type StatusData = TitleWithId<string> & {
  status: OrderItemStatusEnum;
}

export interface OrderErpTableFilters {
  status: Array<string>;
}

function getInitialFiltersValues(): OrderErpTableFilters {
  // TODO temporal fix for WMLN-2061
  return {
    status: [],
  }

  const serializedFilters: SerializedOrderItemsTableFilters
    = getStorageObject('orderItemsStage')?.columnFilters ?? {
      status: [],
    }
  const deserializedFilters: OrderErpTableFilters
    = deserializeFilters(serializedFilters)
  const { status } = deserializedFilters

  return {
    status: Array.isArray(status)
      ? status
      : [],
  }
}

function filterByStatus(
  statuses: Array<StatusData>,
  selectedIds: Array<string>,
  tableOrderItems: Array<TableOrderItem>,
): Set<string> | null {
  if (selectedIds.length === 0) {
    return null
  }
  const selectedIdsSet = new Set(selectedIds)
  const selectedStatusesSet = new Set<OrderItemStatusEnum>(
    statuses.reduce((selectedStatuses: Array<OrderItemStatusEnum>, statusData: StatusData) => {
      if (selectedIdsSet.has(statusData.id)) {
        selectedStatuses.push(statusData.status)
      }
      return selectedStatuses
    }, []),
  )

  // see https://appscience.atlassian.net/browse/DT-64?focusedCommentId=14653
  if (
    selectedStatusesSet.has(OrderItemStatusEnum.PreOrder)
    || selectedStatusesSet.has(OrderItemStatusEnum.Procurement)
  ) {
    selectedStatusesSet
      .add(OrderItemStatusEnum.PreOrder)
      .add(OrderItemStatusEnum.Procurement)
  }

  const filteredTableOrderItemIdsSet: Set<string> = new Set()
  tableOrderItems.forEach(orderItem => {
    if (selectedStatusesSet.has(orderItem.status)) {
      filteredTableOrderItemIdsSet.add(orderItem.id)
    }
  })
  return filteredTableOrderItemIdsSet
}

type OrderErpTableFiltersResult = {
  filtersData: DataTableFiltersData<TableOrderItemColumnId>;
  filteredByColumnsTableOrderItems: Array<TableOrderItem>;
}

export function useOrderErpTableFilters(): OrderErpTableFiltersResult {
  const locale: LocaleEnum = useSelector(selectLocale)
  const { setValueToStorage } = useLocalStorage('orderItemsStage')

  const orderItems: Array<OrderItem> = useSelector(selectTableOrderItems)
  const tableOrderItems: TableOrderItem[] = useMemo(
    () =>
      orderItems
        .map(mapOrderItemToTableOrderItem)
    , [orderItems],
  )

  const statuses: Array<StatusData> = useMemo(
    () =>
      uniqBy<TableOrderItem, OrderItemStatusEnum>(
        tableOrderItem => tableOrderItem.status,
        tableOrderItems,
      )
        .filter(({ status }) => status !== OrderItemStatusEnum.PreOrder)
        .map(({ status, statusDescription, statusDescriptionEn }, index) => ({
          id: `${index}`,
          title: locale === LocaleEnum.Ru
            ? statusDescription
            : statusDescriptionEn,
          status,
        }))
    , [locale, tableOrderItems],
  )

  const statusesList = useMemo(() => statuses.map(mapToSelectItem), [statuses])

  const initialFiltersValues = useMemo<OrderErpTableFilters>(() => getInitialFiltersValues(), [])
  const [filtersValues, setFiltersValues] = useState<OrderErpTableFilters>(initialFiltersValues)
  function updateFilterValue<K extends keyof OrderErpTableFilters>(column: K, value: OrderErpTableFilters[K]) {
    setFiltersValues(prev => {
      const newState = { ...prev, [column]: value }
      const serializedFilters: SerializedOrderItemsTableFilters = serializeFilters(newState)

      // TODO temporal fix for WMLN-2061
      // setValueToStorage('columnFilters', serializedFilters)

      return newState
    })
  }

  const initialFilteredColumnsIds = useMemo<TypedObject<TableOrderItemColumnId, Set<string> | null>>(() => ({
    status: filterByStatus(statuses, filtersValues.status, tableOrderItems),
  }), [statuses, filtersValues.status, tableOrderItems])
  const [filteredColumnIds, setFilteredColumnIds] = useState<TypedObject<TableOrderItemColumnId, Set<string> | null>>(
    initialFilteredColumnsIds,
  )
  function updateFilteredColumnIds(column: TableOrderItemColumnId, value: null | Set<string>) {
    setFilteredColumnIds(prev => ({ ...prev, [column]: value }))
  }

  const filtersData: DataTableFiltersData<TableOrderItemColumnId> = {
    'status': {
      filterActive: filtersValues['status'].length > 0,
      dropdown: ({ setActive, closeFn }) => <MultiSelectListPopover
        selectedIds={filtersValues['status']}
        data={statusesList}
        saveButton={{
          onClick: ids => {
            updateFilteredColumnIds('status', filterByStatus(statuses, ids, tableOrderItems))
            updateFilterValue('status', ids.length > 0 ? ids : [])
            setActive(ids.length > 0)
            closeFn()
          },
        }}
        listClassName='max-h-[300px]'
        className='w-[280px]'
      />,
      // searchable
    },
  }

  const idsSets: Array<Set<string>> = cleanArray(values(filteredColumnIds)) || []
  if (!idsSets.length) {
    return {
      filtersData,
      filteredByColumnsTableOrderItems: tableOrderItems,
    }
  }

  const visibleRowIds: Set<string> = getSetsIntersection<string>(idsSets)
  // intersectionSets

  return {
    filtersData,
    filteredByColumnsTableOrderItems: tableOrderItems.filter(order => visibleRowIds.has(order.id)),
  }
}