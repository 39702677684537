import { AutoComplete, Col, Input, Row } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAutoCompleteDropdown } from './SearchField.hook'
import styles from './SearchField.module.css'

const { Search } = Input

type SearchFieldProps = {
  searchOptions: Array<DefaultOptionType> | undefined;
  searchInput: string;
  isDropdownVisible: boolean;
  isSearchLoading: boolean;
  onChooseSearchLabel: (value: string) => void;
  onSearchInput: (value: string) => void;
  hideDropdown: () => void;
  onSearch: () => void;
}

export function SearchField({
  searchOptions,
  searchInput,
  isDropdownVisible,
  isSearchLoading,
  onChooseSearchLabel,
  onSearchInput,
  hideDropdown,
  onSearch,
}: SearchFieldProps) {
  const intl = useIntl()
  const {
    maxDropdownHeight,
    autoCompleteRef,
  } = useAutoCompleteDropdown({
    hideDropdown,
  })

  const onSearchKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!searchInput.trim() && !isDropdownVisible && e.key === 'ArrowDown') {
      onSearchInput(searchInput)
    }
  }, [onSearchInput, searchInput, isDropdownVisible])

  const onSearchFocus = useCallback(() => {
    if (!searchInput.trim()) {
      return
    }

    onSearchInput(searchInput)
  }, [searchInput, onSearchInput])

  return (
    <Row
      gutter={16}
      className={styles['root']}
    >
      <Col
        span={24}
        md={24}
        className='gutter-row'
      >
        <AutoComplete
          ref={autoCompleteRef}
          className={styles['auto-complete']}
          options={searchOptions}
          value={searchInput}
          open={isDropdownVisible}
          listHeight={maxDropdownHeight}
          autoFocus={false}
          placement='bottomLeft'
          onSelect={onChooseSearchLabel}
        >
          <Search
            className={styles['search']}
            placeholder={intl.messages['sidenav.my_orders.enter_any_word'] as string}
            enterButton={intl.messages['sidenav.my_orders.find'] as string}
            disabled={isSearchLoading}
            autoFocus={false}
            onFocus={onSearchFocus}
            onBlur={hideDropdown}
            onSearch={() => onSearch()}
            onKeyDown={onSearchKeyDown}
            onChange={e => onSearchInput(e.target.value)}
          />
        </AutoComplete>
      </Col>
    </Row>
  )
}
