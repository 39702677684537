import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, Col, Row, Tooltip, Typography } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { ProductType } from '../../../store/search/types'
import 'moment/locale/ru'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'
import CountryFlagIcon from '../CountryFlagIcon'

import { selectCurrency, selectLocale } from '../../../store/theme/selectors'

import { DEFAULT_PRODUCT_PRICE } from '../../../constants/commonConstants'
import { CountryEnum, hasCountryIcon } from '../../../constants/country'
import { AvailabilityInfo } from '../AvailabilityInfo/AvailabilityInfo'
import { selectPaymentOptionsLoading } from '../../../store/payment-options/selectors'
import { Skeleton } from '@mantine/core'
import { LocaleEnum } from '../../../utils/orders.util'

const { Title, Text } = Typography
type ProductCardType = ProductType & {
  addToCart: () => void,
  isDisableBtn: boolean
}

export const ProductCard: React.FC<ProductCardType> = ({
  isDisableBtn,
  name,
  catalogue_id,
  vendor,
  cas_id,
  shipping_group_id,
  package_size,
  availability_flag,
  partner_url,
  delivery_period,
  delivery_period_en,
  country_of_purchase = '',
  currency_prices = [],
  brand,
  addToCart,
}) => {
  const [expandBottom] = useState<boolean>(false)
  const currentCurrency = useSelector(selectCurrency)
  const isPaymentOptionsLoading: boolean = useSelector(selectPaymentOptionsLoading)
  const locale: LocaleEnum = useSelector(selectLocale)
  const intl = useIntl()

  const currentPrice = currency_prices
    .find(({ currency }) => currency === currentCurrency)
    ?.price ?? DEFAULT_PRODUCT_PRICE
  const titleText = parse(`<span>${name}</span>`)
  const countryOfPurchase: string = (hasCountryIcon(country_of_purchase) && country_of_purchase) || ''
  const availabilityText: string | undefined = useMemo(() => {
    if (!availability_flag) {
      return intl.messages['sidenav.my_orders.product_card.unavailable'] as string
    }

    if (!delivery_period) {
      return intl.messages['sidenav.my_orders.product_card.available'] as string
    }

    return locale === LocaleEnum.Ru
      ? delivery_period
      : delivery_period_en
  }, [availability_flag, delivery_period, delivery_period_en, intl.messages, locale])

  const formattedPrice: string = currentPrice
    ? `${currentPrice} ${currentCurrency}`
    : intl.messages['sidenav.basket.by_request'] as string

  const SubmitButton = <Button
    type='primary'
    disabled={isDisableBtn || isPaymentOptionsLoading}
    ghost
    className='ml-3'
    style={{ minWidth: 130 }}
    onClick={addToCart}
  >
    <Skeleton
      style={{
        display: 'inline',
      }}
      radius='xs'
      animate
      visible={isPaymentOptionsLoading}
    >
      {formattedPrice}
    </Skeleton>
    <ShoppingCartOutlined
      style={{
        fontSize: 17,
        transform: 'translateY(1px)',
        marginLeft: 3,
      }}
    />
  </Button>

  return (
    <Card className='product-card'>
      <div className='card-holder'>
        <Row
          justify='space-between'
          className='mb-3'
        >
          <Col
            span={24}
            md={8}
            lg={10}
          >
            <Title level={4}>
              {partner_url
                ? <a
                  href={partner_url}
                  target='_blank'
                  className='text-dark'
                >
                  {titleText}
                </a>
                : titleText
              }
            </Title>
          </Col>
          <Col
            span={24}
            md={16}
            lg={12}
            className='justify-content-between justify-content-md-end d-flex align-items-center'
          >
            <Text className='text-info font-weight-bolder product-top-label'>
              {vendor}
            </Text>
            {brand && brand !== vendor && (
              <Text className='text-info font-weight-bolder product-top-label ml-2'>
                {brand}
              </Text>
            )}
            {countryOfPurchase && (
              <div
                className='ml-2'
                style={{ minWidth: 16, width: 16, display: 'inline-block' }}
              >
                <CountryFlagIcon countryCode={countryOfPurchase as CountryEnum} />
              </div>
            )}
            <div className='d-flex flex-column align-items-center'>
              {!!currentPrice && SubmitButton}
              {!currentPrice &&
                <Tooltip
                  title={intl.messages['sidenav.basket.manual_position_processing_required'] as string}
                  color='white'
                  overlayInnerStyle={{
                    color: 'rgb(26, 51, 83)',
                    textAlign: 'center',
                  }}
                  overlayStyle={{ maxWidth: 175 }}
                >
                  {SubmitButton}
                </Tooltip>
              }
              <div className='d-flex align-items-center ml-3 mt-1'>
                <Text
                  style={{ maxWidth: 150 }}
                  className={`mr-1 font-weight-bold rounded product-top-label text-center ${availability_flag ? 'text-success' : 'text-gray-light opacity-0-5'}`}
                >
                  {availabilityText}
                </Text>
                <AvailabilityInfo
                  isAvailable={availability_flag}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            sm={12}
            md={10}
          >
            <Row>
              <Col span={24}>
                <Text
                  type='secondary'
                  style={{ fontWeight: 400 }}
                  className='mr-2 d-block mb-2'
                >
                  <span className='mr-2'>
                    {intl.messages['sidenav.my_orders.product_card.catalogue_id']}:
                  </span>
                  <b className='text-dark'>
                    {catalogue_id}
                  </b>
                </Text>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            sm={12}
            md={6}
          >
            <Row>
              <Col
                span={24}
                className=' pl-0'
              >
                <Text
                  type='secondary'
                  style={{ fontWeight: 400, minWidth: 250 }}
                  className='mr-2 d-block mb-2'
                >
                  <span className='mr-2 ml-0 ml-md-3'>
                    {intl.messages['sidenav.my_orders.product_card.cas']}:
                  </span>
                  <b className='text-dark'>
                    {cas_id}
                  </b>
                </Text>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            md={8}
            className='text-left text-md-right d-none d-md-block'
          >
            <Row>
              <Col span={24}>
                <Text
                  type='secondary'
                  style={{ fontWeight: 400 }}
                  className='mr-2 d-block mb-2'
                >
                  <span className='mr-2'>
                    {intl.messages['sidenav.my_orders.packing']}:
                  </span>
                  <b className='text-dark'>
                    {package_size}
                  </b>
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={expandBottom ? {} : { height: 10, marginTop: -20 }}>
          <Col
            span={24}
            className='d-flex justify-content-between align-content-center'
          >
            <div className='prop-item mb-2'>
              {expandBottom && <>
                <Text
                  type='secondary'
                  style={{ fontWeight: 400 }}
                  className='mr-3'
                >
                  {intl.messages['sidenav.my_orders.shipping_class']}
                </Text>
                <Text
                  strong
                  style={{ fontWeight: 500 }}
                >
                  {shipping_group_id ? shipping_group_id : '---'}
                </Text>
              </>
              }
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  )
}
