import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize'

const DROPDOWN_TOP_OFFSET = 200

type AutoCompleteRefCurrentType = {
  blur: () => void;
  focus: () => void;
  scrollTo: () => void;
}
type AutoCompleteRefType = React.Ref<AutoCompleteRefCurrentType> | undefined

type UseAutoCompleteDropdownProps = {
  hideDropdown: () => void;
}
type UseAutoCompleteDropdownResult = {
  maxDropdownHeight: number;
  autoCompleteRef: AutoCompleteRefType;
}

export function useAutoCompleteDropdown({
  hideDropdown,
}: UseAutoCompleteDropdownProps): UseAutoCompleteDropdownResult {
  const { width, height } = useWindowSize()
  const maxDropdownHeight: number = useMemo(
    () => (height - DROPDOWN_TOP_OFFSET) * 0.6,
    [height],
  )

  const autoCompleteRef: AutoCompleteRefType = useRef(null)
  const blurAutoComplete = useCallback(() => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current?.blur()
    }
  }, [])

  useEffect(() => {
    hideDropdown()
    blurAutoComplete()
  }, [width, height, hideDropdown, blurAutoComplete])

  return {
    maxDropdownHeight,
    autoCompleteRef,
  }
}