import { Button, Select, Typography } from 'antd'
import { useIntl } from 'react-intl'
import styles from './VendorFilter.module.css'

const { Title } = Typography
const { Option } = Select

type VendorFilterProps = {
  defaultVendors: Array<string>;
  searchVendors: Array<string>;
  setVendorsFilter: (value: Array<string>) => void;
  resetVendorsFilter: () => void;
}

export function VendorFilter({
  defaultVendors,
  searchVendors,
  setVendorsFilter,
  resetVendorsFilter,
}: VendorFilterProps) {
  const intl = useIntl()

  return (
    <div className={styles['root']}>
      <Title
        className={styles['title']}
        type='secondary'
        level={4}
      >
        {intl.messages['sidenav.my_orders.vendors']}
      </Title>
      <Select
        className={styles['vendors']}
        mode='multiple'
        defaultValue={defaultVendors}
        value={searchVendors}
        allowClear
        placeholder={intl.messages['sidenav.my_orders.choose_vendors']}
        onChange={setVendorsFilter}
      >
        {defaultVendors.map((item, key) =>
          <Option
            key={key}
            value={item}>
            {item}
          </Option>,
        )}
      </Select>
      {defaultVendors.length !== searchVendors.length &&
        <Button
          className={styles['reset-button']}
          disabled={false}
          onClick={resetVendorsFilter}
        >
          {intl.messages['sidenav.my_orders.choose_all']}
        </Button>
      }
    </div>
  )
}
