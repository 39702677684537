import { useEffect, useState } from 'react'
import UserInfoService, { GetUserInfoResponse } from '../../../../services/UserService'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'

export const useProposalView = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [showMainLayout, setShowMainLayout] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true

    UserInfoService
      .getUserInfo()
      .then(({ company_id: companyId }: GetUserInfoResponse) => {
        if (!isMounted) {
          return
        }
        setShowMainLayout(!!companyId)
      })
      .catch((error: Error) => {
        if (!isMounted) {
          return
        }
        setShowMainLayout(false)
        notification.error({
          message: 'Ошибка при получении данных пользователя',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        if (!isMounted) {
          return
        }
        setLoading(false)
      })

    return () => {
      isMounted = false
    }
  }, [])

  return {
    loading,
    showMainLayout,
  }
}
