import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG, ThemeConfigType } from '../../configs/AppConfig'
import { CURRENCY_KEY } from '../../constants/localStorage'

const initState: ThemeConfigType = {
  ...THEME_CONFIG,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: {...initState},
  reducers: {
    toggleCollapsedNav(state, action) {
      state.navCollapsed = action.payload
    },
    onLocaleChange(state, action) {
      localStorage.setItem('userLocale', action.payload)
      state.locale = action.payload
    },
    onCurrencyChange(state, action) {
      localStorage.setItem(CURRENCY_KEY, action.payload)
      state.currency = action.payload
    },
    onNavTypeChange(state, action) {
      state.navType = action.payload
    },
    onTopNavColorChange(state, action) {
      state.topNavColor = action.payload
    },
    onHeaderNavColorChange(state, action) {
      state.headerNavColor = action.payload
    },
    onMobileNavToggle(state, action) {
      state.mobileNav = action.payload
    },
    onSwitchTheme(state, action) {
      localStorage.setItem('userTheme', action.payload)
      state.currentTheme = action.payload
    },
  },
})

export const themeReducer = themeSlice.reducer
export const {
  toggleCollapsedNav,
  onLocaleChange,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onMobileNavToggle,
  onSwitchTheme,
  onCurrencyChange,
} = themeSlice.actions
