import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_PRODUCT_PRICE } from '../../../../constants/commonConstants'
import { CurrencyEnum } from '../../../../constants/currency'
import { ProductBasketType } from '../../../../store/basket/types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import IntlMessage from '../../../../components/util-components/IntlMessage'

type TotalBlockProps = {
  basketProducts: Array<ProductBasketType>;
  currentCurrency: CurrencyEnum;
  totalDeliveryCost: number;
  hasProductWithoutPrice: boolean;
}

export function TotalBlock({
  basketProducts,
  currentCurrency,
  totalDeliveryCost,
  hasProductWithoutPrice,
}: TotalBlockProps) {
  const intl = useIntl()

  const totalPrice: number = useMemo(() =>
    basketProducts
      .reduce((result: number, { count, currency_prices: currencyPrices }) => {
        const productPrice: number = currencyPrices
          ?.find(({ currency }) => currency === currentCurrency)
          ?.price ?? DEFAULT_PRODUCT_PRICE

        return result + productPrice * count
      }, 0)
  , [basketProducts, currentCurrency])

  return (
    <div className='d-flex justify-content-between pl-3 pr-3 pt-3'>
      <div>
        <h3 className='mb-0'>
          {intl.messages['sidenav.my_orders.basket.total']}:
        </h3>
        <div className='text-gray-light'>
          <span
            style={{
              fontSize: 14,
            }}
          >
            {intl.messages['totalBlock.includingVat']}
          </span>
        </div>
      </div>
      <div>
        <b
          style={{
            fontSize: 16,
          }}
        >
          {`${totalPrice + totalDeliveryCost} ${currentCurrency}`}
        </b>
        {hasProductWithoutPrice && (
          <Tooltip
            title={(
              <IntlMessage
                id='totalBlock.tooltipAdditionalInfo'
              />
            )}
            overlayInnerStyle={{
              textAlign: 'center',
            }}
            placement='left'
            className='ml-1'
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
    </div>
  )
}
