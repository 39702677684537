import { PartialProductItem, ProductBasketType } from '../store/basket/types'
import isNil from 'lodash/isNil'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'

export function mapProductItemsToPartial(
  productItems: ProductBasketType[],
): PartialProductItem[] {
  return productItems
    .map(item => pick(item, [
      'cas_id',
      'catalogue_id',
      'count',
      'id',
      'info_from_client',
      'name',
      'pacjage_size',
      'partner_url',
      'vendor',
    ]))
    .map(excludeNilProps)
}

export function excludeNilProps(obj: object): Partial<object> {
  return pickBy(obj, (_, key) => !isNil(key))
}
