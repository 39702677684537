import { DataTable, DataTableColumnInfo } from '@appscience/data-table'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage/useLocalStorage'
import { selectCurrentTheme } from '../../../../../store/theme/selectors'
import { ThemeColorEnum, getCustomTableTheme } from '../../../../../utils/orders.util'
import { useIdnTableColumns } from './hooks/OrderIdnTableColumns.hook'
import { useIdnTableFilters } from './hooks/OrderIdnTableFilters.hook'
import { useIdnTableInitialData } from './hooks/OrderIdnTableInitialData.hook'
import { TableIntegratedDeliveryNote, TableIntegratedDeliveryNoteColumnId } from './OrderIdnTable.type'

export function OrderIdnTable() {
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme)
  const customTableTheme = useMemo(() => getCustomTableTheme(theme), [theme])
  const columns: Array<
    DataTableColumnInfo<
      TableIntegratedDeliveryNoteColumnId,
      TableIntegratedDeliveryNote
    >
  > = useIdnTableColumns()
  const { filtersData, filteredByColumnsTableIntegratedDeliveryNotes } = useIdnTableFilters()
  const { setValueToStorage } = useLocalStorage('idnTable')
  const initData = useIdnTableInitialData()

  return <DataTable
    data={filteredByColumnsTableIntegratedDeliveryNotes}
    columns={columns}
    pagination={{
      initSize: initData.paginationSize,
      initPageIndex: initData.paginationPage,
      sizesList: [5, 10, 15, 20],
      onPageIndexChange: index => setValueToStorage('paginationPage', index),
      onSizeChange: size => setValueToStorage('paginationSize', size),
    }}
    select={{
      type: 'none',
    }}
    layout={{
      horizontalScroll: true,
    }}
    filtersData={filtersData}
    theme={customTableTheme}
  />
}
