import { Button, Text } from '@mantine/core'
import { useIntl } from 'react-intl'
import { SurveyStage } from '../SurveyModal'

type WelcomeStageProps = {
  currentStep: number;
  setStage: (surveyStage: SurveyStage) => void;
  onCloseModalClick: () => void;
}

export function WelcomeStage({ currentStep, setStage, onCloseModalClick }: WelcomeStageProps) {
  const intl = useIntl()

  return (
    <>
      <Text
        size='sm'
        mb={16}
      >
        {intl.messages['quarterlyPoll.paragraph1']}
      </Text>
      <Text
        size='sm'
        mb={16}
      >
        {intl.messages['quarterlyPoll.paragraph2']}
      </Text>
      <Text
        size='sm'
        mb={16}
      >
        {intl.messages['quarterlyPoll.paragraph3']}
      </Text>
      <Text
        size='sm'
      >
        {intl.messages['quarterlyPoll.paragraph4']}
      </Text>
      <div className='flex justify-content-end mt-[30px]'>
        <Button
          onClick={onCloseModalClick}
          variant='default'
        >
          {intl.messages['quarterlyPoll.button.remindLater']}
        </Button>
        <Button
          onClick={() => setStage(SurveyStage.Intro)}
          className='ml-[20px]'
        >
          {currentStep === 0
            ? intl.messages['quarterlyPoll.button.goPoll']
            : 'Продолжить опрос'
          }
        </Button>
      </div>
    </>
  )
}
