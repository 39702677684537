import fetch from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

// TODO api typings
type AvailabilityServiceType = {
  post: (product_id: string, quantity: number) => Promise<AnyType>
}

export const AvailabilityService: AvailabilityServiceType = {
  post: (product_id: string, quantity: number) => fetch({
    url: `${API_URL.AVAILABILITY}/get_updated`,
    method: 'post',
    data: { product_id, quantity },
  }),
}
