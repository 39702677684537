import {CheckCircleOutlined, FilePdfOutlined, HistoryOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import {APP_PREFIX_PATH} from '../configs/AppConfig'

// const dashBoardNavTree = [{
//   key: 'home',
//   path: `${APP_PREFIX_PATH}/home`,
//   title: 'home',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// }]

const dashBoardNavTree = [
  {
    key: 'new',
    title: 'sidenav.orders',
    submenu: [
      {
        key: 'new',
        path: `${APP_PREFIX_PATH}/new`,
        title: 'sidenav.orders.new',
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.my_orders',
    breadcrumb: false,
    submenu: [
      {
        key: 'orders-leads',
        path: `${APP_PREFIX_PATH}/orders/proposals`,
        title: 'sidenav.my_orders.proposals',
        icon: FilePdfOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'orders-active',
        path: `${APP_PREFIX_PATH}/orders/active`,
        title: 'sidenav.my_orders.active',
        icon: CheckCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'orders-archive',
        path: `${APP_PREFIX_PATH}/orders/archive`,
        title: 'sidenav.my_orders.archive',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  }]


const navigationConfig = [
  ...dashBoardNavTree,
]

export default navigationConfig
