import { CurrencyEnum } from '../constants/currency'
import { CURRENCY_KEY } from '../constants/localStorage'
import {
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from '../constants/ThemeConstant'
import { LocaleEnum, ThemeColorEnum } from '../utils/orders.util'

import { env } from './EnvironmentConfig'

export const APP_NAME = 'AppScience'
export const API_BASE_URL = env?.API_ENDPOINT_URL
export const APP_PREFIX_PATH = ''
export const AUTH_PREFIX_PATH = '/auth'

export enum DirectionEnum {
  RightToLeft = 'rtl',
  LeftToRight = 'ltr',
}

export type ThemeConfigType = {
  navCollapsed: boolean;
  sideNavTheme: string;
  locale: LocaleEnum;
  navType: string;
  topNavColor: string;
  headerNavColor: string;
  mobileNav: boolean;
  currentTheme: ThemeColorEnum;
  direction: DirectionEnum;
  isMobile: boolean;
  currency: CurrencyEnum;
}

const navCollapsed: number = +(localStorage.getItem('lk_isCollapsedSideNav') || '0')

export const THEME_CONFIG: ThemeConfigType = {
  navCollapsed: !!(navCollapsed - 0) || false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: (localStorage.getItem('userLocale') as LocaleEnum) ?? LocaleEnum.Ru,
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: (localStorage.getItem('userTheme') as ThemeColorEnum) ?? ThemeColorEnum.Light,
  direction: DirectionEnum.LeftToRight,
  isMobile: false,
  currency: (localStorage.getItem(CURRENCY_KEY) as CurrencyEnum) ?? CurrencyEnum.Rub,
}
