import { RootReducer } from './types'
import { combineReducers } from 'redux'
import { authReducer } from './auth/reducer'
import { themeReducer } from './theme/reducer'
import { basketReducer } from './basket/reducer'
import { searchReducer } from './search/reducer'
import { generateReducer } from './generate-table/reducer'
import { ordersTableReducer } from './orders-table/reducer'
import { orderItemsTableReducer } from './order-items-table/reducer'
import { companiesReducer } from './companies/reducer'
import { idnTableReducer } from './idn-table/reducer'
import { paymentOptionsReducer } from './payment-options/reducer'
import { userInfoReducer } from './user-info/reducer'

export const rootReducer = combineReducers<RootReducer>({
  auth: authReducer,
  theme: themeReducer,
  basket: basketReducer,
  search: searchReducer,
  generate: generateReducer,
  ordersTable: ordersTableReducer,
  orderItemsTable: orderItemsTableReducer,
  companies: companiesReducer,
  idnTable: idnTableReducer,
  paymentOptions: paymentOptionsReducer,
  userInfo: userInfoReducer,
})
