import { FireFilled } from '@ant-design/icons'

type Props = {
  isOn: boolean;
}

export function ExpressIcon({ isOn }: Props) {
  return (
    <FireFilled
      className={isOn ? 'text-danger' : 'text-gray-light opacity-0-5'}
      style={{
        fontSize: 21,
        cursor: 'pointer',
      }}
    />
  )
}
