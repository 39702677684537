import { DataTable, DataTableColumnInfo, DataTableSearchFn } from '@appscience/data-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectCurrentTheme, selectLocale } from '../../../../../store/theme/selectors'
import { LocaleEnum, Order, ThemeColorEnum, getCustomTableTheme } from '../../../../../utils/orders.util'
import { OrderItemsTableGlobalFiltersSection } from './content/OrderItemsTableGlobalFiltersSection'
import { useOrderErpTableColumns } from './hooks/OrderErpTableColumns.hook'
import { useOrderErpTableExtraActions } from './hooks/OrderErpTableExtraActions.hook'
import { useOrderErpTableFilters } from './hooks/OrderErpTableFilters.hook'
import { useGlobalFilteredTableOrderItems, useOrderTableSearchFn } from './hooks/OrderErpTableGlobalFilters.hook'
import { useOrderErpTableInitialData } from './hooks/OrderErpTableInitialData.hook'
import { TableOrderItem, TableOrderItemColumnId } from './OrderErpTable.type'
import { getOrderItemPropertyToCSVFn } from './OrderErpTable.utils'

type OrderErpTableProps = {
  order: Order;
}

export function OrderErpTable({ order }: OrderErpTableProps) {
  const theme: ThemeColorEnum = useSelector(selectCurrentTheme)
  const customTableTheme = useMemo(() => getCustomTableTheme(theme), [theme])
  const intl = useIntl()
  const locale: LocaleEnum = useSelector(selectLocale)
  const columns: Array<DataTableColumnInfo<TableOrderItemColumnId, TableOrderItem>> = useOrderErpTableColumns(order)
  const { filtersData, filteredByColumnsTableOrderItems } = useOrderErpTableFilters()
  const globalFilteredTableOrderItems: Array<TableOrderItem> = useGlobalFilteredTableOrderItems(filteredByColumnsTableOrderItems)
  const searchFn: DataTableSearchFn<TableOrderItem> = useOrderTableSearchFn()
  const { page, pageSize, setPage, setPageSize } = useOrderErpTableInitialData(globalFilteredTableOrderItems.length)

  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([])
  const extraActions = useOrderErpTableExtraActions(columns, selectedRowIds, globalFilteredTableOrderItems)

  return <DataTable
    data={globalFilteredTableOrderItems}
    columns={columns}
    pagination={{
      initPageIndex: page,
      initSize: pageSize,
      sizesList: [5, 10, 15, 20],
      onPageIndexChange: newPage => setPage(newPage),
      onSizeChange: newPageSize => setPageSize(newPageSize),
    }}
    select={{
      type: 'multi',
      selectedRowIds,
      onChange: ids => setSelectedRowIds(ids),
    }}
    layout={{ horizontalScroll: true }}
    filtersData={filtersData}
    searchData={{ searchFn }}
    exportCsv={{
      headerAccessor: columnId => intl.messages[`orderTable.columns.${columnId}`] as string,
      contentAccessor: getOrderItemPropertyToCSVFn(locale),
    }}
    actionsSection={{
      subsection: <OrderItemsTableGlobalFiltersSection />,
      className: 'justify-end items-end',
    }}
    theme={customTableTheme}
    extraActions={extraActions}
  />
}
