import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectCompaniesState = (state: RootState) => state.companies

export const selectCompanies = createSelector(selectCompaniesState, state => state.companies)

export const selectSelectedCompanyId = createSelector(selectCompaniesState, state => state.selectedCompanyId)

export const selectSelectedCompanyName = createSelector(
  selectCompaniesState,
  state => state.companies.find(({ id }) => id === state?.selectedCompanyId)?.name,
)

export const selectUserRole = createSelector(selectCompaniesState, state => state.userRole)
