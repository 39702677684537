import { Button, Group, LoadingOverlay, MantineColor, Modal, Paper, ScrollArea, Slider, Stepper, Text, Textarea } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from '@mantine/form'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { selectUserInfo } from '../../../store/user-info/selectors'
import { UserInfo } from '../../../store/user-info/reducer'
import { useSelector } from 'react-redux'
import { SurveyService } from '../../../services'
import { Survey, SurveyStep, SurveyStepItemType } from '../../../services/SurveyService'
import { SurveyNotification } from './SurveyNotification'
import { WelcomeStage } from './stages/WelcomeStage'
import { IntroStage } from './stages/IntroStage'

export enum SurveyStage {
  Welcome,
  Intro,
  Survey_,
  Finish,
}

function getCompletedSurveySteps(surveySteps: Array<SurveyStep>): Array<SurveyStep> {
  return surveySteps.map(surveyStep => ({
    ...surveyStep,
    is_completed: true,
  }))
}

function getIncompletedSurveySteps(surveySteps: Array<SurveyStep>): Array<SurveyStep> {
  return surveySteps.map(surveyStep => ({
    ...surveyStep,
    spend_time_ms: 0,
    is_completed: false,
  }))
}

function getPureSurveySteps(surveySteps: Array<SurveyStep>): Array<SurveyStep> {
  return surveySteps.map(surveyStep => ({
    ...surveyStep,
    is_completed: false,
    spend_time_ms: 0,
    items: surveyStep.items.map(item => ({
      ...item,
      value: '',
    })),
  }))
}

function getFilledSurveyStep(surveyStep: SurveyStep): SurveyStep {
  return {
    ...surveyStep,
    items: surveyStep.items.map(item => ({
      ...item,
      value: item.type === SurveyStepItemType.Select
        ? (`${item.value}` || '4')
        : item.value,
    })),
  }
}

const SLIDER_COLOR: Record<number, MantineColor> = {
  1: 'pink',
  2: 'grape',
  3: 'violet',
  4: 'indigo',
  5: 'blue',
  6: 'cyan',
  7: 'green',
}

function getSliderColor(sliderValue: number): MantineColor {
  if (!sliderValue) {
    return 'gray'
  }

  return SLIDER_COLOR[sliderValue] || 'gray'
}

export function SurveyModal() {
  const [isSurveyModalOpened, { open: openSurveyModal, close: closeSurveyModal }] = useDisclosure(false)
  const [stage, setStage] = useState<SurveyStage>(SurveyStage.Welcome)
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [startTime, setStartTime] = useState(0)
  const userInfo: UserInfo | null = useSelector(selectUserInfo)
  const hasSurvey = Boolean(userInfo?.show_feedback_form)
  const [isSurveyAvailable, setIsSurveyAvailable] = useState(hasSurvey)

  useEffect(() => {
    if (isSurveyAvailable) {
      openWelcomeStage()
    }
  }, [])

  const form = useForm<Survey>({
    initialValues: {
      steps: [
        {
          name: '',
          description: '',
          spend_time_ms: 0,
          is_completed: false,
          items: [
            {
              name: '',
              description: '',
              type: SurveyStepItemType.Text,
              value: '',
              required: true,
              select_value_message: null,
            },
          ],
        },
      ],
      meta_info: {
        is_completed: false,
      },
    },

    validate: {
      steps: {
        items: {
          value: (val, values, path) => {
            const currentStepIndex = parseInt(path.split('.')[1], 10)
            const currentItemIndex = parseInt(path.split('.')[3], 10)
            // @ts-ignore
            const isRequired = values.steps[currentStepIndex].items[currentItemIndex].required
            // @ts-ignore
            const isSlider = values.steps[currentStepIndex].items[currentItemIndex].type === SurveyStepItemType.Select

            return !isSlider && val === '' && currentStep > currentStepIndex - 1 && isRequired
              ? 'Это поле обязательно к заполнению'
              : null
          },
        },
      },
    },
  })

  const modalTitle = useMemo(() => {
    switch (stage) {
      case SurveyStage.Welcome:
        return 'Помогите нам стать лучше'
      case SurveyStage.Intro:
        return 'Индекс удовлетворенности клиентов'
      case SurveyStage.Survey_:
        return form.values.steps[currentStep]?.name || ''
      case SurveyStage.Finish:
        return 'Спасибо, что помогли нам стать лучше!'
    }
  }, [stage, currentStep, form.values.steps])

  const MARKS: Array<{ value: number, label: string }> =
    Array(7).fill(null)
      .map((_, index) => ({ value: index + 1, label: `${index + 1}` }))

  const completeSurvey = useCallback(() => {
    setStage(SurveyStage.Finish)
    setIsSurveyAvailable(false)
    form.reset()
  }, [form])

  const onSubmit = (prevStep: number, nextStep: number) => {
    setIsLoading(true)

    if (prevStep > nextStep) {
      if (prevStep === 0) {
        setStage(SurveyStage.Intro)
        setIsLoading(false)
        return
      }

      const completedSteps: Array<SurveyStep> =
        getCompletedSurveySteps(form.values.steps.slice(0, nextStep))
      const restSteps: Array<SurveyStep> = form.values.steps.slice(nextStep)

      form.setValues({
        steps: completedSteps.concat(getIncompletedSurveySteps(restSteps)),
      })

      const stepsToSave: Array<SurveyStep> =
        completedSteps.concat(getPureSurveySteps(restSteps))

      SurveyService
        .updateSurvey({
          steps: stepsToSave,
          meta_info: { is_completed: false },
        })
        .then(() => {
          setCurrentStep(nextStep)
          setStartTime(Date.now())
        })
        .catch((error: Error) => {
          notification.error({
            message: 'Ошибка при обновлении опроса',
            description: error.message,
            duration: 5,
          })
          Sentry.captureException(error)
        })
        .finally(() => {
          setIsLoading(false)
        })

      return
    }

    const formValidationResult = form.validate()
    if (formValidationResult.hasErrors) {
      setIsLoading(false)
      return
    }

    const completedSteps: Array<SurveyStep> = [
      ...getCompletedSurveySteps(form.values.steps.slice(0, prevStep)),
      getFilledSurveyStep({
        ...form.values.steps[prevStep],
        is_completed: true,
        spend_time_ms: Date.now() - startTime,
      }),
    ]
    const restSteps: Array<SurveyStep> = form.values.steps.slice(prevStep + 1)
    form.setValues({
      steps: completedSteps.concat(restSteps),
    })

    const stepsToSave: Array<SurveyStep> =
      completedSteps.concat(getPureSurveySteps(restSteps))
    const isLastStep = nextStep === form.values.steps.length

    SurveyService
      .updateSurvey({
        steps: stepsToSave,
        meta_info: {
          is_completed: isLastStep,
        },
      })
      .then(() => {
        setCurrentStep(prevStep + 1)
        setStartTime(Date.now())
        if (isLastStep) {
          completeSurvey()
        }
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при попытке отправить запрос',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const openWelcomeStage = useCallback(() => {
    setStage(SurveyStage.Welcome)
    openSurveyModal()
  }, [openSurveyModal])

  const onStartSurveyClick = useCallback(() => {
    setIsLoading(true)

    SurveyService
      .getSurvey()
      .then(({ steps }: Survey) => {
        const firstIncompleteStep = steps.findIndex(surveyStep => !surveyStep.is_completed)
        if (firstIncompleteStep === -1) {
          setIsLoading(false)
          completeSurvey()
          return
        }
        setCurrentStep(firstIncompleteStep)
        setStage(SurveyStage.Survey_)
        form.setValues({ steps })
        setIsLoading(false)
        setStartTime(Date.now())
      })
      .catch((error: Error) => {
        notification.error({
          message: 'Ошибка при получении данных об опросе',
          description: error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
  }, [form, completeSurvey])

  const onSliderChange = useCallback((value: number, stepIndex: number, itemIndex: number) => {
    form.setFieldValue(`steps.${stepIndex}.items.${itemIndex}.value`, `${value || 4}`)
  }, [form])

  const getSliderValue = (stepIndex: number, itemIndex: number) => {
    const formValue = form.getInputProps(`steps.${stepIndex}.items.${itemIndex}.value`).value
    return +(formValue) || 4
  }

  return (
    <>
      {isSurveyAvailable && (
        <SurveyNotification
          onClick={openWelcomeStage}
        />
      )}
      <Modal
        opened={isSurveyModalOpened}
        onClose={closeSurveyModal}
        centered
        size={stage === SurveyStage.Finish ? '35%' : '60%'}
        title={modalTitle}
        className='modalPoll'
        zIndex={999}
        styles={{
          title: {
            fontSize: 22,
            fontWeight: 500,
          },
        }}
        withCloseButton={stage !== SurveyStage.Finish}
      >
        <LoadingOverlay
          visible={isLoading}
        />

        {stage === SurveyStage.Welcome && (
          <WelcomeStage
            currentStep={currentStep}
            setStage={setStage}
            onCloseModalClick={closeSurveyModal}
          />
        )}
        {stage === SurveyStage.Intro && (
          <IntroStage
            onStartSurveyClick={onStartSurveyClick}
            hasSurveySteps={!form.values.steps.length}
            setStage={setStage}
          />
        )}
        {stage === SurveyStage.Survey_ && (
          <form>
            <Stepper
              active={currentStep}
              onStepClick={clickStep => onSubmit(currentStep, clickStep)}
              breakpoint='sm'
              iconSize={28}
              mb={16}
              ml={5}
              mr={5}
              size='xs'
            >
              {form.values.steps.map((_, index) => (
                <Stepper.Step
                  key={index}
                  allowStepSelect={currentStep > index}
                  title={currentStep > index ? (form.values.steps[index]?.name || '') : ''}
                />
              ))}
              <Stepper.Completed>
                Completed, click back button to get to previous step
              </Stepper.Completed>
            </Stepper>
            {form.values.steps.map((surveyStep, stepIndex) => {
              if (currentStep === stepIndex) {
                return (
                  <ScrollArea
                    key={stepIndex}
                    style={{ height: 440 }}
                    type='auto'
                    pr={0}
                    pl={0}
                  >
                    {surveyStep.description && (
                      <Text size='sm' mb={20} ml={5} mr={5}>
                        {surveyStep.description}
                      </Text>
                    )}
                    {surveyStep.items.map((item, itemIndex) => {
                      if (item.type === SurveyStepItemType.Select) {
                        return (
                          <Paper shadow='sm' p='xs' radius='sm' withBorder mb={10} ml={5} mr={5}>
                            <div key={itemIndex}>
                              <Text size='md' weight={500}>
                                {item.name}
                              </Text>
                              <Text size='xs'>
                                {item.description}
                              </Text>
                              <div className='d-flex align-items-center'>
                                {item.select_value_message && (
                                  <Text size='xs'>
                                    {item.select_value_message.min_value}
                                  </Text>
                                )}
                                <Slider
                                  mt={20}
                                  mb={20}
                                  ml={20}
                                  mr={20}
                                  label={null}
                                  defaultValue={4}
                                  step={1}
                                  min={1}
                                  max={7}
                                  marks={MARKS}
                                  styles={{
                                    root: {
                                      flexGrow: 1,
                                    },
                                  }}
                                  size={7}
                                  color={getSliderColor(getSliderValue(stepIndex, itemIndex))}
                                  value={getSliderValue(stepIndex, itemIndex)}
                                  onChange={value => onSliderChange(value, stepIndex, itemIndex)}
                                />
                                {item.select_value_message && (
                                  <Text size='xs'>
                                    {item.select_value_message.max_value}
                                  </Text>
                                )}
                              </div>
                            </div>
                          </Paper>
                        )
                      }

                      if (item.type === SurveyStepItemType.Text) {
                        return (
                          <Paper shadow='sm' p='sm' radius='sm' withBorder mb={10} ml={5} mr={5}>
                            <Textarea
                              {...form.getInputProps(`steps.${stepIndex}.items.${itemIndex}.value`)}
                              label={(
                                <Text<'span'> component='span' size='md' weight={500} >
                                  {item.name}
                                </Text>
                              )}
                              description={item.description}
                              required={item.required}
                              key={itemIndex}
                              autosize
                              minRows={2}
                            />
                          </Paper>
                        )
                      }

                      return null
                    })}
                  </ScrollArea>
                )
              }

              return null
            })}

            <Group
              position='right'
              pt='md'
              style={{
                boxShadow: '0px -20px 20px -20px rgba(0, 0, 0, 0.07)',
                position: 'relative',
              }}
            >
              <Button
                onClick={() => onSubmit(currentStep, currentStep - 1)}
                variant='default'
              >
                Назад
              </Button>
              <Button
                onClick={() => onSubmit(currentStep, currentStep + 1)}
              >
                {currentStep === form.values.steps.length - 1 ? 'Отправить' : 'Далее'}
              </Button>
            </Group>
          </form>
        )}
        {stage === SurveyStage.Finish && (
          <>
            <Text size='md' mb='lg'>
              Ответственный менеджер вернется к Вам для уточнения информации по обратной связи
            </Text>
            <div className='flex justify-content-end'>
              <Button
                onClick={closeSurveyModal}
              >
                Завершить опрос
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
