import React, { useEffect, useMemo, useRef } from 'react'
import { Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { BasketProductCard } from '../../components/custom-components/basket-product-card'
import { useBasket } from './basket.hook'
import { useIntl } from 'react-intl'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { selectAddToCartBtnDisable, selectDeliveryCostInUsd, selectExchangeRates } from '../../store/basket/selectors'
import { selectCurrency } from '../../store/theme/selectors'
import { CurrencyEnum } from '../../constants/currency'
import { convertCurrencyValue } from '../../services/ExchangeRateService'
import { ExchangeRates, ProductBasketType } from '../../store/basket/types'
import { TotalBlock } from './components/TotalBlock/TotalBlock'
import { PaymentOptions } from './components/PaymentOptions/PaymentOptions'

export function Basket() {
  const isDisabledCount: boolean = useSelector(selectAddToCartBtnDisable)
  const deliveryCostInUsd: number = useSelector(selectDeliveryCostInUsd)
  const exchangeRates: ExchangeRates = useSelector(selectExchangeRates)
  const currentCurrency: CurrencyEnum = useSelector(selectCurrency)

  const {
    basketProducts,
    isBasketLoading,
    isLoaderVisible,

    onCountHandler,
    initState,
    onStartEditHandler,
  } = useBasket()

  const totalDeliveryCost: number = useMemo(
    () => convertCurrencyValue(exchangeRates, currentCurrency, deliveryCostInUsd),
    [exchangeRates, currentCurrency, deliveryCostInUsd],
  )
  const deliveryPartialCost: number = useMemo(() => {
    const count: number = basketProducts.length
    return Math.round(totalDeliveryCost / count)
  }, [basketProducts, totalDeliveryCost])

  useEffect(() => {
    initState()
  }, []) //TODO:refactoring Избавиться от бесконечного ререндеринга при добавлении зависимости. Кажется лучше переписать

  const ref = useRef<Scrollbars>(null)
  const ref_1 = useRef<Scrollbars>(null)
  const ref_2 = useRef<Scrollbars>(null)

  const scrollTrigger = () => {
    ref.current && ref.current.scrollTop(ref.current.getScrollHeight())
    ref_1.current && ref_1.current.scrollTop(ref_1.current.getScrollHeight())
    ref_2.current && ref_2.current.scrollTop(ref_2.current.getScrollHeight())
  }

  const cardRefTop = useRef<HTMLDivElement>(null)
  const cardRefBottom = useRef<HTMLDivElement>(null)

  const intl = useIntl()

  const hasProductWithoutPrice: boolean = useMemo(
    () => basketProducts
      .some((product: ProductBasketType) =>
        !product.id
        || !product?.currency_prices
          .find(({ currency }) => currency === currentCurrency)
          ?.price,
      )
    , [basketProducts, currentCurrency])

  if (isLoaderVisible && isBasketLoading) {
    return (
      <div>
        <Scrollbars
          autoHeight
          ref={ref}
          autoHeightMin={0}
          autoHeightMax={500}
        >
          <Card bordered={true}>
            <div className='d-flex justify-content-center'>
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </div>
          </Card>
        </Scrollbars>
      </div>
    )
  }

  if (isLoaderVisible && !isBasketLoading && basketProducts.length) {
    return (
      <div>
        <h3 className='mb-3'>
          {intl.messages['sidenav.my_orders.basket']}
        </h3>
        <Scrollbars
          ref={ref_1}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={500}
        >
          <div ref={cardRefTop}>
            <Card bordered={true}>
              <div className='test'>
                {basketProducts.map((item, key, itemArray) =>
                  <BasketProductCard
                    {...item}
                    key={key}
                    isDisableCount={isDisabledCount}
                    isLastItem={key === itemArray.length - 1}
                    itemIndex={key}
                    deliveryPartialCost={deliveryPartialCost}
                    scrollTrigger={scrollTrigger}
                    onCount={onCountHandler}
                    onStartEditHandler={onStartEditHandler}
                  />,
                )}
              </div>
            </Card>
          </div>
        </Scrollbars>
        <div
          style={{
            height: 1,
          }}
          className='bg-gray-lighter mt-4'
        > </div>
        <PaymentOptions />
        <div
          style={{
            height: 1,
          }}
          className='bg-gray-lighter'
        > </div>
        <TotalBlock
          basketProducts={basketProducts}
          currentCurrency={currentCurrency}
          totalDeliveryCost={totalDeliveryCost}
          hasProductWithoutPrice={hasProductWithoutPrice}
        />
        <div className='offset-block pb-4'> </div>
      </div>
    )
  }

  if (!isLoaderVisible && basketProducts.length) {
    return (
      <>
        <h3 className='mb-3'>
          {intl.messages['sidenav.my_orders.basket']}
        </h3>
        <div
          ref={cardRefBottom}
          style={{ overflow: 'hidden' }}
        >
          <Scrollbars
            autoHeight
            ref={ref_2}
            autoHeightMin={0}
            autoHeightMax={500}
          >
            <Card
              bordered={true}
              style={{
                overflowY: 'auto',
                maxHeight: 500,
              }}
            >
              <div>
                {basketProducts.map((item, key, itemArray) =>
                  <BasketProductCard
                    {...item}
                    key={key}
                    isDisableCount={isDisabledCount}
                    itemIndex={key}
                    isLastItem={key === itemArray.length - 1}
                    deliveryPartialCost={deliveryPartialCost}
                    scrollTrigger={scrollTrigger}
                    onCount={onCountHandler}
                  />,
                )}
              </div>
            </Card>
          </Scrollbars>
        </div>
        <div className='offset-block pb-4'> </div>
      </>
    )
  }

  return null
}
