import { DataTableSearchFn } from '@appscience/data-table'
import { caseInsensitiveSubstr, isNullable } from '@appscience/utils'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OrderItemsTableGlobalFiltersData } from '../../../../../../store/order-items-table/reducer'
import { selectOrderItemsTableGlobalFilters } from '../../../../../../store/order-items-table/selectors'
import { selectLocale } from '../../../../../../store/theme/selectors'
import { LocaleEnum, getOrderItemStatusTitle, unixTimestampToFormattedDate } from '../../../../../../utils/orders.util'
import { TableOrderItem } from '../OrderErpTable.type'

export function useGlobalFilteredTableOrderItems(tableOrderItems: Array<TableOrderItem>): Array<TableOrderItem> {
  const {
    nameIds = [],
  }: OrderItemsTableGlobalFiltersData = useSelector(selectOrderItemsTableGlobalFilters())

  return useMemo(() => {
    const nameIdsSet = nameIds.length > 0
      ? new Set(nameIds)
      : null

    if ([nameIdsSet].every(isNullable)) {
      return tableOrderItems
    }

    return tableOrderItems
      .filter(({ name }) => isInFilter(name, nameIdsSet))
  }, [tableOrderItems, nameIds])
}

function isInFilter(filterId: string, filteredIdsSet: Set<string> | null): boolean {
  return filteredIdsSet === null || filteredIdsSet.has(filterId)
}

export function useOrderTableSearchFn(): DataTableSearchFn<TableOrderItem> {
  const locale: LocaleEnum = useSelector(selectLocale)

  return useCallback((orderItem: TableOrderItem, search: string) =>
    caseInsensitiveSubstr(orderItem.name, search)
    || caseInsensitiveSubstr(`${orderItem.quantity}`, search)
    || caseInsensitiveSubstr(`${orderItem.totalPrice}`, search)
    || caseInsensitiveSubstr(
      orderItem.statusDescription || getOrderItemStatusTitle(orderItem.status, locale),
      search,
    )
    || caseInsensitiveSubstr(unixTimestampToFormattedDate(orderItem.expectedDeliveryDate), search)
  , [locale])
}
