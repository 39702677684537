import { CancelToken } from 'axios'
import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'
import { ProductType } from '../store/search/types'
import { PaymentContract } from './PaymentOptionsService'

export type GetPartnerProductsRequest = {
  vendors: Array<string>;
  any: string;
  count: number;
  page: number;
  payment_contract: PaymentContract;
}

export type GetPartnerProductsResponse = {
  items_count: number;
  pages_count: number;
  partner_products: ProductType[];
}

type SearchServiceType = {
  getPartnerProducts: (request: GetPartnerProductsRequest, cancelToken: CancelToken) =>
    Promise<GetPartnerProductsResponse>;
}

const SearchService: SearchServiceType = {
  getPartnerProducts: (request: GetPartnerProductsRequest, cancelToken: CancelToken) =>
    axiosInstance.post<never, GetPartnerProductsResponse, GetPartnerProductsRequest>(
      `${API_URL.PARTNER_PRODUCTS}/customers-request`,
      request,
      {
        cancelToken,
      },
    ),
}

export default SearchService
