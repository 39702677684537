import React from 'react'
import { SuggestDropdownItemProps } from '../../search.type'
import { Button } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { selectAddToCartBtnDisable, selectBasketProducts } from '../../../../store/basket/selectors'
import { useSelector } from 'react-redux'
import { SuggestItemContent } from '../../../../components/custom-components/SuggestItemContent'

export const SuggestDropdownItem: React.FC<SuggestDropdownItemProps> = props => {
  const {
    id,
    item,
    theme,
    addToBasketFromSearchResult,
  } = props

  const basketProducts = useSelector(selectBasketProducts)
  const isAddToCartDisabled: boolean = useSelector(selectAddToCartBtnDisable)

  return (
    <div
      key={`${id}`}
      className='search-result-dropdown-wrapper d-flex align-items-center'
    >
      <div className='search-result-dropdown-top-holder'>
        <SuggestItemContent item={item}/>
      </div>
      <div className='search-result-basket-block pl-2'>
        <Button
          className='search-result-dropdown-item-add-to-cart-btn'
          size='small'
          disabled={isAddToCartDisabled}
          onClick={e => addToBasketFromSearchResult(e, item.data, basketProducts)}
        >
          <ShoppingCartOutlined
            className='cart-outline'
            style={{
              fontSize: 15,
            }}
          />
        </Button>
      </div>
      <div
        className={`${theme === 'light' ? 'bg-gray-lighter' : 'bg-gray'}`}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 1,
        }}
      ></div>
    </div>
  )
}
