import React from 'react'
import {useOrderPersonalInfo} from './order-personal-info.hook'
import {OrderPersonalInfoForm} from './order-personal-info-form'
import OrderLoading from './order-loading'

type OrderPersonalInfoFormProps = {
	setShowSuccessOrder: (status : boolean, id: string) => void
}

export const OrderPersonalInfo: React.FC<OrderPersonalInfoFormProps> = props => {
  const {setShowSuccessOrder} = props
  const {
    messages,
    comment,
    email,
    contactName,
    phone,
    isDataLoading,
    isOrderLoading,
    isOrderCreationDisabled,
    orderName,

    setPropertyInStore,
    addOrderHandler,
  } = useOrderPersonalInfo({
    setShowSuccessOrder,
  })

  return (
    <>
      <h3 className='mb-3'>
        {messages['orders.btn.enter_user_info']}
      </h3>
      {isOrderLoading &&
        <OrderLoading
          showMessages
        />
      }
      {isDataLoading &&
        <OrderLoading
          showMessages={false}
        />
      }
      {!isOrderLoading && !isDataLoading &&
        <OrderPersonalInfoForm
          orderName={orderName}
          comment={comment}
          contactName={contactName}
          email={email}
          loading={isOrderLoading || isDataLoading}
          phone={phone}
          isOrderCreationDisabled={isOrderCreationDisabled}
          addOrderHandler={addOrderHandler}
          setPropertyInStore={setPropertyInStore}
        />
      }
    </>
  )
}
