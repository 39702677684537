type TogglerProps = {
  firstOption: string,
  secondOption: string,
  isFirstOptionSelected: boolean,
  isDarkThemeEnabled?: boolean,
  onChange: () => void,
}

export const Toggler: React.FC<TogglerProps> = ({
  firstOption,
  secondOption,
  isFirstOptionSelected,
  isDarkThemeEnabled = false,
  onChange,
}) => (
  <div className={`toggler ${isDarkThemeEnabled ? 'toggler--dark-theme' : ''}`}>
    <div
      className={`toggler__option ${isFirstOptionSelected ? 'toggler__option--selected' : ''}`}
      onClick={onChange}
    >
      {firstOption}
    </div>
    <div
      className={`toggler__option ${isFirstOptionSelected ? '' : 'toggler__option--selected'}`}
      onClick={onChange}
    >
      {secondOption}
    </div>
  </div>
)
