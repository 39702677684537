import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {useSelector} from 'react-redux'
import AppLayout from '../layouts/app-layout'
import AuthLayout from '../layouts/auth-layout'
import AppLocale from '../lang'
import {IntlProvider} from 'react-intl'
import {ConfigProvider} from 'antd'
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from '../configs/AppConfig'
import useBodyClass from '../hooks/useBodyClass'
import Error404 from './errors/404'
import * as themeSelectors from '../store/theme/selectors'
import { usePageViews } from '../hooks/usePageViews/usePageViews'

export const Views = props => {
  usePageViews()

  const {location} = props
  const {selectLocale, selectDirection} = themeSelectors

  const locale = useSelector(selectLocale)
  const direction = useSelector(selectDirection)

  const currentAppLocale = AppLocale[locale]
  useBodyClass(`dir-${direction}`)
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/orders/active' />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location}/>
          </Route>
          <Route path='*' component={Error404}/>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default withRouter(Views)
