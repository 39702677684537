export enum HostnameEnum {
  Prod = 'customers.appscience.pro',
  Stage = 'stage.customers.appscience.pro',
  Stage1 = 'stage1.customers.appscience.pro',
  Stage2 = 'stage2.customers.appscience.pro',
  Stage3 = 'stage3.customers.appscience.pro',
  Test = 'test.customers.appscience.pro',
  Test1 = 'test1.customers.appscience.pro',
  Test2 = 'test2.customers.appscience.pro',
  Test3 = 'test3.customers.appscience.pro',
}

export enum ApiUrlEnum {
  Prod = 'https://api.appscience.pro',
  Stage = 'https://stage.api.appscience.pro',
  Stage1 = 'https://stage1.api.appscience.pro',
  Stage2 = 'https://stage2.api.appscience.pro',
  Stage3 = 'https://stage3.api.appscience.pro',
  Test = 'https://test.api.appscience.pro',
  Test1 = 'https://test1.api.appscience.pro',
  Test2 = 'https://test2.api.appscience.pro',
  Test3 = 'https://test3.api.appscience.pro',
}

export enum EnviromentEnum {
  Prod = 'prod',
  Stage = 'stage',
  Stage1 = 'stage1',
  Stage2 = 'stage2',
  Stage3 = 'stage3',
  Test = 'test',
  Test1 = 'test1',
  Test2 = 'test2',
  Test3 = 'test3',
}

const TEST_ENVIRONMENTS: Array<EnviromentEnum> = [
  EnviromentEnum.Test,
  EnviromentEnum.Test1,
  EnviromentEnum.Test2,
  EnviromentEnum.Test3,
]

const HOSTNAME_TO_ENVIRONMENT: Record<HostnameEnum, EnviromentEnum> = {
  [HostnameEnum.Prod]: EnviromentEnum.Prod,
  [HostnameEnum.Stage]: EnviromentEnum.Stage,
  [HostnameEnum.Stage1]: EnviromentEnum.Stage1,
  [HostnameEnum.Stage2]: EnviromentEnum.Stage2,
  [HostnameEnum.Stage3]: EnviromentEnum.Stage3,
  [HostnameEnum.Test]: EnviromentEnum.Test,
  [HostnameEnum.Test1]: EnviromentEnum.Test1,
  [HostnameEnum.Test2]: EnviromentEnum.Test2,
  [HostnameEnum.Test3]: EnviromentEnum.Test3,
}

const HOSTNAME_TO_API_URL: Record<HostnameEnum, ApiUrlEnum> = {
  [HostnameEnum.Prod]: ApiUrlEnum.Prod,
  [HostnameEnum.Stage]: ApiUrlEnum.Stage,
  [HostnameEnum.Stage1]: ApiUrlEnum.Stage1,
  [HostnameEnum.Stage2]: ApiUrlEnum.Stage2,
  [HostnameEnum.Stage3]: ApiUrlEnum.Stage3,
  [HostnameEnum.Test]: ApiUrlEnum.Test,
  [HostnameEnum.Test1]: ApiUrlEnum.Test1,
  [HostnameEnum.Test2]: ApiUrlEnum.Test2,
  [HostnameEnum.Test3]: ApiUrlEnum.Test3,
}

const HOSTNAME = window.location.hostname as HostnameEnum ?? HostnameEnum.Test
export const ENVIRONMENT = HOSTNAME_TO_ENVIRONMENT[HOSTNAME] ?? EnviromentEnum.Test
export const IS_TEST_ENVIRONMENT = TEST_ENVIRONMENTS.includes(ENVIRONMENT)
export const IS_PROD_ENVIRONMENT = ENVIRONMENT === EnviromentEnum.Prod
const CURRENT_API_URL = HOSTNAME_TO_API_URL[HOSTNAME] ?? ApiUrlEnum.Test

export const API_URL = {
  AVAILABILITY: process.env.REACT_APP_AVAILABILITY_URL || CURRENT_API_URL + '/availability',
  AUTH: process.env.REACT_APP_AUTH_URL || CURRENT_API_URL + '/auth',
  CURRENCIES: process.env.REACT_APP_CURRENCIES_URL || CURRENT_API_URL + '/currencies',
  CUSTOMER_AREA: process.env.REACT_APP_CUSTOMER_AREA_URL || CURRENT_API_URL + '/customer-area',
  EXCEL_CONVERTER: process.env.REACT_APP_EXCEL_CONVERTER_URL || CURRENT_API_URL + '/excel-converter',
  METABASE_EMBEDDING: process.env.REACT_APP_METABASE_EMBEDDING_URL || CURRENT_API_URL + '/metabase_embedding',
  PARTNER_PRODUCTS: process.env.REACT_APP_PARTNER_PRODUCTS_URL || CURRENT_API_URL + '/partner-products',
  PROPOSALS: process.env.REACT_APP_PROPOSALS_URL || CURRENT_API_URL + '/proposals',
  SUGGEST: process.env.REACT_APP_SUGGEST_URL || CURRENT_API_URL + '/suggest',
  TWIN: process.env.REACT_APP_TWIN_URL || CURRENT_API_URL + '/twin',
}
