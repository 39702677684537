import { DataTableFiltersData } from '@appscience/data-table'
import { cleanArray, dateInRange, isNullable } from '@appscience/utils'
import { startOfDay } from 'date-fns'
import { values } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { SerializedOrdersTableFilters } from '../../../../../../hooks/useLocalStorage/types'
import { getStorageObject, useLocalStorage } from '../../../../../../hooks/useLocalStorage/useLocalStorage'
import { selectTableOrders } from '../../../../../../store/orders-table/selectors'
import {
  ORDER_STATUS_GROUP_TO_ORDER_STATUSES,
  Order,
  OrderStatusEnum,
  OrderStatusGroupEnum,
  getSetsIntersection,
  unixTimestampToTimestamp,
} from '../../../../../../utils/orders.util'
import { DateRangePopover } from '../../data-range-popover'
import { MultiSelectListPopover } from '../../multi-select-list-popover'
import { TableOrder, TableOrderColumnId } from '../OrdersErpTable.type'
import { deserializeFilters, mapOrderToTableOrder, mapToSelectItem, serializeFilters } from '../OrdersErpTable.utils'

type StatusData = TitleWithId<string> & {
  status: OrderStatusEnum
}

export interface OrdersErpTableFilters {
  status: Array<string>,
  supplyDate: [Date|null, Date|null],
}

function getInitialFiltersValues(): OrdersErpTableFilters {
  const serializedFilters: SerializedOrdersTableFilters
    = getStorageObject('ordersStage')?.columnFilters ?? {
      status: [],
      supplyDate: [null, null],
    }
  const deserializedFilters: OrdersErpTableFilters
    = deserializeFilters(serializedFilters)
  const {status, supplyDate} = deserializedFilters

  return {
    status: Array.isArray(status)
      ? status
      : [],
    supplyDate: Array.isArray(supplyDate)
      ? supplyDate
      : [null, null],
  }
}

function filterByStatus(
  statuses: Array<StatusData>,
  selectedIds: Array<string>,
  tableOrders: Array<TableOrder>,
): Set<string>|null {
  if (selectedIds.length === 0) {
    return null
  }
  const selectedIdsSet = new Set(selectedIds)
  const selectedStatusesSet = new Set<OrderStatusEnum>(
    statuses.reduce((selectedStatuses: Array<OrderStatusEnum>, statusData: StatusData) => {
      if (selectedIdsSet.has(statusData.id)) {
        selectedStatuses.push(statusData.status)
      }
      return selectedStatuses
    }, []),
  )

  const filteredTableOrderIdsSet: Set<string> = new Set()
  tableOrders.forEach(order => {
    if (selectedStatusesSet.has(order.status)) {
      filteredTableOrderIdsSet.add(order.id)
    }
  })

  return filteredTableOrderIdsSet
}

type OrderTableDateFilterColumnId = Extract<TableOrderColumnId, 'supplyDate'>

function filterByDateColumn(
  column: OrderTableDateFilterColumnId,
  range: [Date|null, Date|null]|null,
  tableOrders: Array<TableOrder>,
): Set<string> | null {
  if (range === null || range[0] === null || range[1] === null) {
    return null
  }

  const filteredOrderIds: Array<string> = tableOrders
    .filter((order: TableOrder) => {
      const timestamp: number = order[column]
      const date: Date = startOfDay(unixTimestampToTimestamp(timestamp || 0))

      return isNullable(timestamp)
        ? null
        : dateInRange(date, range as [Date, Date])
    })
    .map(order => order.id)
  return new Set(filteredOrderIds)
}

type OrdersErpTableFiltersResult = {
  filtersData: DataTableFiltersData<TableOrderColumnId>;
  filteredByColumnsTableOrders: Array<TableOrder>;
}

export function useOrdersErpTableFilters(statusGroup: OrderStatusGroupEnum): OrdersErpTableFiltersResult {
  const intl = useIntl()
  const {setValueToStorage} = useLocalStorage('ordersStage')

  const orders: Array<Order> = useSelector(selectTableOrders)
  const tableOrders: TableOrder[] = useMemo(() =>
    orders
      .map(mapOrderToTableOrder)
      .filter(tableOrder => ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup].includes(tableOrder.status))
  , [orders, statusGroup])

  const statuses: Array<StatusData> = useMemo(() =>
    ORDER_STATUS_GROUP_TO_ORDER_STATUSES[statusGroup]
      .map((status, index) => ({
        id: `${index}`,
        title: intl.messages['orders.status.' + status] as string,
        status,
      }))
  , [intl.messages, statusGroup])
  const statusesList = useMemo(() => statuses.map(mapToSelectItem), [statuses])

  const initialFiltersValues = useMemo<OrdersErpTableFilters>(() => getInitialFiltersValues(), [])
  const [filtersValues, setFiltersValues] = useState<OrdersErpTableFilters>(initialFiltersValues)

  function updateFilterValue<K extends keyof OrdersErpTableFilters>(column: K, value: OrdersErpTableFilters[K]) {
    setFiltersValues(prev => {
      const newState = {...prev, [column]: value}
      const serializedFilters: SerializedOrdersTableFilters = serializeFilters(newState)

      setValueToStorage('columnFilters', serializedFilters)

      return newState
    })
  }

  const initialFilteredColumnsIds = useMemo<TypedObject<TableOrderColumnId, Set<string>|null>>(() => ({
    status: filterByStatus(statuses, filtersValues.status, tableOrders),
    supplyDate: filterByDateColumn('supplyDate', filtersValues.supplyDate, tableOrders),
  }), [filtersValues.status, filtersValues.supplyDate, statuses, tableOrders])
  const [filteredColumnIds, setFilteredColumnIds] = useState<TypedObject<TableOrderColumnId, Set<string>|null>>(
    initialFilteredColumnsIds,
  )
  // TODO FIX - filteredColumnIds are empty after page reload without the setFilteredColumnIds call
  useEffect(() => {
    setFilteredColumnIds(initialFilteredColumnsIds)
  }, [initialFilteredColumnsIds])

  function updateFilteredColumnIds(column: TableOrderColumnId, value: Set<string>|null) {
    setFilteredColumnIds(prev => ({...prev, [column]: value}))
  }
  function confirmDateFilter(column: OrderTableDateFilterColumnId, range: [Date, Date]|null) {
    updateFilteredColumnIds(column, filterByDateColumn(column, range, tableOrders))
    updateFilterValue(column, range === null ? [null, null] : range)
  }

  const filtersData: DataTableFiltersData<TableOrderColumnId> = {
    'status': {
      filterActive: filtersValues['status'].length > 0,
      dropdown: ({setActive, closeFn}) => <MultiSelectListPopover
        selectedIds={filtersValues['status']}
        data={statusesList}
        saveButton={{
          onClick: ids => {
            updateFilteredColumnIds('status', filterByStatus(statuses, ids, tableOrders))
            updateFilterValue('status', ids.length > 0 ? ids : [])
            setActive(ids.length > 0)
            closeFn()
          },
        }}
        listClassName='max-h-[300px]'
        className='w-[280px]'
      />,
      // searchable
    },
    'supplyDate': {
      filterActive: cleanArray(filtersValues['supplyDate']).length > 0,
      dropdown: props => <DateRangePopover
        value={filtersValues['supplyDate']}
        confirmValue={range => {
          confirmDateFilter('supplyDate', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
    },
  }

  const idsSets: Array<Set<string>> = cleanArray(values(filteredColumnIds)) || []
  if (!idsSets.length) {
    return {
      filtersData,
      filteredByColumnsTableOrders: tableOrders,
    }
  }

  const visibleRowIds: Set<string> = getSetsIntersection<string>(idsSets)
  // intersectionSets

  return {
    filtersData,
    filteredByColumnsTableOrders: tableOrders.filter(order => visibleRowIds.has(order.id)),
  }
}