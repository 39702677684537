import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectPaymentOptionsState = (state: RootState) => state.paymentOptions

export const selectPaymentTypes = createSelector(selectPaymentOptionsState, state => state.paymentTypes)

export const selectDefaultPaymentType = createSelector(selectPaymentOptionsState, state => state.defaultPaymentType)

export const selectSelectedPaymentType = createSelector(selectPaymentOptionsState, state => state.selectedPaymentType)

export const selectPaymentDelays = createSelector(selectPaymentOptionsState, state => state.paymentDelays)

export const selectDefaultPaymentDelay = createSelector(selectPaymentOptionsState, state => state.defaultPaymentDelay)

export const selectSelectedPaymentDelay = createSelector(selectPaymentOptionsState, state => state.selectedPaymentDelay)

export const selectPaymentOptionsLoading = createSelector(selectPaymentOptionsState, state => state.isLoading)
