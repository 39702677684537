import { MailOutlined } from '@ant-design/icons'
import { ActionIcon, Badge, Popover, Tooltip } from '@mantine/core'
import React, { useCallback, useRef, useState } from 'react'
import { OrderResponsiblePerson } from '../../../../../../../utils/orders.util'
import styles from './ResponsiblePersons.module.css'

function formatEmailLink(email: string, subject?: string): string {
  if (!subject) {
    return `mailto:${email}`
  }

  return `mailto:${email}?subject=${subject}`
}

type ResponsiblePersonProps = {
  name: string;
  email?: string;
  emailSubject?: string;
  shouldShowEmail?: boolean;
  maxWidth: number;
  maxNameLength: number;
}

export function ResponsiblePerson({ name, maxWidth, maxNameLength, email = '', emailSubject = '', shouldShowEmail = false }: ResponsiblePersonProps) {
  return (
    <div className={styles['person']}>
      <Tooltip
        label={name}
        disabled={name.length <= maxNameLength}
        style={{ maxWidth }}
      >
        <div className={styles['person__name']}>
          {name}
        </div>
      </Tooltip>
      {shouldShowEmail && email && (
        <ActionIcon
          component='a'
          href={formatEmailLink(email, emailSubject)}
          className={styles['email-link']}
        >
          <MailOutlined />
        </ActionIcon>
      )}
    </div>
  )
}

type ResponsiblePersonsProps = {
  persons: OrderResponsiblePerson[];
  emailSubject?: string;
  shouldShowEmail?: boolean;
}

export function ResponsiblePersons({ persons, emailSubject = '', shouldShowEmail = false }: ResponsiblePersonsProps) {
  const [isPopoverOpened, setPopoverOpened] = useState(false)
  const closePopoverTimerRef = useRef<number | null>(null)
  const openPopover = useCallback(() => {
    if (closePopoverTimerRef.current) {
      clearTimeout(closePopoverTimerRef.current)
    }
    setPopoverOpened(true)
  }, [])
  const closePopover = useCallback(() => {
    closePopoverTimerRef.current = window.setTimeout(() => {
      setPopoverOpened(false)
    }, 100) as number
  }, [])

  if (!persons.length) {
    return null
  }

  const firstPerson: OrderResponsiblePerson = persons[0]
  const otherPersons: OrderResponsiblePerson[] = persons.slice(1)
  const widthConfig = otherPersons.length
    ? {
      containerMaxWidth: 162,
      nameMaxWidth: 142,
      nameMaxLength: 19,
    }
    : {
      containerMaxWidth: 180,
      nameMaxWidth: 170,
      nameMaxLength: 23,
    }

  return (
    <div
      className={styles['root']}
      style={{
        justifyContent: shouldShowEmail ? 'flex-start' : 'space-between',
      }}
    >
      <div
        style={{maxWidth: widthConfig.containerMaxWidth}}
      >
        <ResponsiblePerson
          name={firstPerson.name}
          maxWidth={widthConfig.nameMaxWidth}
          maxNameLength={widthConfig.nameMaxLength}
          email={firstPerson.email}
          emailSubject={emailSubject}
          shouldShowEmail={shouldShowEmail}
        />
      </div>
      {otherPersons.length > 0 && (
        // TODO replace with HoverCard from Mantine 5.0.0+
        <Popover
          width={200}
          withArrow
          shadow='md'
          position='top'
          opened={isPopoverOpened}
          target={(
            <Badge
              sx={{
                marginLeft: 2,
                padding: '0 5px',
                cursor: 'pointer',
              }}
              size='sm'
            >
              <strong>
                +
              </strong>
              {otherPersons.length}
            </Badge>
          )}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
        >
          <div>
            {otherPersons.map(({ name, email }, index) => (
              <ResponsiblePerson
                key={index}
                name={name}
                maxWidth={shouldShowEmail ? 150 : 170}
                maxNameLength={shouldShowEmail ? 18 : 25}
                email={email}
                emailSubject={emailSubject}
                shouldShowEmail={shouldShowEmail}
              />
            ))}
          </div>
        </Popover>
      )}
    </div>
  )
}
