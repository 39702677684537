import axiosInstance from '../auth/FetchInterceptor'
import { API_URL } from '../constants/environment'

export type GetVendorsResponse = {
  vendors: string[];
}

type VendorServiceType = {
	getPartnerProductsVendors: () => Promise<GetVendorsResponse>;
  getCustomerAreaVendors: () => Promise<GetVendorsResponse>;
	getForHandleInput: () => Promise<string>;
}

const VendorService: VendorServiceType = {
  getPartnerProductsVendors: () =>
    axiosInstance.get<never, GetVendorsResponse>(
      `${API_URL.PARTNER_PRODUCTS}/vendors`,
    ),
  getCustomerAreaVendors: () =>
    axiosInstance.get<never, GetVendorsResponse>(
      `${API_URL.CUSTOMER_AREA}/vendors`,
    ),
  getForHandleInput: () =>
    axiosInstance.get<never, string>(
      `${API_URL.PROPOSALS}/vendors`,
    ),
}

export default VendorService
